import {get, post } from './http';
import API_TYPE from './config';

/**
 *  获取商品信息
 * @param params {}
 * @returns {Promise<any>}
 */
export const apiGetProducts = params => get(API_TYPE.MAIN_GETPRODUCTS, params);
/**
 * 获取token
 * @param params
 * @returns {Promise<any>}
 */
export const apiGetToken = params => get(API_TYPE.MAIN_GETCLIENTTOKEN, params);
/**
 * 信用卡购买
 * @param params {userId,productId,payment_method_nonce,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiBrainTreeBuy = params => post(API_TYPE.MAIN_BRAINTREEBUY, params);
/**
 * top币提交订单
 * @param params {userId,productId,topAddress,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiTopBuy = params => post(API_TYPE.MAIN_TOPBUY, params);

/**
 *top币购买
 * @param params {userId,orderId}
 * @returns {Promise<any>}
 */
export const apiTopOrder = params => post(API_TYPE.MAIN_TOPORDER, params);

/**
 * BTC币提交订单
 * @param params {userId,productId,btcAddress,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiBtcBuy = params => post(API_TYPE.MAIN_BTCBUY, params);

/**
 *BTC币购买
 * @param params {userId,orderId}
 * @returns {Promise<any>}
 */
export const apiBtcOrder = params => post(API_TYPE.MAIN_BITORDER, params);

/**
 *支付检测，检测到已到账，进行套餐发货，btc和top都调用这个api
 * @param params {userId,orderId}
 * @returns {Promise<any>}
 */
export const apiPayCheck = params => post(API_TYPE.MAIN_PAYCHECK, params);

/**
 *paymengwall购买
 * @param params {userId,productId,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiExecutePaymentwallUrl = params => post(API_TYPE.MAIN_PAYMENTWAYLL, params);

/**
 *paypal插件初始化
 * @param params {userId,orderId}
 * @returns {Promise<any>}
 */
export const apiCreatePayment = params => post(API_TYPE.MAIN_CREATEPAYMENT, params);

/**
 *paypal购买
 * @param params {userId,productId,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiExecutePayment = params => post(API_TYPE.MAIN_EXECUTEPAYMENT, params);


/**
 * 数字货比购买成功后，调用此接口，返回一些详情信息
 * @param params {userId,productId,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apiDigitalCurrencySucces = params => post(API_TYPE.MAIN_DIGITALCURRENCYSUCCES, params);

/**
 * 往用户邮箱，发送兑换码
 * @param params {userId,productId,sourceType,userChannelType}
 * @returns {Promise<any>}
 */
export const apisendRedeemCode = params => post(API_TYPE.MAIN_SENDREDEEMCODE, params);
