import Vue from 'vue';
import Vuex from 'vuex';
import products from './modules/products';
import userInfo from './modules/userInfo';

import getters from './getters';

Vue.use(Vuex);

export default function createStore() {
    return new Vuex.Store({
        modules: {
            products,
            userInfo,
        },
        getters,
    });
}
