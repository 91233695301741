<template>
  <div class="bit-paypal">
      <pricing-terms></pricing-terms>
      <div id="paypal_btn"></div>
      <pricing-money-back></pricing-money-back>
  </div>
</template>

<script>
import { loadScript } from '@/utils/common';

export default {
    name: 'TgPaypal',
    props: {
        amount: Object,
        custom: String,
        clientId: String,
    },
    mounted() {
        this.isRender = false;
    },
    watch: {
        clientId: {
            handler() {
                this.loadScript();
            },
            immediate: true,
        },
    },
    methods: {
        loadScript() {
            if (!this.clientId) {
                return;
            }
            if (window.paypal) {
                this.$nextTick(() => {
                    this.renderPaypal();
                });
                return;
            }
            loadScript(`https://www.paypal.com/sdk/js?client-id=${this.clientId}&disable-funding=credit`).then(() => {
                this.$nextTick(() => {
                    this.renderPaypal();
                });
            });
        },
        renderPaypal() {
            if (!window.paypal) {
                return;
            }
            if (this.isRender) {
                return;
            }
            this.isRender = true;
            const that = this;
            window.paypal.Buttons({
                style: {
                    label: 'pay',
                },
                createOrder(data, actions) {
                    that.$emit('clickPayButton');
                    // Set up the transaction
                    const orderPromise = actions.order.create({
                        purchase_units: [{
                            amount: that.amount,
                            custom_id: that.custom,
                        }],
                    });
                    if (orderPromise.value) {
                        that.$emit('createOrder', orderPromise.value);
                    } else {
                        orderPromise.then((orderId) => {
                            that.$emit('createOrder', orderPromise.value);
                            return orderId;
                        });
                    }
                    return orderPromise;
                },
                onApprove(data, actions) {
                    return actions.order.capture().then(() => {
                        // that.$emit('myexcutePayment');
                        // console.log('data ------' + JSON.stringify(data))
                        //  console.log('details ------' + JSON.stringify(details))
                        const para = {
                            payerId: data.payerID,
                            orderId: data.orderID,
                        };
                        that.$emit('myexcutePayment', para);
                    });
                },
                onCancel(data, actions) {
                    that.$emit('onCancel', {
                        data,
                        actions,
                    });
                },
            }).render('#paypal_btn');
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-paypal {

}
</style>
