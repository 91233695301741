<template>
    <footer class="bit-footer2 " :class="['type' + type]">
        <div class="container">
            <div v-show="type === '1'" class="a-wrap">
                <router-link to="/privacy-policy" @click.native="gce('Home', 'click_footer_PrivacyPolicy')">
                    Privacy Policy
                </router-link>
                <router-link to="/terms" @click.native="gce('Home', 'click_footer_Term')">
                    Terms of Service
                </router-link>
            </div>
            <div class="copyright">
                Copyright ©2019 BitVPN Inc.
            </div>
        </div>
    </footer>
</template>

<script>
import { gceMixin } from '@/mixins/';

export default {
    mixins: [gceMixin],
    props: {
        type: {
            type: String,
            default: '1',
        },
    },
    components: {
    },
    name: 'BitFooter2',
};
</script>

<style lang="stylus" scoped>
.bit-footer2 {
    background: #101423;
    padding: 27px 0;
    color: #999;
    font-size: 17px;
    text-align: center;
    .a-wrap {
        display: flex;
        justify-content: space-around;
    }
    .container {
        display: block;
    }
    &.type2 {
        .container {
            justify-content: center;
        }
    }
    a {
        color: #999;
        font-size: 17px;
        margin-right: 0;
    }
    a:hover {
        color: #9327A1;
    }
    .copyright {
        margin-top: 47px;
    }
}
@media (min-width: 576px) {
    .bit-footer2 {
        text-align: left;
        .a-wrap {
            display: block;
        }
        .container {
            display: flex;
            justify-content: space-between;
        }
        .copyright {
            margin-top: 0;
        }
        a {
            margin-right: 44px;
        }
    }
}
</style>
