<template>
    <div class="dig-pur">
        <bit-header :isPayPage="true"></bit-header>
        <div class="total-order-amount">
            <div class="container">
                <div class="row">
                    <div class="left-content col-lg-8">
                        <div class="total-title bitm">Order total: {{amount}} {{type==101 ? 'BTC' : 'TOP'}}</div>
                        <div class="input-wrap">
                            <bit-input :value="address" ref="inputRef"></bit-input>
                            <img src="@/assets/images/icon-copy.png" alt="icon-copy" class="icon-copy" @click="copy()" >
                        </div>
                        <div class="pc-tips tips">
                            <img src="@/assets/images/icon_tip.png" alt="icon_tip">
                            <p v-if="type=='102'">
                                The wallet above accepts TOP tokens only; transactions paid with other types of cryptocurrency will not be able to recover.<br>
                                Please make sure the payment address you've provided is consistent; otherwise the transaction will neither be confirmed nor recovered.<br>
                                Please complete your payment within one transaction.<br>
                                Please secure your web browser and the device during the transaction in order to prevent data leaking or tampering.<br>
                                Currently, TOP token accepts Ethereum transferring only, thank you for your understanding.
                            </p>
                             <p v-if="type=='101'">
                                The wallet above accepts BTC only. Transactions paid with other types of cryptocurrency will not be recoverable.<br>
                                Please make sure the payment address you've provided is consistent or the transaction will neither be confirmed nor recovered.<br>
                                Please complete your payment within one transaction.<br>
                                Please secure your web browser and the device during the transaction in order to prevent data leakage or tampering.<br>
                            </p>
                        </div>
                    </div>
                    <div class=" col-lg-4">
                        <!-- <img src="@/assets/images/erweima.png" alt="erweima"> -->
                        <div class="erweima">
                            <div id="qrcode">
                            </div>
                            <p ref="Wallet">Wallet address</p>
                        </div>
                    </div>
                </div>
                <div class="m-tips tips">
                    <img src="@/assets/images/icon_tip.png" alt="icon_tip">
                    <p v-if="type=='102'">
                        The wallet above accepts TOP tokens only; transactions paid with other types of cryptocurrency will not be able to recover.<br>
                        Please make sure the payment address you've provided is consistent; otherwise the transaction will neither be confirmed nor recovered.<br>
                        Please complete your payment within one transaction.<br>
                        Please secure your web browser and the device during the transaction in order to prevent data leaking or tampering.<br>
                        Currently, TOP token accepts Ethereum transferring only, thank you for your understanding.
                    </p>
                        <p v-if="type=='101'">
                        The wallet above accepts BTC only. Transactions paid with other types of cryptocurrency will not be recoverable.<br>
                        Please make sure the payment address you've provided is consistent or the transaction will neither be confirmed nor recovered.<br>
                        Please complete your payment within one transaction.<br>
                        Please secure your web browser and the device during the transaction in order to prevent data leakage or tampering.<br>
                    </p>
                </div>
            </div>
        </div>
        <div class="order-details container">
            <h2>Order Details</h2>
            <div class="detail">
                <div class="detail-line">
                    <span>Status: </span>
                    <span class="bitm">{{status}}</span>
                </div>
                <div class="detail-line">
                    <span>Time Remaining: </span>
                    <span class="bitm detail-time" ref="timer"></span>
                </div>
                <div class="detail-line">
                    <span>Price: </span>
                    <span class="bitm"> {{amount}} {{type==101 ? 'BTC' : 'TOP'}}</span>
                </div>
                <div class="detail-line">
                    <span>Amount received: </span>
                    <span class="bitm">{{receivedamount}} {{type==101 ? 'BTC' : 'TOP'}}</span>
                </div>
                <div class="detail-line">
                    <span>Contact Seller: </span>
                    <a class="bitm detail-seller" href="mailto:contact@bitvpn.net">contact@bitvpn.net</a>
                </div>
            </div>
            <div>
                <button  class="green-button" ref="btn1" :disabled="isqueryend" @click="query()">{{btn1}}</button>
            </div>
            <div class="border-line"></div>
        </div>
        <div class="what-next container">
            <h2 class="bitm">What's Next?</h2>
            <ul>
                <li>Please transfer {{amount}} {{type==101 ? 'BTC' : 'TOP'}} to this address: {{address}} and make sure that the payment is sufficient (additional fees might be included).</li>
                <li>We will deliver immediately once payment has been completed.</li>
                <li>You have total of 6 hours to complete this transaction.</li>
                <li>If your payment is insufficient or overtime, please contact us at <a href="mailto:contact@bitvpn.net">contact@bitvpn.net</a></li>
            </ul>
        </div>
        <bit-footer2></bit-footer2>
        <!-- <div class="loading" v-if="mask">
             <vue-loading type="spin" color="#1E86E2" :size="{ width: '30px', height: '30px' }"></vue-loading>
        </div> -->
    </div>
</template>

<script>

import {
    apiTopOrder,
    apiBtcOrder,
    apiPayCheck,
    apiDigitalCurrencySucces,
} from '@/fetch/api';
import {mapState} from 'vuex';
import {copy} from '@/utils/common';

export default {
    components: {
        // VueLoading,
    },
    name: 'DigitalPurchase',
    data() {
        return {
            address: '',
            type: 101,
            orderId: '',
            userId: '',
            email: '',
            amount: 0,
            remainingTime: 0,
            timer: null,
            timer2: null,
            mask: true,
            receivedamount: 0,
            status: 'Waiting for payment',
            isqueryend: false,
            btn1: 'Payment Completed, Request Delivery',
            queryparams: {},
        };
    },
    computed: {
        ...mapState([
            'userInfo',
            'products',
        ]),
    },
    watch: {
        address(val) {
            this.qrcode(val);
        },
    },
    methods: {
        copy() {
            const node = this.$refs.inputRef.$el;
            this.$toast({
                toastMsg: 'Copy Successfully',
            });
            console.log('ref', this.$refs.inputRef);
            console.log('Wallet ref', this.$refs.Wallet);
            console.log('el', node);
            copy(node);
        },
        query() {
            this.isqueryend = true;
            this.btn1 = 'Pending...';
            this.checkDelivery(this.queryparams);
        },
        checkDelivery(para) {
            // debugger;
            apiPayCheck(para).then((result) => {
                const { Result } = result.data.result;
                if (Result === 1) {
                    clearInterval(this.timer);
                    clearInterval(this.timer2);
                    apiDigitalCurrencySucces(para).then((data) => {
                        const temp = data.data;
                        if (temp.result === 1) {
                            localStorage.setItem('payMethod', temp.payMethod);
                            localStorage.setItem('orderTime', temp.orderTime);
                            localStorage.setItem('endTime', temp.endTime);
                            localStorage.setItem('amount', temp.price);
                            localStorage.setItem('productName', temp.productName);
                            localStorage.setItem('orderId', temp.orderId);
                            if (temp.oldEndTime !== undefined) {
                                localStorage.setItem('oldEndTime', temp.oldEndTime);
                            }
                            if (temp.redeemCode !== undefined) {
                                localStorage.setItem('redeemCode', temp.redeemCode);
                            }
                            this.$router.push({name: 'vpn-purchase-thank-you'});
                        }
                    }).catch((err) => {
                        console.log(`err---- ${err}`);
                    });
                } else {
                    // this.$toast('Payment is still pending, please check again later');
                    this.$toast({
                        toastMsg: 'Payment is still pending, please check again later',
                    });
                    setTimeout(() => {
                        this.isqueryend = false;
                        this.btn1 = 'Payment Completed, Request Delivery';
                    }, 2000);
                }
            }).catch((err) => {
                // this.$toast('Payment is still pending, please check again later');
                this.$toast({
                    toastMsg: 'Payment is still pending, please check again later',
                });
                setTimeout(() => {
                    this.isqueryend = false;
                    this.btn1 = 'Payment Completed, Request Delivery';
                }, 2000);
                console.log(err);
            });
        },
        // 初始化二维码
        qrcode(value) {
            // this.$refs.qrcode.innerHTML = '';
            // if (this.$refs.qrcode !== undefined) {
            //     this.$refs.qrcode.innerHTML = '';
            // }
            import('qrcodejs2').then((QRCode) => {
                /* eslint new-cap: 0 */
                new QRCode.default(document.getElementById('qrcode'), {
                    width: 240, // 设置宽度，单位像素
                    height: 240, // 设置高度，单位像素
                    text: value, // 设置二维码内容或跳转地址
                });
            });
        },
        getPaymentOrder(params, type = 101, api) {
            api(params).then((reuslt) => {
                const info = reuslt.data;
                this.address = type === 102 || type === '102' ? info.topAddress : info.btcAddress;
                this.amount = type === 102 || type === '102' ? info.top : info.btc;
                this.receivedamount = type === 102 || type === '102' ? info.receiveTop : info.receiveBtc;
                this.remainingTime = info.remainingTime;
                localStorage.setItem('amount', this.amount);
                if (info.result === 1) {
                    const tempstatus = info.status;
                    if (tempstatus === 1) {
                        this.$refs.timer.innerHTML = '00:00:00';
                        clearInterval(this.timer);
                        clearInterval(this.timer2);
                        this.mask = false;
                        this.status = 'Payment Successful';
                        apiDigitalCurrencySucces(params).then((data) => {
                            const temp = data.data;
                            if (temp.result === 1) {
                                localStorage.setItem('payMethod', temp.payMethod);
                                localStorage.setItem('orderTime', temp.orderTime);
                                localStorage.setItem('endTime', temp.endTime);
                                localStorage.setItem('amount', temp.price);
                                localStorage.setItem('productName', temp.productName);
                                localStorage.setItem('orderId', temp.orderId);
                                if (temp.redeemCode !== undefined) {
                                    localStorage.setItem('redeemCode', temp.redeemCode);
                                }
                                if (temp.oldEndTime !== undefined) {
                                    localStorage.setItem('oldEndTime', temp.oldEndTime);
                                }
                                this.$router.push({name: 'vpn-purchase-thank-you'});
                            }
                        }).catch((err) => {
                            console.log(`err---- ${err}`);
                        });
                    } else if (tempstatus === -1) {
                        this.status = 'Payment Failed';
                        this.$refs.timer.innerHTML = 'Overtime';
                        clearInterval(this.timer);
                        clearInterval(this.timer2);
                        this.mask = false;
                    } else if (tempstatus === 0) {
                        this.status = 'Waiting for payment';
                        this.timer = setInterval(this.formatSeconds, 1000);
                    }
                } else if (info.result === 0) {
                    this.qrcode('xxxxxxxx');
                    clearInterval(this.timer);
                    clearInterval(this.timer2);
                    this.status = 'Payment Failed';
                    this.$refs.timer.innerHTML = 'Overtime';
                    this.mask = false;
                }
            }).catch((err) => {
                this.qrcode('xxxxxxxx');
                this.status = 'Payment Failed';
                clearInterval(this.timer);
                clearInterval(this.timer2);
                this.$refs.timer.innerHTML = 'Overtime';
                console.log(err);
                this.mask = false;
            });
        },
        tow(n) {
            return n >= 0 && n < 10 ? `0${n}` : `${n}`;
        },
        // 倒计时函数
        formatSeconds() {
            this.remainingTime = this.remainingTime - 1;
            let minuteTime = 0;// 分
            let hourTime = 0;// 小时
            let newsecondTime = 0;
            if (this.remainingTime > 60) { // 如果秒数大于60，将秒数转换成整数
                // 获取分钟，除以60取整数，得到整数分钟
                minuteTime = parseInt(this.remainingTime / 60, 10);
                // 获取秒数，秒数取佘，得到整数秒数
                newsecondTime = parseInt(this.remainingTime % 60, 10);
                // 如果分钟大于60，将分钟转换成小时
                if (minuteTime > 60) {
                    // 获取小时，获取分钟除以60，得到整数小时
                    hourTime = parseInt(minuteTime / 60, 10);
                    // 获取小时后取佘的分，获取分钟除以60取佘的分
                    minuteTime = parseInt(minuteTime % 60, 10);
                }
            } else {
                hourTime = 0;
                minuteTime = 0;
                newsecondTime = this.remainingTime;
            }
            this.$refs.timer.innerHTML = `${this.tow(hourTime)} : ${this.tow(minuteTime)} :  ${this.tow(newsecondTime)} `;
            this.mask = false;
            if (this.$refs.timer.innerHTML.includes('00:00:00')) {
                this.$refs.timer.innerHTML = '&nbsp;Overtime';
                clearInterval(this.timer);
                clearInterval(this.timer2);
                this.$router.push({name: 'vpn-purchase-thank-you'});
            }
        },
    },
    mounted() {
        this.orderId = this.userInfo.orderId === '' || this.userInfo.orderId === undefined ? localStorage.getItem('orderId') : this.userInfo.orderId;
        this.email = this.userInfo.email === '' || this.userInfo.email === undefined ? localStorage.getItem('email') : this.userInfo.email;
        this.userId = this.userInfo.userId === '' || this.userInfo.userId === undefined ? localStorage.getItem('userId') : this.userInfo.userId;
        this.type = localStorage.getItem('type') === '' || localStorage.getItem('type') === undefined || localStorage.getItem('type') === null ? this.userInfo.type : localStorage.getItem('type');
        if (this.type === '101' || this.type === 101) {
            this.$gce('Pricing', 'enterToken', `BTC:${this.products.productId}`);
        } else {
            this.$gce('Pricing', 'enterToken', `TOP:${this.products.productId}`);
        }
        let params = {};
        // debugger;
        if (this.userId === undefined || this.userId === '' || this.userId === 'undefined') {
            params = {
                orderId: this.orderId,
                email: this.email,
            };
        } else {
            params = {
                orderId: this.orderId,
                userId: this.userId,
            };
        }
        this.queryparams = params;
        this.getPaymentOrder(params, this.type, this.type === 102 || this.type === '102' ? apiTopOrder : apiBtcOrder);
        this.timer2 = setInterval(() => {
            clearInterval(this.timer);
            this.getPaymentOrder(params, this.type, this.type === 102 || this.type === '102' ? apiTopOrder : apiBtcOrder);
        }, 60000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
        clearInterval(this.timer2);
    },
};
</script>

<style lang="stylus" scoped>
.dig-pur {
    .what-next {
        margin-bottom: 52px;
        h2 {
            font-size: 32px;
            color: rgba(51,51,51,1);
            margin-top: 40px;
            margin-bottom: 16px;
        }
        li {
            font-size: 17px;
            margin-left: 1em;
            margin-bottom: 8px;
            color:rgba(102,102,102,1);
            word-wrap: break-word;
        }
        li::before {
            content: "\2022";
            color: rgba(102,102,102,1);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    .input-wrap {
        position: relative;
        margin-bottom: 24px;
        input {
            height: 64px;
            font-size: 12px;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        .icon-copy {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            width: 22px;
            height: 22px;
            cursor: pointer;
        }
    }
    .order-details {
        color:rgba(38,38,38,1);
        font-size:14px;
        padding-top: 32px;
        .border-line {
            border-bottom: 1px solid rgba(222,222,222,1);
            margin-top: 40px;
        }
        h2 {
            font-size: 24px;
            color: rgba(51,51,51,1);
            margin-bottom: 25px;
        }
        .detail {
            margin-bottom: 32px;
        }
        .detail-line {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
        }
        .detail-time {
            color: #E92B2B;
        }
        .detail-time {
            color: #E92B2B;
        }
        button {
            width: 100%;
        }
    }
    .total-order-amount {
        background: rgba(245,245,245,1);
        padding: 32px 0 24px 0;
        .total-title {
            margin-bottom: 16px;
            color: rgba(0,0,0,1);
            font-size: 18px;
            text-align: center;
        }
        .tips {
            display: flex;
            margin-top: 16px;
            p {
                color: rgba(248,126,56,1);
                font-size: 14px;
            }
            img {
                width: 12px;
                height: 12px;
                display: inline-block;
                line-height: 12px;
                font-size: 12px;
                align-self: flex-start;
                margin-top: 4px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
        .m-tips {
            display: flex;
        }
        .pc-tips {
            display: none;
        }
        .erweima {
            padding: 30px 10px 16px 10px;
            background: #fff;
            width: 100%;
            img {
                margin: auto;
            }
            p {
                font-size: 18px;
                color:rgba(51,51,51,1);
                margin-top: 16px;
                text-align: center;
            }
            #qrcode{
                display: flex;
                justify-content: center
            }
        }
    }
    .loading {
        position:fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin:auto;
        z-index: 2147483647;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background:  #fff;
    }

}
@media (min-width: 576px) {
    .dig-pur {
        .what-next {
            margin-bottom: 100px;
        }
        .total-order-amount {
            padding: 64px 0;
            .total-title {
                margin-bottom: 44px;
                font-size: 36px;
                text-align: left;
            }
            .erweima {
                width: 300px;
            }
            .m-tips {
                display: none;
            }
            .pc-tips {
                display: flex;
            }
        }
        .input-wrap {
            margin-bottom: 0;
            input {
                height: 64px;
                font-size: 18px;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        .order-details {
            font-size:24px;
            padding-top: 24px;
            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }
            .detail {
                margin-bottom: 64px;
            }
            .detail-line {
                margin-bottom: 16px;
            }
            button {
                max-width: 600px;
            }
        }
    }
}
</style>
