/* eslint-disable no-param-reassign */
/**
 * httpjs
 * author jack.wang@dingtone.me
 * change date 2019/3/14
 * 进行axios 通信层封装
 */
import axios from 'axios';
import qs from 'qs';
import { logError } from '@/utils/common';

/**
 * 接口环境地址切换
 */
// 网站后端接口地址 基础URL
const BASE_TEST_URL = 'http://52.9.8.211:8113/bitproxy/webBuy/'; // 测试环境API地址
// const BASE_TEST_URL = '/testapi'; // 本地代理
// const BASE_PRE_URL = 'http://ldskyvpn-webserver-995438515.us-west-1.elb.amazonaws.com:8080/skyvpn/' // 灰度环境API地址
// const BASE_PRE_URL = '//ld-bitvpn-webserver-481332435.us-west-1.elb.amazonaws.com:8080/bitproxy/webBuy/'; // 灰度环境API地址
// const BASE_PRE_URL = 'http://54.241.21.249:8089/skyvpn/' // 灰度环境API地址改为测试环境，记得修改回来
// const BASE_ONLINE_URL = 'http://ldskyvpn-webserver-995438515.us-west-1.elb.amazonaws.com:8080/skyvpn/' // 线上环境API地址
const BASE_ONLINE_URL = 'https://d2tv7wzvhihv37.cloudfront.net/bitproxy/webBuy/'; // 线上环境API地址
// const BASE_URL = BASE_ONLINE_URL;

let isSetBaseUrl = false;
function initBaseUrl() {
    if (isSetBaseUrl) {
        return;
    }
    isSetBaseUrl = true;
    if (window.location.hostname.indexOf('bitvpn.net') > -1) {
        axios.defaults.baseURL = BASE_ONLINE_URL;
    } else {
        axios.defaults.baseURL = BASE_TEST_URL;
    }
}
// 进行环境地址切换
// switch (window.location.hostname) {
//     case 'www.skyvpn.net': // 网站线上域名
//     case 'skyvpn.net': // 网站线上域名
//         BASE_URL = BASE_ONLINE_URL
//         break;
//     case 'www.skyvpn.tzwebpre.com': // 网站灰度域名
//     case 'skyvpn.tzwebpre.com': // 网站灰度域名
//         BASE_URL = BASE_PRE_URL
//         break;
//     case 'www.skyvpn.tzwebsitetest.com': // 网站外网测试环境域名
//     case 'skyvpn.tzwebsitetest.com': // 网站外网测试环境域名
//     case 'localhost': // 网站外网测试环境域名
//         BASE_URL = BASE_TEST_URL
//         break;
//     default:
//         BASE_URL = BASE_ONLINE_URL // 改为下面的本机IP是为了给测试本机，记得修改回来
//             // BASE_URL = BASE_TEST_URL
// }

// 本地 storage 开关，存储键名：API_ENV
// if (localStorage.getItem('API_ENV')) {
//     switch (localStorage.getItem('API_ENV')) {
//         case 'online': // 网站线上域名
//             BASE_URL = BASE_ONLINE_URL
//             break;
//         case 'pre': // 网站灰度域名
//             BASE_URL = BASE_PRE_URL
//             break;
//         case 'test': // 网站外网测试环境域名
//             BASE_URL = BASE_TEST_URL
//             break;
//         default:
//             BASE_URL = BASE_ONLINE_URL
//     }
// }
/**
 * axios 定制化配置
 * @type {number}
 */
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        if (config.method === 'post') {
            config.data = qs.stringify(config.data);
        }
        if (localStorage.token) {
            config.headers.Authorization = localStorage.token;
        }
        return config;
    },
    error => Promise.error(error),
);
// axios 响应拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            return Promise.resolve(response);
        }
        return Promise.reject(response);
    },
    (error) => {
        if (error.response.status) {
            switch (error.response.status) {
            // 404请求不存在
            case 404:
                // Message({
                //     duration: 1500,
                //     showClose: true,
                //     message: '网络繁忙',
                //     type: 'error'
                // });
                break;
                // 其他错误，直接抛出错误提示
            default:
                    // Message({
                    //     message: '网络繁忙',
                    //     // message: error.response.data.message,
                    //     duration: 1500,
                    //     showClose: true,
                    //     type: 'error'
                    // });
            }
            return Promise.reject(error.response);
        }
        return error;
    },
);

/**
 * axios get 请求方法封装
 * @param url
 * @param params
 * @returns {Promise<any>}
 */
export function get(url, params) {
    initBaseUrl();
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params,
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            logError(err, url);
            reject(err.data);
        });
    });
}

/**
 * axios post 方法封装
 * @param url
 * @param params
 * @returns {Promise<any>}
 */
export function post(url, params) {
    initBaseUrl();
    return new Promise((resolve, reject) => {
        axios.post(url, params).then((res) => {
            resolve(res);
        }).catch((err) => {
            logError(err, url);
            reject(err.data);
        });
    });
}
