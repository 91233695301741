<template>
    <input
        class="bit-input"
        type="text"
        :value="value"
        @input="inputChange($event)"
    />
</template>

<script>
export default {
    components: {
    },
    name: 'BitInput',
    props: {
        size: {
            type: String,
            default: 'big',
        },
        value: {
            type: String,
            default: '',
        },
        specialCharacters: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt);
        },
        inputChange(e) {
            this.$emit('input', e.target.value);
        },
    },
};
</script>

<style lang="stylus">
.bit-input {
    -webkit-appearance: none;
    box-shadow: none !important;
    appearance: none;
    padding: 9px 16px;
    background: rgba(250,250,250,1);
    border: 1px solid rgba(211,211,211,1);
    width: 100%;
    color: rgba(38,38,38,1);
    font-size: 18px;
    transition: all .3s;
    outline: none;
    height: 40px;
    line-height: 18px;
    border-radius: 0;
    &:hover {
        background: #fff;
    }
    &:active, &:focus {
        background: rgba(255,255,255,1);
        border: 1px solid rgba(102,102,102,1);
    }
}
</style>
