<template>
    <div class="bit-download-modal" @click="$emit('close')">
        <div class="modal-container" @click.stop.prevent>
            <div class="close-icon" @click="$emit('close')">×</div>
            <h4 class="bitm">BitVPN for Android</h4>
            <div class="android-btn">
                <!-- <bit-button @click="device='apk';downloadTarget('AndroidApk', category, action, 'apk');">Download APK</bit-button> -->
                <img class="google_play" @click="device='google'; downloadTarget('AndroidG', category, action, 'google');" src="@/assets/images/google_play.png" alt="google_play">
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';

export default {
    mixins: [downloadMixin],
    props: {
        type: String,
        category: {
            type: String,
            default: 'Home',
        },
        action: {
            type: String,
            default: 'click_bottom_downloadAndroid',
        },
    },
    components: {
    },
    name: 'BitDownloadModal',
    methods: {
        // beforeGce(action) {
        //     if (!this.type) {
        //         return;
        //     }
        //     if (this.type === 'Home') {
        //         this.gce('', 'click_bottom_downloadAndroid');
        //     } else if (this.type === 'Success') {
        //         this.gce('', 'PurchaseSuccessPage_download_Android', action);
        //     }
        // },
    },
};
</script>

<style lang="stylus" scoped>
.bit-download-modal {
    position: fixed;
    top: 0;
    height: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.6);
    padding: 20px;
    .modal-container {
        max-width: 700px;
        background: #fff;
        padding: 60px 0px;
        text-align: center;
        position: relative;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        .close-icon {
            display: inline-block;
            width: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            color: rgba(0,0,0,0.6);
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        h4 {
            font-size:24px;
            margin-bottom: 35px;
            color:rgba(38,38,38,1);
        }

        .google_play {
            vertical-align: top;
            cursor: pointer;
            height: 61px;
            margin-top: 10px;
        }
        button {
            width: 199px;
            height: 61px;
            font-size: 18px;
            padding: 0;
            margin-top: 10px;
        }
    }
}
@media (min-width: 450px) {
    .bit-download-modal {
        .modal-container {
            .android-btn {
                display: flex;
                justify-content: space-around;
            }
            .google_play {
            }
        }
    }
}

@media (min-width: 768px) {
    .bit-download-modal {
        .modal-container {
            padding: 90px 80px;
            h4 {
                font-size:52px;
                margin-bottom: 56px;
            }
            .android-btn {
                display: flex;
                justify-content: space-between;
            }
            .google_play {
                width: auto;
                height: auto;
                margin-top: 0;
            }
            button {
                margin-top: 0;
                width: 260px;
                height: 80px;
                font-size: 24px;
                padding: 0;
            }
        }
    }
}
</style>
