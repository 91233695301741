/* eslint-disable import/order */
import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import createStore from './store';
import 'normalize.css/normalize.css';
import '@/styles/main.styl';
import { logError, gce } from './utils/common';
import '@/components';

import toast from '@/components/toast/toast';

import '@/directives';

Vue.use(toast);
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

// or with options
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1
})

Vue.config.productionTip = true;

Vue.prototype.$logError = logError;

Vue.prototype.$gce = gce;

Vue.config.errorHandler = (e, vm, info) => {
    // 组件名字vm.$options.name
    logError({
        e,
        info: [
            info,
            vm.$options.name,
        ],
    });
};

export default function createApp() {
    const store = createStore();
    const router = createRouter();
    const app = new Vue({
        router,
        store,
        render: h => h(App),
    });
    return { app, router, store };
}

console.log('wwwwwwwwwwwwwwwwwww')
