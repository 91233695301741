<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <div class="title">
                {{title}}
            </div>
            <div class="contentList" v-for="(item, index) in contentList" :key="index">
                <div class="_title">
                    <img v-if="item.imgSrc" v-lazy="item.imgSrc" class="img_l">
                    {{item.title}}
                </div>
                <div class="_content">
                    {{item.content}}
                </div>
                <div class="sub_content" v-for="(item1, index1) in item.contentdetail" :key="index1">
                    <div class="sub_title">
                        {{item1.subTitle}}
                    </div>
                    <div class="sub_sub" v-for="(item2, index2) in item1.details"  :key="index2" v-html="item2">
                    </div>
                </div>
            </div>
             <div class="btn-center">
                <bit-button size="medium" @click.stop="download('click_bottom_aboutvpn')">Get BitVPN</bit-button>
             </div>
        </div>
        
        <bit-footer></bit-footer>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';
export default {
    mixins: [downloadMixin],
    name: 'Faq',
    data() {
        let imgL = require('@/assets/images/whatvpn.png')
        return {
            title: 'What Is a VPN?',
            contentList: [{
                title: '',
                content: '',
                contentdetail: [{
                    subTitle: '',
                    details: [`
VPN, or Virtual Private Network, is a method that encrypts your online activities and helps you surf anonymously from wherever you want by hiding your internet protocol (IP) address.
`,
                    ` <br />Normally when you browse online, your data just need a few clicks to be put at risks. <br /><br />
`,
`• Governments may track you through the IP address provided by your Internet Service Provider;
`,
`• Businesses monitor your online activity to know about your browsing habits and spam you with ads;
`,
`• Your online account information may be stolen or illegally used;
`,
`• Your network may be hacked, and your computer loaded with viruses.
`]
                },
                ]
            },
            {
                title: 'In What Ways Can a VPN Help You?',
                content: ``,
                contentdetail: [{
                    subTitle: 'Keep Your Online Activity Anonymous',
                    details: [`
Your browsing can be hidden to maintain privacy if you connect to the Internet through a VPN because a VPN can make your connections bypass ISP monitoring.
`]
                },
                {
                    subTitle: 'Protect Your Data',
                    details: [`
                        A VPN encrypts the tunnel data goes through to boost your online security and eradicate any direct exposure of your data on the internet, improving your cybersecurity.
`]
                },
                 {
                    subTitle: 'Visit Geo-Blocked Websites',
                    details: [`
                        As a VPN user, however, you can grasp the full potential of the Internet, connecting your devices to any blocked or restricted website through a VPN server.
`]
                },
                 {
                    subTitle: 'Get Back Your Internet Freedom',
                    details: [`
                        A VPN changes your IP addresses to prevent you from being identified.
`]
                },
                ]
            },
            {
                title: 'Centralized VPN vs Decentralized VPN',
                content: ' Although there are numerous VPN services in the market, they can all be categorized into two: centralized VPN and decentralized VPN.',
                contentdetail: [{
                    subTitle: '• Centralized VPN',
                    details: [`
Traditional VPN, or centralized VPN, depends on a centralized network where users store all their data and information. These types of VPN connections pose two huge risks.
`,`Risk #1: Single server is used and easily hacked.
`,`Risk #2: Data can be still obtained by VPN service provider.
`]
                },
                {
                    subTitle: '• Decentralized VPN',
                    details: [`
A decentralized VPN is based on peer-to-peer mesh networking so that data transmission within the network doesn’t depend on single node anymore. With a decentralized VPN, multiple nodes are on the chain to transmit users’ partial data so that the complete data can never be acquired by any single node or server.
<br />`,
`<br />A decentralized VPN has the following advantages: <br />`,
`<p class="right_before">Secure</p>`,`With layers of data encryption and scrambling, a decentralized VPN protects you from data breaches and hackers to keep you secure.`,
`<p class="right_before">Private</p>`,`Based on blockchain technology and distributed structure, a decentralized VPN lets you to regain your Internet privacy and stay unseen and untracked by all kinds of monitors and government censorship.`,
`<p class="right_before">Unstoppable</p>`,`With VPN nodes spread across the world, a decentralized network lasts forever and enables you to bypass firewalls and geo-restrictions.`,
]
                },
                
                ]
            },
            {
                imgSrc:imgL,
                title: 'BitVPN: A Trusted, Decentralized VPN',
                content: '',
                contentdetail: [{
                    subTitle: '',
                    details: [`
<p class="right_before none">Peer-to-Peer Mesh Network: Data Security Boosted</p>`,
`<p class="right_before none">Anonymous IP Address: Privacy Protection Enhanced</p>`,
`<p class="right_before none">Decentralized Network: Hack Risk Dramatically Reduced</p>`,
`<p class="right_before none">No Logging: Top Security for Your Data</p>`,
`<p class="right_before none">iOS, Android, Mac, Windows Support</p>`]
                },
                ]
            },
            ],
        };
    },
    methods: {
        down() {
           
        },
    },
};
</script>

<style lang="stylus" scoped>
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600

        .img_l {
            width 100%
            max-height 100%
            margin 40px 0
        }
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 20px
        font-size 16px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
            >>>.right_before {
               position relative
               padding-left 16px
               margin-top 15px
               &:before {
                   content:url('../../assets/images/whatvpn_right.png');
                   position: absolute;
                   left -4px
                   top 4px
               }
            }
            >>>.none {
                margin-top 5px
            }
            
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}

</style>
