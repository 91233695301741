<template>
  <div class="bit-btc">
        <div class="BTC" >
            <div class="input-item">
                <label for="bit_btc_address">Please enter the last four digits of your BTC address</label>
                <bit-input id="bit_btc_address" v-model="myinput" placeholder="Last four digits of your BTC address"></bit-input>
                <p><img src="@/assets/images/icon_tip.png" alt="icon_tip">Privacy Protection: the digits will only be used for identifying your purchases.</p>
            </div>
        </div>
        <pricing-terms  type="btc"></pricing-terms>
        <div style="margin: 32px 0 24px">
            <button class="green-button" :disabled="myinput.length < 4" @click="buyCheck()">Continue with BTC</button>
        </div>
        <pricing-money-back></pricing-money-back>
        <bit-popup-email v-if="showpupemail" @closePop="hidePop" type='btc' > </bit-popup-email>
  </div>
</template>

<script>
import { mapState, mapActions} from 'vuex';
import { apiBtcBuy } from '@/fetch/api';
import { loadRecaptcha } from '@/utils/common';

export default {
    name: 'BitBtc',
    data() {
        return {
            myinput: '',
            showpupemail: false,
        };
    },
    methods: {
        ...mapActions([
            'upgradebtcaddress',
            'upgradeorderid',
            'upgradetype',
        ]),
        buyCheck() {
            // debugger;
            this.upgradebtcaddress(this.myinput.slice(this.myinput.length - 4, this.myinput.length));
            if (this.userInfo.userId === '' || this.userInfo.userId === undefined) {
                this.showpupemail = true;
            } else {
                loadRecaptcha().then((robotToken) => {
                    const params = {
                        userId: this.userInfo.userId,
                        productId: this.products.productId,
                        btcAddress: this.userInfo.btcAddress,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                    apiBtcBuy(params).then((result) => {
                        if (result.data.result === 1) {
                            console.log('sucess');
                            this.upgradetype(101);
                            this.upgradeorderid(result.data.orderId);
                            this.$router.push({name: 'vpn-purchase-btc'});
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                });
            }
        },
        hidePop() {
            this.showpupemail = false;
        },
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    watch: {
        myinput(newVal, oldVal) {
            const parttern = /[\W]/g;
            if (parttern.test(newVal)) {
                this.$nextTick(() => {
                    this.$gce('Pricing', 'enterTokenaddresssuccess', `BTC:${this.products.productId}`);
                    this.myinput = oldVal;
                });
            } else {
                this.$gce('Pricing', 'enterTokenaddress', `BTC:${this.products.productId}`);
                this.myinput = newVal;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-btc {
    .BTC {
        border-bottom: 1px solid rgba(222,222,222,1);
        padding-bottom: 10px;
        p {
            font-size: 16px;
            margin-top: 8px;
            display: flex;
            img {
                width: 12px;
                height: 12px;
                display: inline-block;
                line-height: 12px;
                font-size: 12px;
                align-self: flex-start;
                margin-top: 4px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
    }
}
</style>
