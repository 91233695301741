<template>
    <div class="home">
        <bit-header header-type="transparent"></bit-header>
        <div class="h-top container">
            <div class="left-content">
                <h1 class="bitm">A truly decentralized VPN service</h1>
                <p>We take internet privacy seriously.</p>
                <div class="get-now-wrap">
                    <bit-button @click="download('click_banner_GetskyVPN')">Get BitVPN</bit-button>
                </div>
            </div>
            <img v-if="mobile === -1" src="@/assets/images/home_computer.png" alt="home_computer">
        </div>
        <div class="banner-img" v-if="mobile === 1">
            <img src="@/assets/images/home_computer_m.png" alt="home_computer">
        </div>
        <div class="bg-container">
            <div class="why-choose-bitvpn ">
                <h2 class="bitm">Why choose BitVPN</h2>
                <div class="container">
                    <ul class="row">
                        <li class="col-sm-4">
                            <div style="height: 75px;">
                                <img src="@/assets/images/Secure.png" alt="Secure">
                            </div>
                            <div>
                                <h4 class="bitm">Secure</h4>
                                <p class="bitm">With layers of data encryption and scrambling in a decentralized VPN network, BitVPN protects you from data breaches and cyberattacks.</p>
                            </div>
                        </li>
                        <li class="col-sm-4">
                            <div style="height: 75px;">
                                <img src="@/assets/images/privacy.png" alt="privacy">
                            </div>
                            <div>
                                <h4 class="bitm">Private</h4>
                                <p class="bitm">Based on a decentralized structure, BitVPN empowers you to regain your internet privacy and stay invisible from all kinds of surveillance and censorship.</p>
                            </div>
                        </li>
                        <li class="col-sm-4">
                            <div style="height: 75px;">
                                <img src="@/assets/images/unstoppable.png" alt="Unstoppable">
                            </div>
                            <div>
                                <h4 class="bitm">Unstoppable</h4>
                                <p class="bitm">With VPN nodes spread across the world, BitVPN's decentralized network lasts forever and enables you to bypass firewalls and geo-restrictions.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- video -->
            <div class="bit-video container">
                <div>
                    <h3 class="bitm h3-mobile">BitVPN - A decentralized VPN service on blockchain</h3>
                    <h3 class="bitm h3-pc">BitVPN </h3>
                    <p class="bitm">A decentralized VPN service on Blockchain</p>
                </div>
                <div class="youtube-container-img">
                    <img class="youtube-container-img" src="@/assets/images/video2.png" alt="youtube video"  @click="showVideoModal = true">
                </div>
                <!-- <div class="youtube-container" :style="{height: videoHeight}" >
                    <iframe v-if="showVideo" width="100%" height="100%" src="https://www.youtube.com/embed/k-5CNsGYpUQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div> -->
            </div>
            <!-- VPN with Absolute Anonymity -->
            <div class="vpn-with-aa container">
                <div class="row">
                    <h2 class="bitm col-12">VPN with Absolute Anonymity</h2>
                    <p class="col-12">BitVPN is your ultimate internet privacy solution, ensuring you an anonymous online browsing experience. With BitVPN's decentralized data structure, you can surf, unblock, and stream any content anonymously wherever you are.</p>
                </div>
            </div>
        </div>
        <div class="posu container">
            <div class="row">
                <div class="posu-item col-md-6 col-12">
                    <div >
                        <img class="posu-first-img" style="width: 57px;" src="@/assets/images/dps.png" alt="Digital Privacy Safeguard">
                    </div>
                    <div>
                        <h4 class="bitm">Digital Privacy Safeguard</h4>
                        <p>Stay hidden and protected even when connected to public Wi-Fi. Browse anonymously and leave absolutely no trace.</p>
                    </div>
                </div>
                <div class="posu-item col-md-6 col-12">
                    <div>
                        <img style="width: 69px" src="@/assets/images/nlk.png" alt="No Logging of Any Kind">
                    </div>
                    <div>
                        <h4 class="bitm">No Logging of Any Kind</h4>
                        <p>BitVPN cannot access, track, or store your online activities due to the nature of decentralization.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="posu-item col-md-6 col-12">
                    <div>
                        <img style="width: 70px" src="@/assets/images/pmn.png" alt="Peer-to-Peer Mesh Network">
                    </div>
                    <div>
                        <h4 class="bitm">Peer-to-Peer Mesh Network</h4>
                        <p>BitVPN's decentralized community is powered by individuals like you. All data is generated and distributed amongst users.</p>
                    </div>
                </div>
                <div class="posu-item col-md-6 col-12">
                    <div>
                        <img style="width: 70px" src="@/assets/images/fandr.png" alt="Flexibility and Resilience">
                    </div>
                    <div>
                        <h4 class="bitm">Flexibility and Resilience</h4>
                        <p>We built BitVPN on a decentralized network so single points of attack or failure pose no risk at all.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="get-bitvpn ">
            <h2 class="bitm">Get BitVPN</h2>
            <div class="map-bg">
                <bit-download type='Home'></bit-download>
            </div>
        </div>
        <bit-footer></bit-footer>
        <bit-download-modal v-show="showDownloadModal" @close="showDownloadModal = false"></bit-download-modal>
        <bit-video-modal v-if="showVideoModal" @close="showVideoModal = false"></bit-video-modal>
    </div>
</template>

<script>
import { downloadMixin, gceMixin } from '@/mixins/';
import { gaClose, goBack } from '@/utils/common';

export default {
    components: {
    },
    name: 'Home',
    mixins: [downloadMixin, gceMixin],
    mounted() {
        this.mobile = window.innerWidth < 576 ? 1 : -1;
        this.gce('Home', 'Homepage_show');
        gaClose(this, 'Home', 'close_Homepage', '');
        goBack(this, 'Home', 'back_Homepage', '');
    },
    data() {
        return {
            showVideoModal: false,
            mobile: 0,
        };
    },
};
</script>

<style lang="stylus" scoped>
.home {
    background-image: url('../../assets/images/home_mobile_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    .bg-container {
        background-image: url('../../assets/images/home_computer2_m.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }
    .bit-video {
        background-repeat: no-repeat;
        padding: 0 27px;
        .youtube-container-img {
            width: 100%;
            max-width: 378px;
            cursor: pointer;
        }
        h3 {
            color: rgba(38,38,38,1);
            font-size: 24px;
            margin-bottom: 10px;
        }
        .h3-pc {
            display: none;
        }
        .h3-mobile {
            text-align: center;
        }
        p {
            display: none;
        }
    }
    .vpn-with-aa {
        color: #fff;
        padding-top: 100px;
        padding-bottom: 177px;
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
            text-align: center;
        }
        p {
            font-size: 16px;
            text-align: center;
        }
    }
    .why-choose-bitvpn {
        margin-bottom: 10px;
        margin-top: 24px;
        text-align: center;
        font-size: 42px;
        h2 {
            font-size:24px;
        }
        ul {
            text-align: center;
            margin-top: 24px;
        }
        li {
            padding: 0 30px;
            margin-bottom: 41px;
            display: flex;
            text-align: left;
        }
        img {
            width: 78px;
            margin-right: 20px;
        }
        h4 {
            color: #262626;
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            color: rgba(38,38,38,1);
            font-size: 14px;
        }
    }
    .h-top {
        position: relative;
        margin-top: 40px;
        img {
            display: none;
        }
        h1 {
            font-size: 42px;
            color: #262626;
            line-height:48px;
        }
        p {
            font-size: 16px;
            color: rgba(38,38,38,1);
            margin-bottom: 32px;
        }
        .get-now-wrap {
            padding: 0 11px;
        }
    }
    .banner-img {
        width: 100%;
        overflow: hidden;
        margin-top: 50px;
        background:linear-gradient(180deg,rgba(255,255,255,1) 0%,rgba(207,207,207,1) 100%);
        img {
            width: 110%;
            vertical-align: top;
        }
    }
    .posu {
        text-align: center;
        color: rgba(38,38,38,1);
        margin-top: 40px;
        .posu-item {
            text-align: left;
            display: flex;
            margin-bottom: 48px;
        }
        img {
            width: 64px;
            margin-right: 27px;
        }
        h4 {
            font-size: 16px;
            margin-bottom: 7px;
        }
        p {
            font-size: 14px;
        }
        .posu-first-img {
            margin-right: 40px;
        }
    }
    .get-bitvpn {
        text-align: center;
        padding-top: 6px;
        h2 {
            font-size: 24px;
            color: rgba(38,38,38,1);
            margin-bottom: 22px;
        }
        .map-bg {
            background-image: url('../../assets/images/home_map_m.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding: 0 16px;
        }
    }
}
@media (min-width: 576px) {
    .home {
        background-image: url('../../assets/images/home_header_bg.png');
        .bg-container {
            background-image: url('../../assets/images/home_computer2.png');
            background-size: 2000px;
        }
        .get-bitvpn {
            h2 {
                font-size: 56px;
                margin-bottom: 40px;
            }
            .map-bg {
                background-image: url('../../assets/images/home_map.png');
            }
        }
        .posu {
            margin-top: 58px;
            h4 {
                font-size: 24px;
            }
            p {
                font-size: 17px;
            }
            .posu-item {
                width: 50%;
                padding-right: 100px;
                margin-bottom: 70px;
            }
            img {
                margin-right: 37px;
            }
            .posu-first-img {
                margin-right: 50px;
            }
        }
        .bit-video {
            padding: 40px;
            display: flex;
            flex-direction: row-reverse;
            background-image: url('../../assets/images/home_video_bg.png');
            background-size: 233px 231px;
            .youtube-container-img {
                margin-right: 56px;
                flex-shrink: 0;
                width: 540px;
                max-width: 540px;
            }
            h3 {
                font-size: 60px;
                margin-bottom: 8px;
            }
            .h3-mobile {
                display: none;
            }
            .h3-pc {
                display: block;
            }
            p {
                font-size:36px;
                display: block;
            }
        }
        .vpn-with-aa {
            padding-top: 170px;
            padding-bottom: 310px;
            h2 {
                font-size: 56px;
                margin-bottom: 8px;
            }
            p {
                font-size: 24px;
                text-align: left;
            }
        }
        .why-choose-bitvpn {
            margin-top: 103px;
            margin-bottom: 132px;
            h2 {
                font-size:42px;
            }
            ul {
                margin-top: 46px;
            }
            h4 {
                font-size: 24px;
                margin: 13px 0 3px;
            }
            p {
                font-size: 17px;
            }
            li {
                display: block;
                text-align: center;
            }
            img {
                margin-right: 0;
            }
        }
        .h-top {
            h1 {
                font-size: 42px;
                line-height: 48px;
            }
            p {
                font-size: 16px;
                margin: 2px 0 32px;
            }
            .get-now-wrap {
                padding: 0;
                button {
                    width: 260px;
                }
            }
            img {
                display: inline-block;
                width: 300px;
                bottom: -0px;
                right: -40px;
                position: absolute;
            }
            .left-content {
                width: 300px;
            }
            button {
                padding: 14px 40px;
            }
        }
        .banner-img {
            display: none;
        }
    }
}
@media (min-width: 768px) {
    .home {
        .h-top {
            img {
                width: 510px;
                bottom: -30px;
            }
        }
    }
}
@media (min-width: 992px) {
    .home {
        .h-top {
            h1 {
                font-size: 76px;
                line-height: 83px;
            }
            p {
                font-size: 24px;
                margin: 16px 0 36px;
            }
            .get-now-wrap {
                padding: 0;
            }
            img {
                right: -20px;
            }
            .left-content {
                width: 500px;
            }
            button {
                padding: 24px 40px;
            }
        }
    }
}
@media (min-width: 1200px) {
    .home {
        .h-top {
            img {
                width: 700px;
            }
        }
    }
}
@media (min-width: 1500px) {
    .home {
        .h-top {
            img {
                right: -120px;
                width: 820px;
            }
        }
    }
}
</style>
