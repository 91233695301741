<template>
    <div class="bit-braintree">
        <div id="braintree-form">
            <div class="braintree" >
                <div class="input-item">
                    <label for="braintree_card_name">Name on Card</label>
                    <bit-input id="braintree_card_name" v-model="cardName" placeholder="" :class="checkErrorList.cardName === true ? 'check-error' : ''"></bit-input>
                    <div class="invalid-feedback" :class="checkErrorList.cardName === true ? 'tip-error' : 'invalid-feedback'">
                        <!-- Security code required -->
                        Please fill out a cardholder name.
                    </div>
                </div>
                <div class="input-item">
                    <label for="cc-number">Card Number</label>
                    <div class="input-item-wrap" id="cc-number" :class="checkErrorList.number === true ? 'check-error' : ''"></div>
                    <div class="invalid-feedback" :class="checkErrorList.number === true ? 'tip-error' : ''" v-if="emptyList.number">
                        <!-- Security code required -->
                        Please fill out a card number.
                    </div>
                    <div class="invalid-feedback" :class="checkErrorList.number === true ? 'tip-error' : ''" v-else-if="!emptyList.number">
                        <!-- Security code required -->
                        This card number is not valid.
                    </div>
                </div>
                <div class="row">
                    <div class="input-item col-12 col-sm-4">
                        <label for="braintree_exp_date">Exp. Date</label>
                        <div class="input-item-wrap" id="braintree_exp_date" :class="checkErrorList.expirationDate === true ? 'check-error' : ''"></div>
                        <div class="invalid-feedback" :class="checkErrorList.expirationDate === true ? 'tip-error' : ''" v-if="emptyList.expirationDate">
                            <!-- Security code required -->
                            Please fill out an expiration date.
                        </div>
                        <div class="invalid-feedback" :class="checkErrorList.expirationDate === true ? 'tip-error' : ''" v-else-if="!emptyList.expirationDate">
                            <!-- Security code required -->
                            This expiration date is not valid.
                        </div>
                    </div>
                    <div class="input-item col-12 col-sm-4">
                        <label for="braintree_bit_cvv">CVV</label>
                        <div class="input-item-wrap" id="braintree_bit_cvv" :class="checkErrorList.cvv === true ? 'check-error' : ''"></div>
                        <div class="invalid-feedback" :class="checkErrorList.cvv === true ? 'tip-error' : ''" v-if="emptyList.cvv">
                            <!-- Security code required -->
                            Please fill out a CVV.
                        </div>
                        <div class="invalid-feedback" :class="checkErrorList.cvv === true ? 'tip-error' : ''" v-else-if="!emptyList.cvv">
                            <!-- Security code required -->
                            This security code is not valid.
                        </div>
                    </div>
                    <div class="input-item col-12 col-sm-4">
                        <label for="braintree_postal_code">ZIP Code</label>
                        <div class="input-item-wrap" id="braintree_postal_code" :class="checkErrorList.postalCode === true ? 'check-error' : ''"></div>
                        <div class="invalid-feedback" :class="checkErrorList.postalCode === true ? 'tip-error' : ''" v-if="emptyList.postalCode">
                            <!-- Security code required -->
                             Please fill out a postal code.
                        </div>
                         <div class="invalid-feedback" :class="checkErrorList.postalCode === true ? 'tip-error' : ''" v-else-if="!emptyList.postalCode">
                            <!-- Security code required -->
                             This postal code is not valid.
                        </div>
                    </div>
                </div>
                <div class="b-line"></div>
                <pricing-terms  type="creditCard"></pricing-terms>
                <div class="b-btn-wrap">
                    <button class="green-button" id="braintree-submit" disabled @click="submitInfoToBraintree">Continue</button>
                </div>
                <pricing-money-back></pricing-money-back>
                <bit-popup-email v-if="showpupemail" @closePop="hidePop" type='creditCard' @buyCkick="submitInfoToBraintree" > </bit-popup-email>
            </div>
        </div>
    </div>
</template>

<script>
import { apiBrainTreeBuy, apiGetToken } from '@/fetch/api';
import { loadScript, loadRecaptcha } from '@/utils/common';
import { mapState, mapActions } from 'vuex';

function loadBraintree() {
    if (window.braintree && window.braintree.hostedFields) {
        return Promise.resolve();
    }
    return loadScript('https://js.braintreegateway.com/web/3.46.0/js/client.min.js')
        .then(() => loadScript('https://js.braintreegateway.com/web/3.46.0/js/hosted-fields.min.js'));
}
export default {
    name: 'BitBraintree',
    data() {
        return {
            showpupemail: false,
            cardName: '',
            checkErrorList: {
                cardName: false,
                number: false,
                expirationDate: false,
                cvv: false,
                postalCode: false,
            },
            emptyList: {
                number: true,
                expirationDate: true,
                cvv: true,
                postalCode: true,
            },
        };
    },
    watch: {
        cardName(val) {
            if (val !== '') {
                this.checkErrorList.cardName = false;
            } else {
                this.checkErrorList.cardName = true;
            }
        },
    },
    mounted() {
        setTimeout(() => {
            Promise.all([loadBraintree(), apiGetToken()]).then((data) => {
                // console.log('token --- ', data[1].data.clientToken);
                if (data[1].data.result === 1) {
                    this.initBrain(data[1].data.clientToken);
                }
            });
        }, 100);
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    methods: {
        ...mapActions([
            'upgradetype',
        ]),
        hidePop() {
            this.showpupemail = false;
        },
        submitInfoToBraintree() {
            if (!this.hostedFieldsInstance) {
                return;
            }
            this.checkErrorList = {
                cardName: false,
                number: false,
                expirationDate: false,
                cvv: false,
                postalCode: false,
            };
            this.hostedFieldsInstance.tokenize({cardholderName: this.cardName}, (tokenizeErr, payload) => {
                const productId = this.products.productId;
                if (tokenizeErr || this.cardName === '') {
                    console.log(tokenizeErr);
                    if (tokenizeErr) {
                        this.$gce('Pricing', 'brainTreeprocessPaymentFailed', `${productId}--reseaon:${tokenizeErr.message}`);
                        if (tokenizeErr.details === undefined) {
                            this.checkErrorList.number = true;
                            this.checkErrorList.expirationDate = true;
                            this.checkErrorList.cvv = true;
                            this.checkErrorList.postalCode = true;
                        } else {
                            Object.keys(tokenizeErr.details.invalidFieldKeys).forEach((key) => {
                                this.checkErrorList[tokenizeErr.details.invalidFieldKeys[key]] = true;
                            });
                        }
                    }
                    if (this.cardName === '') {
                        this.$gce('Pricing', 'brainTreeprocessPaymentFailed', `${productId}--reseaon:Name on Card is empty`);
                        this.checkErrorList.cardName = true;
                    }
                    return;
                }
                const userId = this.userInfo.userId;
                if ((userId === '' || userId === undefined) && (localStorage.getItem('email') === '' || localStorage.getItem('email') === undefined)) {
                    this.showpupemail = true;
                    return;
                }
                const nonce = payload.nonce;
                const email = this.userInfo.email;
                loadRecaptcha().then((robotToken) => {
                    let params = {};
                    let md5 = require('md5');
                    let language = (navigator.browserLanguage || navigator.language).toLowerCase();
                    language = language.includes('ar') ? 'ar' : language
                    if (email !== '' && email !== undefined) {
                        params = {
                            email,
                            productId,
                            payment_method_nonce: nonce,
                            userChannelType: 0,
                            sourceType: 1,
                            'g-recaptcha-response': robotToken,
                            googles: md5('1qaz_' + new Date().getTimezoneOffset() / 60 + '_' + language + '_7ujm'),

                        };
                    } else if (userId !== '' && userId !== undefined) {
                        params = {
                            userId,
                            productId,
                            payment_method_nonce: nonce,
                            userChannelType: 0,
                            sourceType: 1,
                            'g-recaptcha-response': robotToken,
                            googles: md5('1qaz_' + new Date().getTimezoneOffset() / 60 + '_' + language + '_7ujm'),
                        };
                    }
                    apiBrainTreeBuy(params).then((data) => {
                        const needData = data.data;
                        if (needData.result === 1) {
                            this.$gce('Pricing', 'processPaymentSuccess', productId);
                            localStorage.setItem('payMethod', needData.payMethod);
                            localStorage.setItem('orderTime', needData.orderTime);
                            localStorage.setItem('endTime', needData.endTime);
                            localStorage.setItem('amount', needData.price);
                            localStorage.setItem('productName', needData.productName);
                            localStorage.setItem('orderId', needData.orderId);
                            if (needData.redeemCode !== undefined) {
                                localStorage.setItem('redeemCode', needData.redeemCode);
                            }
                            if (needData.oldEndTime !== undefined) {
                                localStorage.setItem('oldEndTime', needData.oldEndTime);
                            }
                            this.upgradetype(9);
                            this.$router.push({name: 'vpn-purchase-thank-you'});
                        } else {
                            this.$toast({
                                toastMsg: 'Transaction Failure',
                            });
                            this.$gce('Pricing', 'processPaymentFailed', `${productId}'--'${needData.code}'--'${needData.reason}`);
                        }
                    }).catch((err) => {
                        console.log(`err---- ${err}`);
                        this.$toast({
                            toastMsg: 'Transaction Failure',
                        });
                        this.$gce('Pricing', 'processPaymentFailed', `${productId}'--'${err}`);
                    });
                });
            });
        },
        initBrain(token) {
            // console.log('token --- ', token);
            window.braintree.client.create({
                authorization: token || 'sandbox_g42y39zw_348pk9cgf3bgyw2b',
            }, (clientErr, clientInstance) => {
                if (clientErr) {
                    console.error(clientErr);
                    return;
                }

                // This example shows Hosted Fields, but you can also use this
                // client instance to create additional components here, such as
                // PayPal or Data Collector.

                window.braintree.hostedFields.create({
                    client: clientInstance,
                    styles: {
                        input: {
                            'font-size': '18px',
                            color: 'rgba(38,38,38,1)',
                            transition: 'all .3s',
                            outline: 'none',
                        },
                        'input.invalid': {
                            color: 'red',
                        },
                        'input.valid': {
                            color: 'block',
                        },
                    },
                    fields: {
                        number: {
                            selector: '#cc-number',
                            placeholder: '**** **** **** ****',
                        },
                        cvv: {
                            selector: '#braintree_bit_cvv',
                            placeholder: '•••',
                        },
                        expirationDate: {
                            selector: '#braintree_exp_date',
                            placeholder: 'MM/YY',
                        },
                        postalCode: {
                            selector: '#braintree_postal_code',
                            placeholder: '',
                        }
                    },
                }, (hostedFieldsErr, hostedFieldsInstance) => {
                    if (hostedFieldsErr) {
                        console.error(hostedFieldsErr);
                        this.$gce('Pricing', 'initPaymentFailed', this.products.productId);
                        return;
                    }
                    this.$gce('Pricing', 'initPaymentSuccess', this.products.productId);
                    document.querySelector('#braintree-submit').removeAttribute('disabled');
                    this.hostedFieldsInstance = hostedFieldsInstance;
                    hostedFieldsInstance.on('validityChange', (event) => {
                        // console.log(event.fields[event.emittedBy].isValid);
                        this.checkErrorList[event.emittedBy] = !event.fields[event.emittedBy].isValid;
                        // if (event.fields[event.emittedBy].isEmpety) {
                        //     this.emptyList[event.emittedBy] = true;
                        // } else {
                        //     this.emptyList[event.emittedBy] = false;
                        // }
                    });
                    hostedFieldsInstance.on('notEmpty', (event) => {
                        // console.log(event.fields[event.emittedBy].isValid);
                        console.log(event.emittedBy, 'is not now empty');
                        this.emptyList[event.emittedBy] = false;
                    });
                    hostedFieldsInstance.on('empty', (event) => {
                        // console.log(event.fields[event.emittedBy].isValid);
                        console.log(event.emittedBy, 'is now empty');
                        this.emptyList[event.emittedBy] = true;
                    });
                });
            });
        },
    },
};
</script>

<style lang="stylus">
.bit-braintree {
    .braintree {
    }
    .input-item-wrap {
        padding: 9px 16px;
        background: rgba(250,250,250,1);
        border: 1px solid rgba(211,211,211,1);
        width: 100%;
        color: rgba(38,38,38,1);
        font-size: 18px;
        transition: all .3s;
        outline: none;
        height: 40px;
        &.braintree-hosted-fields-focused {
            background: rgba(255,255,255,1);
            border: 1px solid rgba(102,102,102,1);
        }
    }
    .invalid-feedback{
        color : rgba(255,255,255,1);
        height :22px;
        user-select: none;
    }
    .check-error{
        border: 1px solid #CA2A2A;
    }
    .tip-error{
        color : #CA2A2A;
    }
    .b-line {
        border-top: 1px solid rgba(222,222,222,1);
        padding-top: 0px;
        margin-top: 8px;
    }
    .b-btn-wrap {
        margin: 24px 0px;
    }
    .input-item {
        margin-bottom: 10px;
    }
}
@media (min-width: 576px) {
    .bit-braintree {
        .b-line {
            padding-top: 10px;
            margin-top: 0px;
        }
        .b-btn-wrap {
            margin: 32px 0px 24px;
        }
    }
}
@media (max-width: 576px) {
    .bit-braintree {
        .input-item {
            margin-bottom: 0px;
        }
    }
}
</style>
