<template>
    <div class="bit-pay container">
        <div class="row">
            <div class="col-xl-9 col-12" style="margin-bottom: 20px;">
                <div class="pay-container left-pay">
                    <div class="pay-price">
                        <div class="pay-name">
                        <span>BitVPN {{products.month === 6 ? 'Six' : 'One'}}-{{products.month === 6 ? 'Months' : (products.month === 12 ? 'Year' : 'Month')}} Plan</span>
                        <span>${{products.avg}} X {{products.month > 1  ? products.month + ' months' : products.month + ' month'}}</span>
                        </div>
                        <div class="big-price bitm">
                        <span>Total</span>
                        <div>
                            <span class="unit">USD</span>
                            <span>${{products.amount}}</span>
                        </div>
                        </div>
                    </div>
                    <!-- creditCard -->
                    <bit-braintree v-if="type === 'creditCard'"></bit-braintree>
                    <!-- paypal -->
                    <div class="paypal" v-if="type === 'paypal'">
                        <bit-paypal-old
                            :amount="{value: products.amount}"
                            custom="1"
                            clientId="AU_m0IwR_jzxlswFk47lo4bNYmWfEEQAquRUex9YjxIcMrReVES_PwhyHM2E9pvlvx5G4dOtUTDKIi3U"
                            @clickPayButton="initPaypalInterface"
                            @myexcutePayment="executePaypalBuy"
                            @onCancel="paymentCancel"
                        ></bit-paypal-old>
                    </div>
                    <!-- BTC -->
                    <bit-btc v-if="type === 'btc'"></bit-btc>
                    <!-- TOP -->
                    <bit-top v-if="type === 'top'"></bit-top>
                    <!-- paymentwall -->
                    <bit-paymentwall v-if="type === 'other'" :from="from"></bit-paymentwall>
                </div>
            </div>
            <div class="col-xl-3 col-12 ">
                <bit-right-sulution></bit-right-sulution>
            </div>
        </div>
    </div>
</template>

<script>
// import {
//   apiGetToken,
//   apiBrainTreeBuy,
//   apiTopBuy,
//   apiTopOrder,
//   apiBtcBuy,
//   apiBtcOrder,
//   apiExecutePaymentwallUrl,
//   apiCreatePayment,
//   apiExecutePayment
// } from "@/fetch/api.js";
import { mapState } from 'vuex';

export default {
    components: {},
    name: 'BitPay',
    props: {
        from: {
            type: String,
            default: 'pc',
        },
        type: {
            type: String,
        },
    },
    computed: {
        ...mapState(['products']),
    },
    mounted() {},
    methods: {
        handleClick(evt) {
            this.$emit('click', evt);
        },

        // 初始化paypal插件
        initPaypalInterface() {
            // const params = {
            //     productId: this.products.productId,
            //     sourceType: 1,
            // };

            // apiCreatePayment(params).then(data => {
            // });
        },

        // 执行购买
        executePaypalBuy() {
            // const params = {
            //     productId: this.products.productId,
            //     sourceType: 1,
            //     payerId: paramsObj.payerId,
            //     paymentId: paramsObj.orderId,
            //     userChannelType: 0,
            // };
            // console.log('初始化paypal成功 ----' + params);

            // apiExecutePayment(params)
            //     .then(data => {
            //         //  console.log(data);
            //     })
            //     .catch(err => {
            //         //  console.log(err);
            //     });
        },
        //  取消支付
        paymentCancel() {
            //  console.log("取消支付 -----------");
        },
    },
};
</script>
<style lang="stylus">
.green-button {
    padding: 14px 24px;
    background:linear-gradient(90deg,rgba(147,39,161,1) 0%,rgba(112,39,161,1) 100%);
    border-radius: 8px;
    font-size: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 33px;
    cursor: pointer;
    min-width: 300px;
    transition: all 0.5s;
}
.green-button[disabled] {
    color: rgba(255, 255, 255, 1);
    background: rgba(172, 167, 171, 1);
    box-shadow: 0px 2px 10px 0px rgba(166, 166, 166, 0.5);
    transition: all 0.5s;
    cursor: not-allowed;
    min-width: 300px;
}
.input-item {
    margin-bottom: 24px;
    label {
        font-size: 18px;
        margin-bottom: 8px;
        display: inline-block;
    }
}
@media (min-width: 576px) {
    .input-item {
        margin-bottom: 30px;
    }
}
</style>

<style lang="stylus">
.bit-pay {
    color: rgba(38, 38, 38, 1);
    padding-left: 0;
    padding-right: 0;
    .promise {
        p {
            display: flex;
            font-size: 17px;
            margin-bottom: 16px;
        }
        img {
            width: 16px;
            margin-right: 8px;
            align-self: flex-start;
            margin-top: 2px;
        }
    }
    .agree-terms {
        margin-top: 24px;
        li {
            font-size: 17px;
            margin-left: 1em;
            margin-bottom: 16px;
        }
        li::before {
            content: '\2022';
            color: #262626;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
        a {
            color: #0C54F1;
        }
    }
    .left-pay {
        padding-left: 0;
        padding-right: 0;
    }
    .pay-name, .big-price {
        display: flex;
        justify-content: space-between;
    }
    .pay-name {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .big-price {
        font-size: 24px;
        .unit {
            color: #DEDEDE;
            margin-right: 16px;
        }
    }
    .pay-price {
        border-bottom: 1px solid rgba(222, 222, 222, 1);
        padding-bottom: 16px;
        margin-bottom: 24px;
    }
}
@media (min-width: 576px) {
    .bit-pay {
        padding-left: 16px;
        padding-right: 16px;
        .pay-container {
            border: 1px solid rgba(222, 222, 222, 1);
            padding: 24px 24px 28px 24px;
            min-height: 450px;
        }
        .left-pay {
            padding-left: 32px;
            padding-right: 32px;
            flex-grow: 2;
        }

        .pay-name {
            font-size: 18px;
        }
        .big-price {
            font-size: 42px;
            .unit {
                color: #DEDEDE;
                margin-right: 16px;
            }
        }
        .pay-price {
            margin-bottom: 32px;
        }
        .agree-terms {
            li {
                margin-bottom: 8px;
            }
        }
        .promise {
            p {
                margin-bottom: 12px;
            }
        }
    }
}
</style>
