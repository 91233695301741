<template>
  <div class="bit-popup">
      <div class="content">
           <div class="close" @click="close()">x</div>
           <div class="title">Please enter an email address in order to receive your order confirmation and purchase receipt</div>
           <bit-input class="inputsty" id="bit_btc_address" v-model="myinput" placeholder="Email address"></bit-input>
           <div :class="valid == false ? 'tip2' : 'tip'">Invalid email address format</div>
           <div class="btn1">
                 <bit-button size="medium" @click="storageClick()">Confirm</bit-button>
           </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { apiBtcBuy, apiTopBuy, apiExecutePaymentwallUrl } from '@/fetch/api';
import { emailValid, loadRecaptcha } from '@/utils/common';

export default {
    name: 'BitPopupEmail',
    data() {
        return {
            myinput: '',
            valid: false,
            paymentwalluri: '',
        };
    },
    props: {
        type: {
            type: String,
            default: 'creditCard',
        },
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    methods: {
        ...mapActions([
            'upgradeemail',
            'upgradeorderid',
            'upgradetype',
        ]),
        close() {
            this.$emit('closePop', this.paymentwalluri);
        },
        storageClick() {
            if (!emailValid(this.myinput)) {
                this.valid = true;
                this.$gce('Pricing', 'enter_email', 'failed');
                return;
            }
            this.$gce('Pricing', 'enter_email', 'success');
            this.upgradeemail(this.myinput);
            const that = this;
            loadRecaptcha().then((robotToken) => {
                if (this.type === 'btc') {
                    const params = {
                        email: this.myinput,
                        productId: this.products.productId,
                        btcAddress: this.userInfo.btcAddress,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                    apiBtcBuy(params).then((result) => {
                        if (result.data.result === 1) {
                            console.log('sucess');
                            this.upgradetype(101);
                            that.upgradeorderid(result.data.orderId);
                            this.$router.push({name: 'vpn-purchase-btc'});
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else if (this.type === 'top') {
                    const params = {
                        email: this.myinput,
                        productId: this.products.productId,
                        topAddress: this.userInfo.topAddress,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                    apiTopBuy(params).then((result) => {
                        if (result.data.result === 1) {
                            console.log('sucess');
                            this.upgradetype(102);
                            that.upgradeorderid(result.data.orderId);
                            this.$router.push({name: 'vpn-purchase-top'});
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else if (this.type === 'other') {
                    const params = {
                        email: this.myinput,
                        productId: this.products.productId,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                    apiExecutePaymentwallUrl(params).then((result) => {
                        if (result.data.result === 1) {
                            // console.log('sucess111');
                            this.paymentwalluri = result.data.url;
                            this.close();
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else if (this.type === 'creditCard') {
                    this.$emit('buyCkick', '');
                } else if (this.type === 'paypal') {
                    this.$emit('closePop', '');
                }
            });
        },
    },
    watch: {
        myinput() {
            this.valid = false;
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-popup {
    position :fixed;
    top :0;
    bottom :0;
    right :0;
    left :0;
    margin :auto;
    z-index :10001;
    height:100%;
    width:100%;
    overflow: hidden;
    background: rgba(128, 128, 128, 0.6);
    .content{
        width:30%;
        padding: 16px 26px 56px 26px;
        position:absolute;
        z-index :2147483648;
        left :50%;
        top :50%;
        transform:translate(-50%,-50%);
        background :rgba(255,255,255,1) !important;
        @media screen and (max-width: 1700px) {
            width: 40%;
        }
        @media screen and (max-width: 1200px) {
            width: 60%;
        }
        @media screen and (max-width: 970px) {
            width: 70%;
        }
        @media screen and (max-width: 768px) {
            width: 90%;
        }
        @media screen and (max-width: 468px) {
            width: 95%;
        }
        .close{
            position:absolute;
            right:6px;
            top:6px;
            width:24px;
            height:24px;
            font-size:24px;
            color:#999999;
            cursor: pointer;
        }
        .title{
            font-size:24px;
            font-family:PingFangSC-Regular;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:35px;
            margin-top: 60px;
            margin-bottom:30px
        }
        .inputsty{

        }
        .tip{
            color :red;
            font-size :18px;
            height:24px;
        }
        .tip2{
            height:24px;
            color :#fff;
        }
        .btn1{
            margin-top:56px;
            display :flex;
            justify-content :center;
        }
    }
}
</style>
