<template>
    <ul class="agree-terms ">
        <li>
            By clicking <span v-if="type==='creditCard'">'Continue'</span>
            <span v-if="type==='paypal'">'PayPal' </span>
            <span v-if="type==='btc'">'Continue with BTC'</span>
            <span v-if="type==='top'">'Continue with TOP'</span>
            <span v-if="type==='paymentwall'">'Continue with Paymentwall'</span>
             button and purchasing, you agree to our <router-link to="/terms">Terms of Service</router-link> and <router-link to="/privacy-policy">Privacy Policy</router-link>.</li>
        <li>If you have any questions regarding BitVPN's service or subscriptions, please feel free to contact us: <a href="mailto:contact@bitvpn.net">contact@bitvpn.net</a></li>
    </ul>
</template>

<script>
export default {
    name: 'PricingTerms',
    props: {
        type: {
            type: String,
            default: 'creditCard',
        },
    },
};
</script>
