<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <h1 class="bitm">FAQs</h1>
            <div class="faqs-list">
                <div class="content"  v-for="(item,index) in FAQsList" :key="index">
                    <div class="intro" @click.prevent.stop="change(item,index)">
                        <span>{{item.title}}</span>
                        <img :src="item.isshow == false ? images[1].src : images[0].src" height="8" width="12" alt="">
                    </div>
                    <div class="contentdetail" v-show="item.isshow">
                        <p v-html="item.contentdetail"></p>
                    </div>
                </div>
            </div>
        </div>
        <bit-footer></bit-footer>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'Faq',
    data() {
        return {
            images: [
                {src: require('@/assets/images/up_arrow.png')},
                {src: require('@/assets/images/down_arrow.png')},
            ],
            FAQsList: [{
                title: 'What is BitVPN?',
                isshow: false,
                contentdetail: `BitVPN is the pioneer of the decentralized VPN service on blockchain technology, and it arms with an unstoppable mesh network.
`,
            },
            {
                title: 'What services can I do with BitVPN?',
                isshow: false,
                contentdetail: `BitVPN is a multi-purpose digital survival tool. You may use your VPN to:
<br />-Hide your real IP address and surf the web with absolute privacy;<br />-Protect your confidential information through VPN encryption;
<br />-Unblock and access your favorite websites blocked by internet censorship and social media platforms, such as Facebook, Twitter, and YouTube.
`,
            },
            {
                title: 'What platforms does BitVPN support?',
                isshow: false,
                contentdetail: 'BitVPN works across all devices with Android & iOS operating systems, ranging from cell phones, tablets, laptops to PCs.',
            },
            {
                title: 'How does BitVPN differ from other VPN service providers on the market?',
                isshow: false,
                contentdetail: `BitVPN architecture is built with decentralization at the core. It differs from other VPN services in the following aspects:<br />-BitVPN uses distributed nodes owned by the users worldwide<br />-BitVPN uses the blockchain for better security and decentralization<br />-Nodes communicate in a P2P network<br />In other words, no single node has access to who you are and where your traffic is being directed.`,
            },
            {
                title: 'Is BitVPN safe to use?',
                isshow: false,
                contentdetail: `Absolutely yes. BitVPN is not only reliable through privacy policies with promise to protect users’ personal information from being leaked. What’s more, all your data is randomly distributed among decentralized network with strong encryption. It's impossible to steal your data or know what you did, you are completely private and anonymous when using BitVPN to browse.`,
            },
            {
                title: 'Can I use BitVPN for free?',
                isshow: false,
                contentdetail: `Yes. BitVPN provides free service. You can earn online time with VPN by watching videos, taking special offers or inviting a friend to use BitVPN. `,
            },
            {
                title: 'What’s the difference between free service and Premium service on BitVPN?',
                isshow: false,
                contentdetail: `BitVPN free service differs from Premium service in the following perspectives:<br />-Free service is free while Premium should be paid.<br/>-Premium runs much faster than free services, up to 4X faster.<br/>-Free service has only 1 VPN server while Premium has multiple servers scattered around the world.<br/>-Premium service has unlimited data while free service data has a limitation.<br/>-Premium service has no ads while free service does.<br/>-Premium accounts can be used on different devices simultaneously but those of free service can’t.`,
            },
            {
                title: 'Can I try BitVPN Premium for free?',
                isshow: false,
                contentdetail: `Yes. BitVPN provides a 7-day free trial service as you purchase a subscription. You can cancel the subscription anytime you like, and you will not be charged if you cancel the free trial 24 hours before the end of the 7-day free trial period.`,
            },
            {
                title: 'What should I do when I have an issue in using BitVPN?',
                isshow: false,
                contentdetail: `Submit your issues in the app to our support team, and we’ll get back to you soon. Here is how. Tap on the ‘menu’ icon on the upper left corner in the app > choose ‘Support’ > select type of questions and enter your questions > tap on the ‘Submit’ button. Done!`,
            },
            {
                title: 'What should I do if I forget my password?',
                isshow: false,
                contentdetail: 'If you forget the password of your BitVPN account, there is only one way out: create a new BitVPN account. As you successfully sign up, a BitVPN ID will be created. Then send the BitVPN ID (How to find my BitVPN ID?) of your new account and a screenshot of your Premium payment bill to our support team. Once verified successfully, your entitlements in the old account will be transferred to your current new account.',
            },
            {
                title: 'What is my BitVPN ID? Where can I find it?',
                isshow: false,
                contentdetail: `BitVPN ID, a string of numbers, is your unique identity in BitVPN app. You can find it at the bottom of the sidebar by simply tapping on the ‘menu’ icon at the upper left corner of BitVPN app.`,
            },
            {
                title: 'What should I do when my VPN connection gets slow?',
                isshow: false,
                contentdetail: `VPN stands for Virtual Private Network, and it allows you to access the internet privately and anonymously. VPN also enables you to browse online contents you want wherever you are.`,
            },
            {
                title: 'Why should I use VPN?',
                isshow: false,
                contentdetail: `If you are using a regular browser to surf the internet, your IP address, online records, and all your relevant data is broadcast for everyone to see. Hackers, ISP, and government can track and monitor everything you did online. The only way to secure your connection is to use a VPN service. With encrypted traffic running through multiple IP addresses, your entry and exit points cannot be connected with each other.`,
            },
            {
                title: 'What is a decentralized VPN?',
                isshow: false,
                contentdetail: `A decentralized VPN is a type of network that uses distributed, separately owned servers to secure users' internet connection. In decentralized VPN, everything is generated and distributed by users themselves. On the contrary, a centralized VPN provider owns its servers and controls all the data.`,
            },
            {
                title: 'What is a mesh network?',
                isshow: false,
                contentdetail: `The devices on the mesh network connect to all other devices nearby. The devices also known as nodes, sending data to other nodes that are within range, and the data moves through the network node to node until it reaches its final destination.`,
            }
            ]
        };
    },
    methods: {
    // 点击图标按钮 隐藏文案
        change(item, index) {
            this.FAQsList[index].isshow = !item.isshow;
        },
    },
};
</script>

<style lang="stylus" scoped>
.faq {
    h1 {
        font-size: 24px;
        color: rgba(38,38,38,1);
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(222,222,222,1);
        margin-bottom: 24px;
        margin-top: 32px;
    }
    .title {
        font-size: 24px;
        font-weight: 600;
        color: rgba(57,66,102,1);
        line-height: 33px;
        padding-bottom: 20px;
    }
    .content {
        font-size: 18px;
        font-weight: 400;
        color: rgba(115,122,148,1);
        line-height: 33px;
        padding-bottom: 10px;
        transition: all 3s;
        .intro {
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
        span {
            color: rgba(57,66,102,1);
            font-weight: 600;
            line-height: 33px;
            width: 96%;
        }
      .contentdetail {
            padding-top: 4px;
            word-break: break-word;
        }
    }
    .content:nth-last-of-type(1) {
        padding-bottom: 30px;
    }
    .title {
        font-size:18px;
        font-family:Helvetica;
        color:rgba(12,84,241,1);
        line-height:22px;
        &::before {
            content: "\2022";
            color: rgba(153,153,153,1);
            font-weight: bold;
            display: inline-block;
            font-size: 24px;
            height: 19px;
            line-height: 19px;
            width: 16px;
            margin-left: -16px;
            vertical-align: middle;
        }
    }
    .faqs-list {
        margin-bottom: 80px;
    }
}
@media (min-width: 576px) {
    .faq {
        h1 {
            margin-top: 0px;
            font-size: 42px;
            margin-bottom: 40px;
        }
        .faqs-item {
            margin-bottom: 24px;
        }
    }
}
</style>
