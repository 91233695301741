<template>
    <div class="bit-about">
        <bit-header></bit-header>
        <div class="container">
            <h1 class="bitm">Privacy Policy</h1>
            <p class="time">
                Last Modified: January 18, 2018
            </p>
            <div class="about-content">
                <p >
                    The BitVPN.net website (the Website or BitVPN Website), the BitVPN mobile applications (the Software or BitVPN Software) ) and the BitVPN VPN proxy service (the Service) are owned and operated by BitVPN, Inc. ("BitVPN", "us" or "we"). It is BitVPNs policy to respect your privacy regarding any information we may collect while operating our websites and BitVPN Software.
                </p>
                <p >
                    The BitVPN network is a No Log network. BitVPN does not store users originating IP address when connected to our service and therefore cannot identify users when provided IP addresses of our servers. Additionally, BitVPN cannot disclose information about the applications, services or websites our users consume while connected to our services; as BitVPN does not store this information.
                </p>
                <p >
                    <span>The information we may collect</span><br>
                    BitVPN may need to collect the following operational data in order to operate our Services.
                </p>
                <p >
                    • Aggregate bandwidth usage for network operations and support.<br>
                    • Temporary usage data to assist with debugging a problem with the service. This information is not retained once trouble shooting is resolved.<br>
                    • We perform automated rules-based traffic management for the purposes of maintaining and improving our service. Applying these rules may require real-time analysis of Internet and data traffic including destination websites or IP addresses, originating IP addresses. However, no log is maintained regarding this information as it is a real-time process.<br>
                    • The BitVPN website uses cookies, tracking pixels and related technologies. Cookies are small data files that are served by our platform and stored on your device. Our site uses cookies dropped by us or third parties for a variety of purposes including to operate and personalize the website. Also, cookies may also be used to track how you use the site to target ads to you on other websites.<br>
                    • BitVPN collect your email address and other contact information when you request customer support. We also collect information regarding your support request. This information is used to address your inquiry or service problem and to improve our service.<br>
                </p>
                <p >
                    When you install BitVPN on your device, we may automatically collect certain information from your device, including an Android, Apple iOS, or other ID, device maker and model, mobile web browser type and version, IP address, the operating system’s maker and version, MCC (Mobile Country Code) information.
                </p>
                <p >
                    <span>Ads</span><br>
                    Ads appearing on Bitvpn or any of our websites may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Bitvpn and does not cover the use of cookies by any advertisers.
                </p>
                <p >
                    <span>The way we use information</span><br>
                    If you consent, BitVPN may use the collected data in order to provide and maintain our Service, in order to improve BitVPN and to better adapt the service to your needs, in order to send you information on BitVPN, to provide you Updates and Upgrades and in order to notify you on products news and actions.
                </p>
                <p >
                    BitVPN enables you to download software, software updates or patches, or other utilities and tools onto your computer or Internet-enabled device ("Software"). BitVPN grants to you a non-exclusive, limited license to use the Software solely for the purpose stated by BitVPN at the time the Software is made available to you and in accordance with these Terms. Modifying, distributing to unauthorized parties, reverse engineering, or otherwise using the Software in any way not expressly authorized by BitVPN, is strictly prohibited.
                </p>
                <p >
                    • To confirm your subscription identification<br>
                    When you buy subscription service, we’d prompt you to collect your email to verify subscription identification.
                </p>
                <p >
                    • To ensure the product quality<br>
                    When you reach out to us about any product quality issue, we will collect the specific information of your device, such as unique device ID, device type, and operating system type. It helps us to speed up the problem solving and improve the product experience.
                </p>
                <p >
                    • To study and analyze<br>
                    When you become a user of the Service, we will collect the statistic about users’ behavior and country code. By learning and analyzing the statistic, we will improve the service and product to be better.
                </p>
                <p >
                    <span>When we disclose information</span><br>
                    BitVPN forwards no data to third parties. We make express reference to the fact that we do not share data with social networks or advertising networks. BitVPN forwards data only to observe the applicable laws, or to comply with a judicial order, or to prevent or investigate possible wrongdoing in connection with our Service, to protect the personal safety of users of our Service and/or the general public, to implement our General Business Terms.
                </p>
                <p >
                    <span>Privacy Policy Changes</span><br>
                    BitVPN may change its Privacy Policy from time to time, and at BitVPNs sole discretion. BitVPN encourages visitors or users to frequently check this page for any changes to its Privacy Policy.<br>
                    The last revision will be reflected in the "Last Updated" heading. Any change of this policy will be posted in the Site. Your continued access to the Website or using of the Software after any change in this Privacy Policy will constitute your acceptance of such a change.
                </p>
                <p >
                    <span>Contact Us </span><br>
                    If you have any questions or comments about this notice or about our privacy practices, you may contact us at: contact@bitvpn.net
                </p>
            </div>
        </div>
        <bit-footer2></bit-footer2>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'PrivacyPolicy',
};
</script>

<style lang="stylus" scoped>
</style>
