import Home from '@/views/home/Home.vue'; // 首页
import Products from '@/views/products/Products.vue'; // 产品列表介绍页面
import Pricing from '@/views/pricing/Pricing.vue'; // 购买页面
import PrivacyPolicy from '@/views/about/PrivacyPolicy.vue'; // PrivacyPolicy
import Terms from '@/views/about/Terms.vue'; // Terms
import Faq from '@/views/about/Faq.vue'; // Faq
import Dp from '@/views/pricing/DigitalPurchase.vue'; // 数字货币购买下一页
import Success from '@/views/pricing/PurchaseSuccess.vue'; // 购买成功页面
import AboutVpn from '@/views/aboutvpn/AboutVpn.vue'; // 购买成功页面
import WhatVpn from '@/views/whatvpn/WhatVpn.vue'; // 购买成功页面
import WhatIp from '@/views/whatip/WhatIp.vue'; // 购买成功页面
import WhatTop from '@/views/whattop/WhatTop.vue'; // 购买成功页面
import WhyVpn from '@/views/whyvpn/WhyVpn.vue'; // 购买成功页面

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
        },
    },
    {
        path: '/vpn-for-ios',
        name: 'vpn-for-ios',
        component: Products,
        meta: {
        },
    },
    {
        path: '/vpn-for-android',
        name: 'vpn-for-android',
        component: Products,
        meta: {
        },
    },
    {
        path: '/vpn-for-windows',
        name: 'vpn-for-windows',
        component: Products,
        meta: {
        },
    },
    {
        path: '/vpn-for-mac',
        name: 'vpn-for-mac',
        component: Products,
        meta: {
        },
    },
    // {
    //     path: '/vpn-price',
    //     name: 'Pricing',
    //     component: Pricing,
    //     meta: {
    //     },
    // },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: {
        },
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq,
        meta: {
        },
    },
    {
        path: '/vpn-purchase-btc',
        name: 'vpn-purchase-btc',
        component: Dp,
        meta: {
        },
    },
    {
        path: '/vpn-purchase-top',
        name: 'vpn-purchase-top',
        component: Dp,
        meta: {
        },
    },
    {
        path: '/vpn-purchase-thank-you',
        name: 'vpn-purchase-thank-you',
        component: Success,
        meta: {
        },
    },
    {
        path: '/about-bitvpn',
        name: 'about-bitvpn',
        component: AboutVpn,
        meta: {
        },
    },
    {
        path: '/what-is-a-vpn',
        name: 'what-is-a-vpn',
        component: WhatVpn,
        meta: {
        },
    },
    {
        path: '/what-is-my-ip',
        name: 'what-is-my-ip',
        component: WhatIp,
        meta: {
        },
    },
    {
        path: '/what-is-top',
        name: 'what-is-top',
        component: WhatTop,
        meta: {
        },
    },
    {
        path: '/why-choose-bitvpn',
        name: 'why-choose-bitvpn',
        component: WhyVpn,
        meta: {
        },
    },
];
