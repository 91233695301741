<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <div class="title">
                {{title}}
            </div>
            <div class="des_tiny">
               <p>It’s never too late to use VPN for your online security and privacy.</p>
                <img src="@/assets/images/whyvpn.png" />
            </div>
            <div class="contentList" v-for="(item, index) in contentList" :key="index">
                <div class="_title">
                    {{item.title}}
                </div>
                <div class="_content">
                    {{item.content}}
                </div>
                <div class="sub_content" v-for="(item1, index1) in item.contentdetail" :key="index1">
                    <div class="sub_title">
                        {{item1.subTitle}}
                    </div>
                    <div class="sub_sub" v-for="(item2, index2) in item1.details"  :key="index2" v-html="item2">
                    </div>
                </div>
            </div>
             <div class="btn-center">
                <bit-button size="medium" @click.stop="download('click_bottom_aboutvpn')">Get BitVPN for 100% Online Privacy</bit-button>
             </div>
        </div>
        
        <bit-footer></bit-footer>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';
export default {
    mixins: [downloadMixin],
    name: 'Faq',
    data() {
        return {
            title: 'Why BitVPN?',
            contentList: [{
                title: 'BitVPN: A Decentralized VPN',
                content: 'To protect your online privacy from head to toe.',
                contentdetail: [{
                    subTitle: '',
                    details: [
`• No Registration Access`,
`To use BitVPN, you can get your VPN connection just with a username. Your email or phone number isn’t needed and your privacy will be under protection even before you start.`
]
                },
                 {
                    subTitle: '',
                    details: [
`• P2P Mesh Network`,
`In decentralized network, everyone can be a node to contribute to VPN service and get paid for their provided service. Data transmission is faster and safer with BitVPN used.`
]
                },
                {
                    subTitle: '',
                    details: [
`• Truly No Logging`,
`Your data can never be logged by anyone or any organization when BitVPN is used. The distributed nodes in decentralized VPN mechanism essentially stop data from being logged.`
]
                },
                {
                    subTitle: '',
                    details: [
`• Blockchain Technology Based`,
`BitVPN is backed up by the blockchain technology. There’s no single chief node or server on the chain and all the nodes are distributed on the network.`
]
                },
                {
                    subTitle: '',
                    details: [
`• Multiple Devices Support`,
`Anonymity can be achieved anytime and anywhere and BitVPN supports multiple devices including Mac, Windows, iOS and Android.`
]
                },
                {
                    subTitle: '',
                    details: [
`• Hack-Resistant`,
`Layers of data protections are carried out to preserve the privacy of individuals or organizations. As such, your online activity can never be tracked or hacked when BitVPN is turned on. `
]
                },
                {
                    subTitle: '',
                    details: [
`• Unlimited Bandwidth`,
`Most VPNs feature limited data, while decentralized VPN makes you stream, download and browse as you like by scaling up your bandwidth speed.`
]
                },
                {
                    subTitle: '',
                    details: [
`• Open Source`,
`Transparency and collaboration are the core of decentralized VPN. Open source leads all community members to establish a safe structure. `
]
                },
                ]
            },
            {
                title: '100,000,000+ users are protecting their online privacy using BitVPN.',
                content: '',
                contentdetail: [{
                    subTitle: '',
                    details: [`Download BitVPN now, and you’ll get:`]
                }
                ,{
                    subTitle: '',
                    details: [`
<p class="right_before none">A Decentralized Network based on Blockchain</p>`,
`<p class="right_before none">Peer-to-Peer Mesh Network</p>`,
`<p class="right_before none">One-Click Access to VPN with NO Registration</p>`,
`<p class="right_before none">iOS, Android, Mac and Windows Supporting</p>`,
`<p class="right_before none">No Logging Mechanism based on Multiple Nodes</p>`,
`<p class="right_before none">Fast and Secure Access to Internet</p>`,
`<p class="right_before none">Access to Censored & Blocked Websites</p>`,
`<p class="right_before none">Unlimited Bandwidth</p>`,
`<p class="right_before none">24/7 Customer Service</p>`,
`<p class="right_before none">Safe Transmission of Data</p>`]
                },
                ]
            },
            ],
        };
    },
    methods: {
        down() {
           
        },
    },
};
</script>

<style lang="stylus" scoped>
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.des_tiny {
    text-align center
    margin 40px 0 43px
    img {
        width 100%
        max-height 100%
    }
    p{
        text-align left
        margin-bottom 76px;
    }
}

.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 20px
        font-size 16px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
            >>>.right_before {
               position relative
               padding-left 16px
               margin-top 15px
               &:before {
                   content:url('../../assets/images/whatvpn_right.png');
                   position: absolute;
                   left -4px
                   top 4px
               }
            }
            >>>.none {
                margin-top 0px
            }
            
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}

</style>
