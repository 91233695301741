<template>
    <div class="bit-video-modal" @click="$emit('close')">
        <div class="modal-container" @click.stop.prevent :style="{width: videoWidth + 'px',height: videoHeight + 'px'}">
            <div class="close-icon" @click="$emit('close')">×</div>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/90qb6F3llF8?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videoWidth: '',
            videoHeight: '',
        };
    },
    name: 'BitVideoModal',
    mounted() {
        this.onResize();
    },
    methods: {
        onResize() {
            if (window.innerWidth > 768) {
                this.videoWidth = '';
                this.videoHeight = '';
            } else {
                this.videoWidth = window.innerWidth - 20;
                this.videoHeight = this.videoWidth * 0.56;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-video-modal {
    position: fixed;
    top: 0;
    height: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.6);
    padding: 20px;
    .modal-container {
        background: #000;
        text-align: center;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        .close-icon {
            cursor: pointer;
            display: inline-block;
            width: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 42px;
            font-weight: bold;
            color: rgba(0,0,0,0.6);
            background: #fff;
            position: absolute;
            top: -100px;
            right: 0px;
            cursor: pointer;
            border-radius: 50%;
            height: 80px;
        }
    }
}
@media (min-width: 768px) {
    .bit-video-modal {
        .modal-container {
            max-width: 500px;
            height: 281px;
            .close-icon {
                top: -80px;
                right: -100px;
            }
        }
    }
}
@media (min-width: 992px) {
    .bit-video-modal {
        .modal-container {
            max-width: 750px;
            height: 422px;
        }
    }
}

@media (min-width: 1200px) {
    .bit-video-modal {
        .modal-container {
            max-width: 1000px;
            height: 563px;
        }
    }
}
</style>
