<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <div class="title">
                {{title}}
            </div>
            <div class="contentList" v-for="(item, index) in contentList" :key="index">
                <div class="_title">
                    {{item.title}}
                </div>
                <div class="_content">
                    {{item.content}}
                </div>
                <div class="sub_content" v-for="(item1, index1) in item.contentdetail" :key="index1">
                    <div class="sub_title">
                        {{item1.subTitle}}
                    </div>
                    <div class="sub_sub" v-for="(item2, index2) in item1.details"  :key="index2" v-html="item2">

                    </div>
                </div>
            </div>
             <div class="btn-center">
                <bit-button size="medium" @click.stop="download('click_bottom_aboutvpn')">Get BitVPN</bit-button>
             </div>
        </div>
        
        <bit-footer></bit-footer>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';
export default {
    mixins: [downloadMixin],
    name: 'Faq',
    data() {
        return {
            title: 'What Is My IP?',
            contentList: [{
                title: 'What Exactly Is My IP?',
                content: `IP address, short for Internet Protocol address, is a unique number provided by ISP (Internet Service Provider) to stand for an Internet device. You can imagine IP as an address for a device in the world of Internet. When one device wants to send messages to another device, it should first know where its counterpart is located.`,
                contentdetail: [
                ]
            },
            {
                title: 'How Is My IP Generated?',
                content: `Since IP address is a unique number, it can never be repeated. IP address can be generated in two methods: IPv4 and IPv6.`,
                contentdetail: [{
                    subTitle: '',
                    details: [`IPv4 address is a traditional IP address and uses 32-bit number to define network and host address. A 32-bit number can provide about 4 billion unique numbers. As a result, IPv4 IP addresses will be used up as more Internet devices are connected to the Internet.`]
                },
                {
                    subTitle: '',
                    details: [`So, a new version of IP address, IPv6, is generated to provide unique and countless numbers. Using “dotted decimal”, IPv6 addresses consist of 4 sets of numbers and each set is separated by period. Each period represents 8-bit number in the range from 0 to 255.`]
                },
                {
                    subTitle: '',
                    details: [`The main difference between IPv4 address and IPv6 address lies in their quantity. The former can provide 4 billion addresses with 32 bits used. The latter can provide as many as 3.4×1038 with 128 bits used.`]
                }
                ]
            },
            {
                title: 'What Information does My IP Tell Others?',
                content: 'Your IP address can let others know something about you, including:',
                contentdetail: [{
                    subTitle: '',
                    details: [
`• Which city are you staying in?`,
`• Which country are you exactly?`,
`• What is your current state or province?`,
`• What is your zipcode?`,
`As you scan websites, more your personal information can be revealed through your IP address. For example, as you surf on the Internet, meta data, cookies, browsing habits, likes and dislikes, and fingerprint recognition can be known by website owners, marketers and advertisers. All the information is obtained based on your IP address.`]
                },
                {
                    subTitle: '',
                    details: [
`They can figure out your sketch by combining your location, your visited websites, your online likes, your shared files and your communication counterparts. Then you’ll receive some content and ads prepared especially for you. Or, they can sell the data to some groups calling for it.
`]
                },
                {
                    subTitle: '',
                    details: [
`ISPs have more opportunities to know more about you through your IP address. They can keep log of your browsing activities and may provide loggings to some governments or organizations. Besides, as you visit some websites unprotected by https, your ISPs can get aware of all the unencrypted web pages. Even if they are protected by https, your ISPs still can monitor the domain you’re visiting.
`]
                },
                ]
            },
            {
                title: 'What’s the Difference between Public IP and Private IP?',
                content: 'The IP address provided by your ISP is your public IP. When accessing the Internet, all the devices on the same network share this IP.',
                contentdetail: [{
                    subTitle: '',
                    details: [
`Let’s take your home network for example. Your home network must contain more than one device: your computer, phone, tablet, and router. When they’re connected to the Internet, they use the public IP address assigned to the router.
`]
                },
                {
                    subTitle: '',
                    details: [
`Routers give private IPs to each device on the network so that all the devices can identify and find each other. When they get connected to the Internet, their private IP addresses will remain hidden and only public IP address is visible.
`]
                },
                ]
            },
            {
                title: 'Can I Hide My IP?',
                content: 'You absolutely can.',
                contentdetail: [{
                    subTitle: '',
                    details: [
`Your IP addresses can be perfectly hidden when VPN (Virtual Private Network) is used on your Internet devices.
`]
                },
                {
                    subTitle: '',
                    details: [
`BitVPN is your ultimate Internet privacy solution, ensuring your online browsing anonymous and reliably. With BitVPN’s decentralized network, your data and information will be in TOP security.
`,
`• Truly Decentralized VPN`,
`• Blockchain Platform`,
`• P2P Mesh Network`,
`• NO Logging`]
                },
                ]
            },
            ],
        };
    },
    methods: {
        down() {
           
        },
    },
};
</script>

<style lang="stylus" scoped>
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 10px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
           // line-height 25px
          // margin-top 10px
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}

</style>
