import Vue from 'vue';

Vue.directive('bit-tooltip', {
    // 当被绑定的元素插入到 DOM 中时……
    bind(el, binding) {
        const toolTipInstance = document.createElement('div');
        toolTipInstance.className = 'bit-tooltip';
        toolTipInstance.innerHTML = `<div class="tooltip-arrow"></div><div class="tooltip-inner">${binding.value.content}</div>`;
        let isShow = false;
        let hideTimeoutId;
        function showHandle() {
            clearTimeout(hideTimeoutId);
            if (isShow) {
                return;
            }
            document.body.appendChild(toolTipInstance);
            toolTipInstance.style.left = `${el.offsetLeft - 54}px`;
            toolTipInstance.style.top = `${el.offsetTop - toolTipInstance.offsetHeight - 10}px`;
            isShow = true;
        }

        function hideHandle() {
            if (!isShow) {
                return;
            }
            document.body.removeChild(toolTipInstance);
            isShow = false;
        }
        // 聚焦元素
        el.addEventListener('mouseover', showHandle, false);
        el.addEventListener('click', () => {
            if (isShow) {
                hideHandle();
            } else {
                showHandle();
            }
        }, false);

        el.addEventListener('mouseout', () => {
            hideTimeoutId = setTimeout(() => {
                hideHandle();
            }, 300);
        }, false);

        // instance
        toolTipInstance.addEventListener('mouseenter', showHandle, false);
        toolTipInstance.addEventListener('mouseleave', () => {
            hideTimeoutId = setTimeout(() => {
                hideHandle();
            }, 300);
        }, false);
    },
});
