<template>
    <div class="pur-success">
        <bit-header :isPayPage="true"></bit-header>
        <div class="thank-you container">
            <h1 class="bitm">Thank You!</h1>
            <p>This receipt is the only voucher of your purchase and subscription. Please keep it safe.</p>
        </div>
        <div class="save-your-receipt" v-if="userId === '' || userId === undefined || userId === null ">
            <div class="container">
                <h2 class="bitm">Save Your Receipt</h2>
                <div class=" email-input-wrap">
                    <div class="email-label">Email address:</div>
                    <bit-input v-model="email" class=""></bit-input>
                    <div  class="btn-wrap1">
                        <button class="green-button  green-small" @click="sendRedeemCode()" >Edit</button>
                    </div>
                </div>
                <div class="tips">
                    <img src="@/assets/images/icon_tip.png" alt="icon_tip">
                    <p>
                        Privacy Protection: the email address is used for receiving purchase receipt only; it's recommended but not required.
                    </p>
                </div>
                <div class="no-line"></div>
                <h2 class="bitm" >Receipt</h2>
                <p style="font-size: 17px;">This receipt is the only voucher of your purchase and subscription. Please keep it safe.</p>
                <div class="detail">
                    <div class="detail-line">
                        <span>Order ID：</span>
                        <span class="bitm">{{orderId}}</span>
                    </div>
                    <div class="detail-line">
                        <span>Licese key：<img src="@/assets/images/icon_tip.png" alt="icon_tip" v-bit-tooltip="{content: tipContent}"></span>
                        <span class="bitm detail-time">{{redeemCode}}</span>
                    </div>
                    <div class="detail-line">
                        <span>Order date:</span>
                        <span class="bitm">{{orderTime}}</span>
                    </div>
                    <div class="detail-line">
                        <span>payment method:</span>
                        <span class="bitm">{{payMethod}}</span>
                    </div>
                </div>
                <div class="tips">
                    <img src="@/assets/images/icon_tip.png" alt="icon_tip">
                    <p>
                        If you do not receive an email, please contact: <a href="mailto:contact@bitvpn.net">contact@bitvpn.net</a> , Our support team will take care of it within 24 hours.
                    </p>
                </div>
            </div>
        </div>
        <div class="order-details container">
            <h2>BitVPN - {{productName}}</h2>
            <div class="detail">
                <div class="detail-line">
                    <span>Total：</span>
                    <span class="bitm"><span v-if="type != 101 && type != 102">$</span>{{amount}} <span v-if="type==101">BTC</span><span v-if="type==102">TOP</span></span>
                </div>
                <div class="detail-line">
                    <span>Duration：</span>
                    <span class="bitm detail-time">{{month === 1 ? month + ' month' : month + ' months'}} </span>
                </div>
                <div class="detail-line" v-if="userId !== '' && userId !== undefined && userId !== null " >
                    <span>End date:</span>
                    <span class="bitm">{{endTime}}</span>
                </div>
            </div>
            <div class="tips" v-if="!oldEndTime.toString().includes('1970')">
                <img src="@/assets/images/icon_tip.png" alt="icon_tip">
                <p>
                    We've detected another subscription on your account (original end date: {{oldEndTime}}). Additionally, the new subscription you've just purchased has been applied, and your subscription end date has been extended to {{endTime}}.
                </p>
            </div>
            <div class="border-line"></div>
        </div>
        <div class="get-bitvpn container">
            <h2 class="bitm">Download BitVPN</h2>
            <bit-download type='Success'  category="Pricing" action="PurchaseSuccessPage_download_Android"></bit-download>
        </div>
        <!-- <bit-download-modal v-if="showDownloadModal" @close="showDownloadModal = false" category="Pricing" action=""></bit-download-modal> -->
        <bit-footer2></bit-footer2>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { apisendRedeemCode } from '@/fetch/api';

export default {
    components: {
    },
    data() {
        return {
            email: '',
            orderId: '',
            productName: '',
            redeemCode: '',
            orderTime: '',
            endTime: '',
            oldEndTime: '',
            payMethod: '',
            amount: '',
            userId: '',
            type: '', // 101-- btc支付  102-- top支付
            month: '',
            isOpen: false,
            tipContent: 'You can redeem your subscription using the license key (Open BitVPN and tap "Enter License Key" on the sidebar menu). The license key is valid for 2 years, please redeem it as soon as possible.',
        };
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    mounted() {
        this.email = this.$route.query.email === undefined ? localStorage.getItem('email') : this.$route.query.email;
        this.orderId = this.$route.query.orderId === undefined ? localStorage.getItem('orderId') : this.$route.query.orderId;
        this.productName = this.$route.query.productName === undefined ? localStorage.getItem('productName') : this.$route.query.productName;
        this.redeemCode = this.$route.query.redeemCode === undefined ? localStorage.getItem('redeemCode') : this.$route.query.redeemCode;
        // this.orderTime = new Date(Number(localStorage.getItem('orderTime'))).toGMTString();
        // this.endTime = new Date(Number(localStorage.getItem('endTime'))).toGMTString();
        this.orderTime = this.formatUnixtimestamp(this.$route.query.orderTime === undefined ? localStorage.getItem('orderTime') : this.$route.query.orderTime);
        this.endTime = this.formatUnixtimestamp(this.$route.query.endTime === undefined ? localStorage.getItem('endTime') : this.$route.query.endTime);
        this.oldEndTime = this.formatUnixtimestamp(this.$route.query.oldEndTime === undefined ? localStorage.getItem('oldEndTime') : this.$route.query.oldEndTime);
        this.payMethod = this.$route.query.payMethod === undefined ? localStorage.getItem('payMethod') : this.$route.query.payMethod;
        this.amount = this.$route.query.amount === undefined ? localStorage.getItem('amount') : this.$route.query.amount;
        this.userId = this.$route.query.userId === undefined ? localStorage.getItem('userId') : this.$route.query.userId;
        this.type = this.$route.query.type === undefined ? localStorage.getItem('type') : this.$route.query.type;
        this.month = this.$route.query.month === undefined ? localStorage.getItem('month') : this.$route.query.month;
        this.$gce('Pricing', 'enterPurchaseSuccessPage', `${this.products.productId}-- ${this.type}`);
    },
    name: 'PurchaseSuccess',
    methods: {
        sendRedeemCode() {
            const params = {
                orderId: this.orderId,
                redeemCode: this.redeemCode,
                email: this.email,
            };
            apisendRedeemCode(params).then((data) => {
                if (data.data.result === 1) {
                    this.$toast({
                        toastMsg: 'Send Successfully',
                    });
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        // 将时间戳转化为dd-MM-yyyy HH:mm:ss
        formatUnixtimestamp(unixtimestamp) {
            const mytime = new Date(unixtimestamp * 1000);
            const year = 1900 + mytime.getYear();
            const month = `0${(mytime.getMonth() + 1)}`;
            const date = `0${mytime.getDate()}`;
            const hour = `0${mytime.getHours()}`;
            const minute = `0${mytime.getMinutes()}`;
            const second = `0${mytime.getSeconds()}`;
            return ` ${date.substring(date.length - 2, date.length)}-${month.substring(month.length - 2, month.length)}-${year}
                 ${hour.substring(hour.length - 2, hour.length)} :
                ${minute.substring(minute.length - 2, minute.length)} :
                ${second.substring(second.length - 2, second.length)}`;
        },
    },
    watch: {
        // email(newVal) {
        //     this.$nextTick(() => {
        //         this.email = newVal;
        //     });
        // },
    },
};
</script>

<style lang="stylus" scoped>
.pur-success {
    color: #262626;
    .thank-you {
        margin-top: 32px;
        margin-bottom: 32px;
        text-align: center;
        h1 {
            font-size: 46px;
            color: #9327A1;
            margin-bottom: 8px;
        }
        p {
            color: rgba(38,38,38,1);
            font-size: 18px;
        }
    }
    .get-bitvpn {
        margin-top: 24px;
        text-align: center;
        h2 {
            font-size: 24px;
            color: rgba(38,38,38,1);
            margin-bottom: 8px;
        }
    }
    .order-details {
        color:rgba(38,38,38,1);
        font-size:14px;
        padding-top: 32px;
        .border-line {
            border-bottom: 1px solid rgba(222,222,222,1);
            margin-top: 40px;
        }
        h2 {
            font-size: 24px;
            color: rgba(51,51,51,1);
            margin-bottom: 33px;
        }
    }
    .detail {
        margin-bottom: 32px;
    }
    .detail-line {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        span {
            color:rgba(38,38,38,1);
        }
        span:first-child {
            font-family:PingFangSC-Regular;
            font-weight:400;
            flex-shrink: 0;
            margin-right: 10px;
        }
        span:last-child {
            font-family:PingFangSC-Medium;
            font-weight:500;
            text-align: right;
        }
    }
    .save-your-receipt {
        background: rgba(245,245,245,1);
        padding: 32px 0 24px;
        .detail {
            margin-top: 33px;
        }
        .receipts-p {
            font-size: 16px;
        }
        .detail-line {
            span:last-child {
                color: #9327A1;
            }
        }
        h2 {
            margin-bottom: 8px;
            color: rgba(0,0,0,1);
            font-size: 24px;
        }
        .email-input-wrap {
            display: block;
            .btn-wrap1 {
                width: 100%;
                margin-top: 16px;
            }
        }
        .email-label {
            margin-top: 24px;
            margin-bottom: 9px;
            font-size: 18px;
            color: rgba(51,51,51,1);
            flex-shrink: 0;
            margin-right: 10px;
        }
        .green-small {
            width: 100%;
            font-size:24px;
            padding: 14px;
            min-width: 0;
        }
        .no-line {
            border-bottom: 1px solid rgba(222,222,222,1);;
            margin: 32px 0 24px;
        }
        .erweima {
            padding: 24px;
            background: #fff;
            img {
                width: 252px;
            }
            p {
                font-size: 18px;
                color:rgba(51,51,51,1);
                margin-top: 24px;
                text-align: center;
            }
        }
    }
    .tips {
        display: flex;
        margin-top: 16px;
        p {
            color: rgba(38,38,38,1);
            font-size: 14px;
        }
        img {
            width: 12px;
            height: 12px;
            display: inline-block;
            line-height: 12px;
            font-size: 12px;
            align-self: flex-start;
            margin-top: 3px;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }
}
@media (min-width: 576px) {
    .pur-success {
        .thank-you {
            margin-bottom: 40px;
            text-align: center;
            h1 {
                font-size: 72px;
                margin-bottom: 0;
            }
            p {
                font-size: 24px;
            }
        }
        .get-bitvpn {
            margin-top: 80px;
            h2 {
                font-size: 56px;
                margin-bottom: 40px;
            }
        }
        .order-details {
            font-size:24px;
            padding-top: 24px;
            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }
            .detail {
                margin-bottom: 64px;
            }
        }
        .save-your-receipt {
            padding: 32px 0 64px;
            .email-input-wrap {
                flex-wrap: nowrap;
                align-items: center;
                display: flex;
                .btn-wrap1 {
                    width: 150px;
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
            .detail {
                margin-top: 56px;
            }
            h2 {
                margin-bottom: 28px;
                font-size: 36px;
            }
            .receipts-p {
                font-size: 17px;
            }
            .no-line {
                margin: 56px 0 24px;
            }
            .email-label {
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 0;
            }
            .green-small {
                padding: 4px;
            }
        }
        .detail-line {
            margin-bottom: 16px;
        }
    }
}
</style>
