/**
 * config,js
 * author jack.wang@dingtone.me
 * change date 2019/6/24
 * 站点请求接口方法名管理
 */
// 统一方法名地址管理
export default {
    // 获取商品信息
    MAIN_GETPRODUCTS: '/getProducts',
    // 获取信用卡token
    MAIN_GETCLIENTTOKEN: '/getClientToken',
    // 信用卡购买，验证用户及得到框架数据 接口
    MAIN_BRAINTREEBUY: '/brainTreeBuy',
    // top币 提交订单
    MAIN_TOPBUY: '/topBuy',
    // top币购买
    MAIN_TOPORDER: '/topOrder',
    // bit币 提交订单
    MAIN_BTCBUY: '/btcBuy',
    // bit币购买
    MAIN_BITORDER: '/btcOrder',
    // 监测是否订单是否已经到账
    MAIN_PAYCHECK: '/payCheck',
    // paymentwall购买
    MAIN_PAYMENTWAYLL: '/executePaymentwallUrl',
    // 初始化paypal接口
    MAIN_CREATEPAYMENT: '/createPayment',
    // paypal支付接口
    MAIN_EXECUTEPAYMENT: '/executePayment',
    // 数字货比购买成功后，调用此接口，返回一些详情信息
    MAIN_DIGITALCURRENCYSUCCES: '/digitalCurrencySucces',
    // 往用户邮箱，发送兑换码
    MAIN_SENDREDEEMCODE: '/sendRedeemCode',

};
