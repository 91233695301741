<template>
    <ul class="bit-download">
        <li @mouseenter="overLi('IOS')" :class="[hover === 'IOS' ? 'hover' : '']">
            <div class="img-wrap">
                <img  src="@/assets/images/ios.png" alt="IOS" >
            </div>
            <div class="device-type">iOS</div>
            <button @click="downloadTarget('iOS');beforeGa('IOS')">Download</button>
        </li>
        <li @mouseenter="overLi('Android')" :class="[hover === 'Android' ? 'hover' : '']">
            <div class="img-wrap">
                <img class="out_img" src="@/assets/images/android.png" alt="Android">
                <img class="over_img" src="@/assets/images/download-android-hover.png" alt="Android">
            </div>
            <div class="device-type">Android</div>
            <button @click="showDownloadModal = true;">Download</button>
        </li>
        <li @mouseenter="overLi('MAC')" :class="[hover === 'MAC' ? 'hover' : '']">
            <div class="img-wrap">
                <img class="out_img" src="@/assets/images/Macos.png" alt="MAC">
                <img class="over_img" src="@/assets/images/download-Macos-hover.png" alt="MAC">
            </div>
            <div class="device-type">Mac OS</div>
            <button @click="downloadTarget('Mac');beforeGa('MAC')">Download</button>
        </li>
        <li @mouseenter="overLi('windows')" :class="[hover === 'windows' ? 'hover' : '']">
            <div class="img-wrap">
                <img class="out_img" src="@/assets/images/download-windows-normal.png" alt="windows">
                <img class="over_img" src="@/assets/images/download-windows-hover.png" alt="windows">
            </div>
            <div class="device-type">Windows</div>
            <button @click="downloadTarget('Windows');beforeGa('windows')">Download</button>
        </li>
        <bit-download-modal :category="category" :action="action"  v-show="showDownloadModal" @close="showDownloadModal = false"></bit-download-modal>
    </ul>
</template>

<script>
import { downloadMixin, gceMixin } from '@/mixins/';

export default {
    name: 'BitDownload',
    props: {
        category: {
            type: String,
            default: 'Home',
        },
        action: {
            type: String,
            default: 'click_bottom_downloadAndroid',
        },
        type: {
            type: String,
            default: 'Home',
        },
    },
    data() {
        return {
            hover: '',
        };
    },
    mixins: [downloadMixin, gceMixin],
    methods: {
        overLi(hover) {
            // clearTimeout(this.timeoutId);
            this.hover = hover;
        },
        beforeGa(action) {
            // this.hover = action;
            if (this.type === 'Home') {
                if (action === 'IOS') {
                    this.gce('Home', 'click_bottom_downloadiOS', '');
                } else if (action === 'MAC') {
                    this.gce('Home', 'click_bottom_downloadMac', '');
                } else if (action === 'windows') {
                    this.gce('Home', 'click_bottom_downloadWindows', '');
                }
            } else if (this.type === 'Success') {
                if (action === 'IOS') {
                    this.gce('Pricing', 'PurchaseSuccessPage_download_iOS', '');
                } else if (action === 'MAC') {
                    this.gce('Pricing', 'PurchaseSuccessPage_download_mac', '');
                } else if (action === 'windows') {
                    this.gce('Pricing', 'PurchaseSuccessPage_download_win', '');
                }
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-download {
    display: flex;
    justify-content: space-around;
    background-repeat: no-repeat;
    align-items: flex-start;
    height: 140px;
    button {
        display: none;
        margin-top: 10px;
        color: #fff;
        padding: 6px 10px;
        background: rgba(171,86,181,1);
        border-radius: 4px;
        font-size:12px;
        font-family:PingFangSC-Medium;
        font-weight:500;
        color:rgba(255,255,255,1);
        line-height:17px;
        cursor: pointer;
    }
    .img-wrap {
        height: 50px;
    }
    .over_img {
        display: none;
    }
    img {
        width: 30px;
    }
    li {
        height: 130px;
        width: 60px;
        padding: 10px 0;
        font-size: 14px;
    }
    .hover {
        background:rgba(255,255,255,1);
        box-shadow:0px 1px 4px 0px rgba(0,0,0,0.3);
        width: 100px;
        button {
            display: inline-block;
        }
        .over_img {
            display: inline-block;
        }
        .out_img {
            display: none;
        }
    }
}
@media (min-width: 576px) {
    .bit-download {
        justify-content: center;
        height: 200px;
        li {
            width: 150px;
            font-size: 17px;
            height: 180px;
            padding: 20px 0;
        }
        button {
            margin-top: 20px;
            padding: 6px 20px;
        }
        .hover {
            width: 150px;
        }
        img {
            width: 45px;
        }
        .img-wrap {
            height: 60px;
        }
    }
}
</style>
