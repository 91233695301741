<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <div class="title">
                {{title}}
            </div>
            <div class="contentList" v-for="(item, index) in contentList" :key="index">
                <div class="_title">
                    {{item.title}}
                </div>
                <div class="_content">
                    {{item.content}}
                </div>
                <div class="sub_content" v-for="(item1, index1) in item.contentdetail" :key="index1">
                    <div class="sub_title">
                        {{item1.subTitle}}
                    </div>
                    <div class="sub_sub" v-for="(item2, index2) in item1.details"  :key="index2" v-html="item2">

                    </div>
                </div>
            </div>
             <div class="btn-center">
                <bit-button size="medium" @click.stop="download('click_bottom_aboutvpn')">Get BitVPN</bit-button>
             </div>
        </div>
        
        <bit-footer></bit-footer>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';
export default {
    mixins: [downloadMixin],
    name: 'Faq',
    data() {
        return {
            title: 'About BitVPN',
            contentList: [{
                title: 'Who We Are?',
                content: `We established BitVPN in 2012 to protect online privacy and security through technology development and unstoppable innovation.
In the beginning, our intention was simple: embrace and improve cybersecurity with user-friendly products.`,
                contentdetail: [{
                    subTitle: 'Our Mission',
                    details: [`
To provide uncompromising professional solutions for cybersecurity and privacy 
protections so that unauthorized individuals don’t view your online activities. In pursuit of that goal, we constantly search for all possible solutions to break Internet limitations.`,
                    `The BitVPN App (link to http://bitvpn.net/vpn-for-ios) is one of those solutions.`]
                },
                {
                    subTitle: 'Our Essence',
                    details: [`
                        To be one of the most trusted providers of privacy and security services in the world.
                    `]
                },
                {
                    subTitle: 'Our Promise',
                    details: [`
                        To protect our users’ data from theft and hack without logging Internet activity.
                    `]
                },
                ]
            },
            {
                title: 'What We Offer',
                content: `BitVPN – The World’s FIRST Decentralized VPN on Blockchain`,
                contentdetail: [{
                    subTitle: 'Focus on Technology Innovation',
                    details: [`
Our tech gene derives from Silicon Valley. That influence keeps us constantly striving for technology innovation.
`, `We aim to provide users with a quick and safe connection to the Internet. BitVPN depends on blockchain technology to protect users’ data from theft or hack.
                    `]
                },
                {
                    subTitle: 'Customer-Oriented Service',
                    details: [`
                        To date, over 1 million users are keeping their data fully private with BitVPN and we’ve gained a customer satisfaction rate as high as 99%. On one hand, we provide reliable solutions to meet customers’ needs. On the other hand, we promise users’ data is 100% safe with us. We don’t log your activities when you connect to the Internet and we guarantee your privacy wherever your online travels take you.
                    `]
                },
                ]
            },
            {
                title: 'The Benefits of Decentralized VPN',
                content: '',
                contentdetail: [{
                    subTitle: 'Distributed',
                    details: [`
Decentralized VPN data is distributed among a decentralized network. No single entity can gather your data and personal information.
                    `]
                },
                {
                    subTitle: 'Log-Proof',
                    details: [`
                        By its very nature, decentralized VPN cannot access, track, or store your online activities.  Because a decentralized VPN is log-proof, your privacy is protected.
                    `]
                },
                {
                    subTitle: 'Secure',
                    details: [`
                        Decentralized VPN encrypts all your data and makes scrambling impossible.
                    `]
                },
                {
                    subTitle: 'Unstoppable',
                    details: [`
                        Decentralized VPN is constructed of millions of nodes so the network runs infinitely and lasts forever.
                    `]
                },
                ]
            },
            ],
        };
    },
    methods: {
        down() {
           
        },
    },
};
</script>

<style lang="stylus" scoped>
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 20px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
           // line-height 25px
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}

</style>
