<template>
    <div class="promise">
        <p><img src="@/assets/images/iocn-tip.png" alt="money-back">You're 100% covered by our 30-day money-back guarantee </p>
        <p><img style="width: 12px;margin-right: 12px;" src="@/assets/images/icon-wr.png" alt="securely">Please follow the instructions above to securely complete your check out</p>
    </div>
</template>

<script>
export default {
    name: 'PricingMoneyBack',
};
</script>
