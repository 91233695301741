<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>

export default {
    data() {
        return {
            vConsole: '',
        };
    },
    mounted() {
        // loadScript('/public/vconsole.min.js').then(() => {
        // if (process.env.NODE_ENV === 'development') {
        //     const VConsole = require('vconsole');
        //     this.vConsole = new VConsole();
        // }
        // });
    },
};
</script>


<style lang="stylus">
.bit-tooltip {
    display: block !important;
    z-index: 10000;
    position: absolute;
    top: 100px;
    left: 100px;
    max-width: 500px;
    margin-right: 54px;
    .tooltip-inner {
        background: #000;
        color: #fff;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }
    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #000;
        bottom: -10px;
        left: 50px;
    }
}
</style>
