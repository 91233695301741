<template>
    <header class="bit-header " :class="['type-' + headerType, showMenu ? 'show-menu' : '', isPayPage ? 'isPayPage' : '']">
        <div class="bit-header-container">
            <div class="row">
                <div style="padding-left: 26px">
                    <div class="left">
                        <img v-show="!showMenu" @click="$router.push('/');gce('', 'click_BitVPN')" src="@/assets/images/logo.png" alt="logo" class="logo-img">
                        <img v-show="showMenu" @click="$router.push('/');gce('', 'click_BitVPN')" src="@/assets/images/bitvpn_white.png" alt="logo" class="logo-img">
                        <nav class="header-content"  :class="[showMenu ? '' : 'hide-menu']" v-if="!isPayPage">
                            <ul >
                                <!-- 暂时只有PC端,移动端后期后 -->
                                <li v-if="mobile == -1">
                                    <a class="bitm triangle" href="javascript:void(0)">What is VPN

                                        <div class="menu_list">
                                            <ul v-for="(item, index) in whatVpnList" :key="index" class="menu_list_ul">
                                                <p class="title">
                                                    {{item.title}}
                                                </p>
                                                <div class="content" v-for="(item1, index1) in item.cotent" :key="index1">
                                                    <router-link v-if="item1.split('~')[1] == 0 " :to="item1.split('~')[2]">{{item1.split('~')[0]}}</router-link>
                                                    <a target="_blank" v-if="item1.split('~')[1] == 1 " :href="item1.split('~')[2]">
                                                       {{item1.split('~')[0]}}
                                                    </a>
                                                    <a  v-if="item1.split('~')[1] == 2 " :href="item1.split('~')[2]">
                                                       {{item1.split('~')[0]}}
                                                    </a>
                                                </div>
                                            </ul>
                                        </div>

                                    </a>
                                </li>
                                <li :class="[(path === '/vpn-for-ios' || path === '/vpn-for-android' || path === '/vpn-for-mac' || path === '/vpn-for-windows') ? 'active' : '']">
                                    <router-link class="bitm" :to="productUrl" @click.native="gce('Home', 'click_Navigationbar_products')">
                                        Products
                                    </router-link>
                                </li>
                                <!-- <li>
                                    <router-link class="bitm" to="/vpn-price" @click.native="gce('Home', 'click_Navigationbar_pricing')">
                                        Pricing
                                    </router-link>
                                </li> -->
                                <li>
                                    <a class="bitm" href="https://www.bitvpn.net/blog/">Blog</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div style="padding-right: 26px;" >
                    <bit-button class="header-download" size="small" @click="download('click_Navigationbar_download')" :class="[showDownloadBtn ? 'showDownload':'']">Download</bit-button>
                    <span v-show="!showMenu && !showDownloadBtn && !isPayPage" class="header-open-icon" @click="openMenu()"></span>
                    <span v-show="showMenu && !isPayPage" class="header-close-icon" @click="closeMenu()">×</span>
                </div>
            </div>
        </div>
        <bit-download-modal v-show="showDownloadModal" @close="showDownloadModal = false"></bit-download-modal>
    </header>
</template>

<script>
import { downloadMixin, gceMixin } from '@/mixins/';
import { getMobileOperatingSystem } from '@/utils/common';

function getHeaderType() {
    return window.pageYOffset > 70 ? 'default' : 'transparent';
}
export default {
    components: {
    },
    props: {
        isPayPage: {
            type: Boolean,
            default: false,
        },
    },
    name: 'BitHeader',
    mixins: [downloadMixin, gceMixin],
    mounted() {
        this.mobile = window.innerWidth < 576 ? 1 : -1;
        this.path = this.$route.path;
        this.prevScroll = -1;
        if (this.$route.path === '/') {
            window.addEventListener('scroll', this.calHeaderType);
            this.$nextTick(this.calHeaderType);
        } else {
            this.headerType = 'default';
        }
        if (this.$route.path.match(/^\/vpn-for-/)) {
            // 添加滚动事件，如果网上滑动就显示下载按钮
            window.addEventListener('scroll', this.calSHowDownload);
        }
        const sys = getMobileOperatingSystem();
        if (sys === 'Windows') {
            this.productUrl = '/vpn-for-windows';
        } else if (sys === 'Mac') {
            this.productUrl = '/vpn-for-mac';
        } else if (sys === 'Android') {
            this.productUrl = '/vpn-for-android';
        }
    },
    data() {
        return {
            headerType: 'transparent',
            showMenu: false,
            showDownloadBtn: false,
            showDownloadModal: false,
            productUrl: '/vpn-for-ios',
            path: '',
            mobile: -1,
            whatVpnList: [
                {
                    title: 'Learn More',
                    cotent: ['What is VPN~0~/what-is-a-vpn', 'What Is My IP~0~/what-is-my-ip'],
                },
                {
                    title: 'Follow Us',
                    cotent: ['Telegram~1~https://t.me/bitvpnapp', 'Facebook~1~https://www.facebook.com/bitvpnapp/', 'Instagram~1~https://www.instagram.com/bitvpn_app/', 'Twitter~1~https://twitter.com/Bitvpn1'],
                },
                {
                    title: 'About Us',
                    cotent: ['Why choose BitVPN~0~/why-choose-bitvpn', 'About BitVPN~0~/about-bitvpn', 'Privacy Policy~0~/privacy-policy', 'Terms of Service~0~/terms', 'Contact us~2~mailto:contact@bitvpn.net', 'FAQ~0~/faq'],
                },
            ]
        };
    },
    methods: {
        openMenu() {
            this.showMenu = true;
        },
        closeMenu() {
            this.showMenu = false;
        },
        calHeaderType() {
            this.headerType = getHeaderType();
        },
        calSHowDownload() {
            if (window.pageYOffset > 0 && window.pageYOffset < this.prevScrollY) {
                this.showDownloadBtn = true;
            } else {
                this.showDownloadBtn = false;
            }
            this.prevScrollY = window.pageYOffset;
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.calHeaderType);
        window.removeEventListener('scroll', this.calSHowDownload);
    },
};
</script>

<style lang="stylus" scoped>
.triangle {
    position : relative;
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left:7px solid transparent;
        border-right:7px solid transparent;
        border-bottom:7px solid #262626;
        right: -20px;
        top:4px;
    }
    &:hover:before {
        content: '';
        position: absolute;
        width: 0px;
        height: 0px;
        border-left:7px solid transparent;
        border-right:7px solid transparent;
        border-top:7px solid #262626;
        border-bottom:7px solid transparent;
        right: -20px;
        top:6px;
    }
    .menu_list{
        width 100%
        position fixed
        height 0
        top 70px
        left 0px
        background:rgba(255,255,255,0.96);
        transition-property height ;
        transition-duration 500ms;
        z-index 111;
        display:flex;
        justify-content center
        overflow hidden
        .menu_list_ul {
            width 218px
            display block
            .title {
                font-size:24px;
                font-weight:500;
                color:rgba(38,38,38,1);
                line-height:33px;
                margin-top 25px;

            }
            .content {
                width 100%
                font-size:17px;
                margin-top 5px
            }
        }
    }
    &:hover > .menu_list {
        height 275px
    }
}
.triangle:hover > .menu_list {
}
</style>

<style lang="stylus" scoped>
.bit-header {
    height: 70px;
    width: 100%;
    .bit-header-container {
        
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        
    }
    .row {
        height: 70px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 1140px;
    }
    .left, ul {
        display: flex;
        align-items: center;
    }
    .logo-img, li {
        margin-right: 10px;
    }
    li {
        padding: 0 16px;
    }

    .logo-img {
        width: 100px;
        cursor: pointer;
    }
    &.type-default {
        .bit-header-container {
            background: #fff;
        }
    }
    &.type-transparent {
        .bit-header-container {
            background: transparent;
        }
    }
    a {
        color: #262626;
        font-size: 14px;
        padding-bottom: 5px;
        display: inline-block;
    }
    a:hover {
        color: #9327A1;
    }
    .header-download {
        display: none;
    }
    &.isPayPage {
        .header-download {
            display: inline-block;
        }
    }
    .showDownload {
        display: inline-block;
    }
    .header-open-icon {
        display: inline-block;
        width: 24px;
        height: 17px;
        border-top: 3px solid #454545;
        border-bottom: 3px solid #454545;
        position: relative;
        &:after {
            content: ' ';
            position: absolute;
            height: 3px;
            display: inline-block;
            width: 100%;
            top: 4px;
            background: #454545;
        }
    }
    .header-close-icon {
        display: inline-block;
        width: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #fff;
    }
    .hide-menu {
        display: none;
    }
    &.show-menu {
        background: #101423;
        position: fixed;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        .container {
            background: #101423;
        }
        .header-content {
            position: absolute;
            width: 100%;
            top: 210px;
            left: 0;
            ul {
                display: block;
            }
            li {
                margin-bottom: 40px;
                text-align: center;
            }
            a {
                color: #fff;
                font-size: 32px;
            }
        }
    }
}
@media (min-width: 576px) {
    .bit-header {
        // width: 100%;
        // .container {
        //     // position: static;
        //     width: 100%;
        // }
        .logo-img, li {
            margin-right: 32px;
        }
        .logo-img {
            margin-right: 84px;
        }
        .header-close-icon, .header-open-icon {
            display: none;
        }
        .header-download {
            display: inline-block;
        }
        .hide-menu {
            display: block;
        }
        .active {
            border-bottom: 2px solid rgba(147,39,161,1);
        }
    }
}
.isPayPage {
}
</style>
