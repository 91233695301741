/* eslint-disable no-param-reassign */
import * as API from '@/fetch/api';

const app = {
    state: {
        userId: '',
        email: '',
        btcAddress: '',
        topAddress: '',
        sourceType: 1,
        userChannelType: 0,
        orderId: '',
        type: '', // btc支付101, top支付102
    },
    mutations: {

        SET_USERID: (state, value) => {
            state.userId = value;
            localStorage.setItem('userId', value);
        },
        SET_EMAIL: (state, value) => {
            state.email = value;
            localStorage.setItem('email', value);
        },
        SET_BTCADDRESS: (state, value) => {
            state.btcAddress = value;
        },
        SET_TOPADDRESS: (state, value) => {
            state.topAddress = value;
        },
        SET_ORDERID: (state, value) => {
            state.orderId = value;
            localStorage.setItem('orderId', value);
        },
        SET_TYPE: (state, value) => {
            state.type = value;
            localStorage.setItem('type', value);
        },
    },
    actions: {

        upgradeorderid({ commit }, order) {
            commit('SET_ORDERID', order);
        },
        upgradeemail({ commit }, email) {
            commit('SET_EMAIL', email);
        },
        upgradebtcaddress({ commit }, btcAddress) {
            commit('SET_BTCADDRESS', btcAddress);
        },
        upgradetopaddress({ commit }, topAddress) {
            commit('SET_TOPADDRESS', topAddress);
        },
        upgradeuserid({ commit }, userId) {
            commit('SET_USERID', userId);
        },
        upgradetype({ commit }, type) {
            commit('SET_TYPE', type);
        },
    },

};

export default app;
