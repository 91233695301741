<template>
  <div class="bit-paypal">
      <pricing-terms type="paypal"></pricing-terms>
      <div id="paypal_btn" ref="refName">
          <div class="maskshow" v-show="maskshow" @click="toemailinput()"></div>
      </div>
      <pricing-money-back></pricing-money-back>
    <bit-popup-email v-if="showpupemail" @closePop="hidePop" type='paypal'> </bit-popup-email>
  </div>
</template>

<script>
import { loadScript, loadRecaptcha } from '@/utils/common';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'TgPaypal',
    data() {
        return {
            sandbox: { // sandbox
                createPaymentURL: 'http://52.9.8.211:8113/bitproxy/webBuy/createPayment',
                exePaymentURL: 'http://52.9.8.211:8113/bitproxy/webBuy/executePayment',
            },
            production: { // production
                createPaymentURL: 'https://d2tv7wzvhihv37.cloudfront.net/bitproxy/webBuy/createPayment',
                exePaymentURL: 'https://d2tv7wzvhihv37.cloudfront.net/bitproxy/webBuy/executePayment',
            },
            maskshow: true, // 用户遮挡paypal按钮的开关
            showpupemail: false,
        };
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    mounted() {
        this.loadScript();
        if (this.userInfo.userId === '' || this.userInfo.userId === undefined) {
            this.maskshow = true;
        } else {
            this.maskshow = false;
        }
    },
    methods: {
        ...mapActions([
            'upgradetype',
        ]),
        toemailinput() {
            if (this.userInfo.userId === '' || this.userInfo.userId === undefined) {
                this.showpupemail = true;
            } else {
                this.maskshow = false;
            }
        },
        hidePop() {
            if (this.userInfo.email === '' || this.userInfo.email === undefined) {
                this.maskshow = true;
            } else {
                this.maskshow = false;
            }
            this.showpupemail = false;
        },
        loadScript() {
            if (window.paypal) {
                this.$nextTick(() => {
                    this.renderPaypal();
                });
                return;
            }
            loadScript('https://www.paypalobjects.com/api/checkout.js').then(() => {
                this.$nextTick(() => {
                    this.renderPaypal();
                });
            });
        },
        renderPaypal() {
            if (!window.paypal) {
                return;
            }
            const that = this;
            window.paypal.Button.render({
                env: (window.location.hostname.indexOf('test') > -1 || window.location.hostname.indexOf('localhost') > -1) ? 'sandbox' : 'production', // sandbox | production
                commit: true,
                style: {
                    size: 'responsive',
                    color: 'gold',
                    shape: 'pill',
                    label: 'checkout',
                    // tagline: 'false'
                },
                locale: 'en_US',
                client: {
                    sandbox: 'ATwUHJnckuwoOJgM9ricgRg-ks1SZHHAvJ4vhFHx2M-S36otNub6ahhHz6F8RGNxHuChwrGAe8YdobJm',
                    production: 'AeVfqRCsJaTzyu9qqe35fkBca_3478_QGgIvwd-8xcth6d7Yt_efDvTG5lak',
                },
                payment: (data, actions) => {
                    // debugger;
                    let createPaymentURL = '';
                    if (window.location.hostname.indexOf('bitvpn.net') > -1) {
                        createPaymentURL = `${that.production.createPaymentURL}?productId=${that.products.productId}&sourceType=1`;
                    } else {
                        createPaymentURL = `${that.sandbox.createPaymentURL}?productId=${that.products.productId}&sourceType=1`;
                    }
                    return actions.request.post(createPaymentURL)
                        .then((res) => {
                            if (res.result === 1) {
                                that.$gce('Pricing', 'createPaypalPaymentSuccess', `${that.products.productId}`);
                                return res.paymentId;
                            }
                            if (res.result !== 1) {
                                that.$gce('Pricing', 'processPaymentFailed', `${that.products.productId}`);
                                console.log('Failed to initialise paypal payment. Please try again.', 'error');
                            }
                            return -1;
                        });
                },
                onAuthorize: (data, actions) => {
                    loadRecaptcha().then((robotToken) => {
                        let params = {};
                        let md5 = require('md5');

                        let language = (navigator.browserLanguage || navigator.language).toLowerCase();
                        language = language.includes('ar') ? 'ar' : language;
                        if (that.userInfo.userId === '' || that.userInfo.userId === undefined) {
                            params = {
                                paymentId: data.paymentID,
                                payerId: data.payerID,
                                productId: that.products.productId,
                                sourceType: 1,
                                email: that.userInfo.email,
                                userChannelType: 0,
                                'g-recaptcha-response': robotToken,
                                googles: md5('1qaz_' + new Date().getTimezoneOffset() / 60 + '_' + language + '_7ujm'),

                            };
                        } else {
                            params = {
                                paymentId: data.paymentID,
                                payerId: data.payerID,
                                productId: that.products.productId,
                                sourceType: 1,
                                userId: that.userInfo.userId,
                                userChannelType: 0,
                                'g-recaptcha-response': robotToken,
                                googles: md5('1qaz_' + new Date().getTimezoneOffset() / 60 + '_' + language + '_7ujm'),

                            };
                        }
                        let exePaymentURL = '';
                        if (window.location.hostname.indexOf('bitvpn.net') > -1) {
                            exePaymentURL = `${that.production.exePaymentURL}`;
                        } else {
                            exePaymentURL = `${that.sandbox.exePaymentURL}`;
                        }
                        return actions.request.post(exePaymentURL, params).then((response) => {
                            if (response.result === 1) {
                                that.$gce('Pricing', 'executePaypalPaymentSuccess', `${that.products.productId}`);
                                localStorage.setItem('payMethod', response.payMethod);
                                localStorage.setItem('orderTime', response.orderTime);
                                localStorage.setItem('endTime', response.endTime);
                                localStorage.setItem('amount', response.price);
                                localStorage.setItem('productName', response.productName);
                                localStorage.setItem('orderId', response.orderId);
                                if (response.redeemCode !== undefined) {
                                    localStorage.setItem('redeemCode', response.redeemCode);
                                }
                                if (response.oldEndTime !== undefined) {
                                    localStorage.setItem('oldEndTime', response.oldEndTime);
                                }
                                that.upgradetype(7);
                                that.$router.push({name: 'vpn-purchase-thank-you'});
                                return 1;
                            }
                            that.$gce('Pricing', 'executePaypalPaymentFailed', `${that.products.productId} -- ${response.code} -- ${response.reason}`);
                            return -1;
                        }).catch((error) => {
                            console.log('paypal catch err :', error.response);
                            that.$gce('Pricing', 'executePaypalPaymentFailed', `${that.products.productId} -- ${error.response}`);
                        });
                    });
                },
            }, this.$refs.refName);
        },
    },
};
</script>

<style lang="stylus" scoped>
.bit-paypal {
    #paypal_btn{
        position: relative;
        .maskshow{
             position: absolute;
             z-index: 10000;
             height: 80px;
             width: 100%;
             top: 0;
             cursor: pointer;
        }
    }
}
</style>
