<template>
    <footer class="bit-footer " >
        <div class="container">
            <div class="big-row row">
                <div class="col-lg-2 col-md-12">
                    <img src="@/assets/images/logo_gray.png" alt="logo" class="logo-img">
                </div>
                <div class="col-lg-10 col-md-12">
                    <div class="container">
                        <ul class="footer-line row">
                            <li class="col-12 col-xl-2 col-md-4">
                                <p class="footer-title">Products</p>
                                <ul>
                                    <li>
                                        <router-link to="/vpn-for-ios" @click.native="gce('Home', 'click_footer_Download_iOS')">
                                            VPN for iOS
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/vpn-for-android" @click.native="gce('Home', 'click_footer_Download_Android')">
                                            VPN for Android
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/vpn-for-windows" @click.native="gce('Home', 'click_footer_Download_win')">
                                            VPN for Windows
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/vpn-for-mac" @click.native="gce('Home', 'click_footer_Download_mac')">
                                            VPN for macOS
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="col-12 col-xl-2 col-md-4"  >
                                <p class="footer-title">Learn More</p>
                                <ul>
                                    <li>
                                        <router-link to="/what-is-a-vpn" @click.native="gce('Home', 'click_footer_Download_iOS')">
                                           What is VPN
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/what-is-my-ip" @click.native="gce('Home', 'click_footer_Download_Android')">
                                           What Is My IP
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="col-12 col-xl-2 col-md-4">
                                <p class="footer-title">Follow us</p>
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://t.me/bitvpnapp" @click="gce('Home', 'click_footer_telegram')">
                                            Telegram
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/bitvpnapp/" @click="gce('Home', 'click_footer_Facebook')">
                                            Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/bitvpn_app/" @click="gce('Home', 'click_footer_Instagram')">
                                            Instagram
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/Bitvpn1" @click="gce('Home', 'click_footer_Twitter')">
                                            Twitter
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="col-12 col-xl-2 col-md-4">
                                <p class="footer-title">Partner</p>
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://www.topnetwork.org/" @click="gce('Home', 'click_footer_TOP')">
                                            Top Network
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.hiwallet.org" @click="gce('Home', 'click_footer_TOP')">
                                            HiWallet
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="col-12 col-xl-3 col-md-4">
                                <p class="footer-title">About us</p>
                                <ul>
                                     <li >
                                        <router-link to="/why-choose-bitvpn" @click.native="gce('Home', 'click_footer_PrivacyPolicy')">
                                           Why choose BitVPN
                                        </router-link>
                                    </li>
                                    <li  >
                                        <router-link to="/about-bitvpn" @click.native="gce('Home', 'click_footer_Term')">
                                            About BitVPN
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy" @click.native="gce('Home', 'click_footer_PrivacyPolicy')">
                                            Privacy Policy
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/terms" @click.native="gce('Home', 'click_footer_Term')">
                                            Terms of Service
                                        </router-link>
                                    </li>
                                    <li>
                                        <a href="mailto:contact@bitvpn.net" @click="gce('Home', 'click_footer_contactus')">
                                            Contact us
                                        </a>
                                    </li>
                                    <li>
                                        <router-link to="/faq" @click.native="gce('Home', 'click_footer_FAQ')">
                                            FAQ
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="copyright">
                        Copyright ©2019 BitVPN Inc.
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { gceMixin } from '@/mixins/';

export default {
    components: {
    },
    mixins: [gceMixin],
    name: 'BitFooter',
    data() {
        return {
            mobile: -1,
        };
    },
    mounted() {
        this.mobile = window.innerWidth < 576 ? 1 : -1;
    }
}
</script>

<style lang="stylus" scoped>
.bit-footer {
    background: #101423;
    padding: 40px 0 30px 0;
    color: #999999;
    .big-row {
        justify-content: space-between;
    }
    .logo-img {
        width: 150px;
        margin-bottom: 56px;
        margin-left: 40px;
    }
    .footer-line {
        padding-left: 24px;
        >li {
            margin-bottom: 46px;
            p {
                margin-bottom: 30px
            }
            li {
                margin-bottom: 10px;
            }
        }
    }
    .footer-title {
        font-size: 17px;
        color: rgba(255,255,255,1);
    }
    .copyright {
        margin-top: 27px;
        font-size: 17px;
        text-align: center;
    }
    a {
        color: #999999;
        font-size: 14px;
    }
    a:hover {
        color: #9327A1;
    }
}
@media (min-width: 576px) {
    .bit-footer {
        padding-top: 56px;
        padding-left: 0;
        .logo-img {
            margin-left: 0;
        }
        .footer-line {
            padding-left: 0;
            >li {
                margin-bottom: 56px;
                p {
                    margin-bottom: 32px
                }
                li {
                    margin-bottom: 13px;
                }
            }
        }
        .copyright {
            margin-top: 58px;
            text-align: left;
        }
    }
}
</style>
