<template>
    <div class="products">
        <bit-header header-type="transparent"></bit-header>
        <div class="products-header">
            <div class="container">
                <div class="product-top-tab row">
                    <span
                        class="icon-ios"
                        @click="$router.push('/vpn-for-ios')" :class="[selectedProductName === 'vpn-for-ios' ? 'active' : '']"
                    ></span>
                    <span
                        class="icon-android"
                        @click="$router.push('/vpn-for-android')" :class="[selectedProductName === 'vpn-for-android' ? 'active' : '']"
                    ></span>
                    <span
                        class="icon-mac"
                        @click="$router.push('/vpn-for-mac')" :class="[selectedProductName === 'vpn-for-mac' ? 'active' : '']"
                    ></span>
                    <span
                        class="icon-windows"
                        @click="$router.push('/vpn-for-windows')" :class="[selectedProductName === 'vpn-for-windows' ? 'active' : '']"
                    ></span>
                </div>

            </div>
            <div class="header-content container" :class="[selectedProductName]">
                <h1 class="bitm">{{selectedProduct.h1}}</h1>
                <p class="bitm">{{selectedProduct.des}}</p>
                <ul>
                    <li v-for="item in selectedProduct.headerList" :key="item">
                        <div><img src="@/assets/images/icon_hook.png" alt="icon_hook"></div>
                        <span>{{item}}</span>
                    </li>
                </ul>
                <div class="android-btn">
                    <img  class="google_play" @click="gceGoogle('Download','click_Androidpage_top_GetBitVPN','google');downloadTarget('AndroidG');" v-if="selectedProductName === 'vpn-for-android'" src="@/assets/images/google_play.png" alt="google_play">
                </div>
            </div>
        </div>
        <div class="des container">
            <bit-product-item
                v-for="(item, index) in selectedProduct.contentList"
                :key="index"
                :index="index"
                :item="item"
            >
            </bit-product-item>
        </div>
        <div class="why-vpn-network ">
            <div class="container">
                <h2 class="bitm">
                    How We Decentralize Our VPN Network
                </h2>
                <p>
                    The decentralized P2P network of BitVPN is empowered by <a href="https://www.topnetwork.org/">TOP Network</a>'s high-performance public blockchain platform.
                </p>
                <div class="btn-wrap">
                    <bit-button size="medium" v-if="selectedProductName === 'vpn-for-android'" @click="showDownloadModal = true">Get BitVPN Now</bit-button>
                    <bit-button size="medium" v-if="selectedProductName === 'vpn-for-ios'" @click="down();beforeGce('bottom');">Get BitVPN Now</bit-button>
                    <bit-button size="medium" v-if="selectedProductName === 'vpn-for-mac'" @click="down();beforeGce('bottom');">Get BitVPN Now</bit-button>
                    <bit-button size="medium" v-if="selectedProductName === 'vpn-for-windows'" @click="down();beforeGce('bottom');">Get BitVPN Now</bit-button>
                </div>
            </div>
        </div>
        <bit-footer></bit-footer>
        <bit-download-modal v-if="showDownloadModal" @close="showDownloadModal = false" category="Download" action="click_Androidpage_bottom_GetBitVPN"></bit-download-modal>
    </div>
</template>

<script>
import { downloadMixin } from '@/mixins/';
import { gaClose, goBack } from '@/utils/common';

const dess = {
    'vpn-for-ios': {
        h1: 'BitVPN for iOS',
        des: 'The best privacy VPN for iPhone and iPad',
        btnText: 'Get BitVPN for IOS',
        headerList: [
            'Private access to streaming, games, and more',
            'Intuitive one-tap VPN connection',
            'No advertisements, no interruptions',
            '30-day money-back guarantee',
        ],
        contentList: [{
            type: 'ios',
            title: 'Simple & Intuitive VPN',
            list: [
                'With just one tap, BitVPN automatically connects you to the best VPN server available.',
                'Private access to your favorite social media sites, streaming, games, and apps -- anytime, anywhere.',
            ],
        }, {
            type: 'ios',
            title: 'No More Geo-Restrictions',
            list: [
                'Globally distributed VPN nodes ensure you a seamless VPN connection.',
                'Get 24/7 Live Support from the BitVPN team. Whenever you need assistance, we are here to help.',
            ],
        }, {
            type: 'ios',
            title: 'VPN Privacy Protection',
            list: [
                'Maximize your internet privacy with sophisticated data scrambling.',
                'Zero personal information involved when using BitVPN.',
            ],
        }],
    },
    'vpn-for-android': {
        h1: 'BitVPN for Android',
        des: 'The most secure VPN app for Android',
        btnText: '',
        headerList: [
            'Secure connections for work, entertainment, and more',
            'Unlock secure VPN access with just one tap',
            'Seamless VPN connections without ads',
            '30-day money-back guarantee',
        ],
        contentList: [{
            type: 'android',
            title: 'Secure VPN on the Go',
            list: [
                'Protection from internet censorship, ISP surveillance, ad trackers, and any suspicious monitoring.',
                'Totally free trials for 7 days with additional 30-day money-back guarantee. No hassles, no risks.',
            ],
        }, {
            type: 'android',
            title: 'No Borders, No Limitations',
            list: [
                'With global VPN servers, you can bypass any geo-restrictions and securely access your favorite content wherever you are.',
                'Get secure VPN access 24/7 under all circumstances -- airport, cafe, workplace, school, and more.',
            ],
        }, {
            type: 'android',
            title: 'Next-Level Security',
            list: [
                'The decentralized architecture of BitVPN prevents anyone (including BitVPN) from doing any kind of logging.',
                'Layered encryption mechanism provides the best security for your data.',
            ],
        }],
    },
    'vpn-for-windows': {
        h1: 'BitVPN for Windows',
        des: 'The best VPN for Windows 10, 8, and 7',
        btnText: 'Get BitVPN for Windows',
        headerList: [
            'Unlimited access for websites, apps, and content',
            'Multi-layered data encryption and scrambling',
            'Private, secure and anonymous browsing',
            '30-day money-back guarantee',
        ],
        contentList: [{
            type: 'windows',
            title: 'Truly Decentralized VPN',
            list: [
                'Built upon a decentralized, open-communication TOP-network as well as a public blockchain platform.',
                'The decentralized structure enables BitVPN to become truly anonymous, private, and secure.',
            ],
        }, {
            type: 'windows',
            title: 'The Unstoppable Nature',
            list: [
                'Single point of failure or attack is no longer a risk to the entire network, thanks to our decentralized P2P mesh network.',
                'Each and every step of data processing is encrypted, scrambled, and encrypted again.',
            ],
        }, {
            type: 'windows',
            title: 'Join, Share & Earn',
            list: [
                "BitVPN's decentralized architecture enables everyone to become a node and spread the value of the connection.",
                'Share your spare bandwidth with the community. Let your computers work for you and earn while you sleep.',
            ],
        }],
    },
    'vpn-for-mac': {
        h1: 'BitVPN for macOS',
        des: 'The most anonymous VPN for MacBook',
        btnText: 'Get BitVPN for macOS',
        headerList: [
            'Anonymous VPN browsing for all needs',
            'Stay hidden and invisible from snoopers',
            'Powerful stratified data encryption',
            '30-day money-back guarantee',
        ],
        contentList: [{
            type: 'mac',
            title: 'Ultimate Anonymity',
            list: [
                'Built with multiple advanced encryption techniques, BitVPN assures you an anonymous VPN experience.',
                'With full-stack decentralized architecture, BitVPN provides an everlasting VPN network.',
            ],
        }, {
            type: 'mac',
            title: '24/7 Data Protection',
            list: [
                'With a built-in launch-on-startup feature, BitVPN keeps your online browsing entirely anonymous when using MacBook.',
                'With thousands of VPN nodes and servers across the globe, BitVPN enables you to switch and connect to different locations at will.',
            ],
        }, {
            type: 'mac',
            title: 'Grab and Go in 2 Steps',
            list: [
                "Get BitVPN for macOS simply by clicking the 'GET' button in the App Store. No daunting installations.",
                'Unlock all restricted content and regain all your media access with just one click. Simple and intuitive.',
            ],
        }],
    },
};
const gaMap = {
    'vpn-for-windows': 'Windows-WindowsDownloadpage-WindowsDownloadpage-click_Windowspage_top_GetBitVPN-click_Windowspage_bottom_GetBitVPN',
    'vpn-for-ios': 'IOS-IOSDownloadpage-IOSDownloadpage-click_IOSpage_top_GetBitVPN-click_IOSpage_bottom_GetBitVPN',
    'vpn-for-android': 'Android-AndroidDownloadpage-AndroidDownloadpage-click_Androidpage_top_GetBitVPN-click_Androidpage_bottom_GetBitVPN',
    'vpn-for-mac': 'mac-macDownloadpage-macDownloadpage-click_macpage_top_GetBitVPN-click_macpage_bottom_GetBitVPN',
};
export default {
    components: {
    },
    name: 'Products',
    mixins: [downloadMixin],
    mounted() {
        this.selectedProductName = this.$route.path.replace('/', '');
        const listArr = gaMap[this.selectedProductName].split('-');
        this.$gce('Download', 'Downloadpage_show', listArr[0]);
        gaClose(this, 'Download', `close_${listArr[2]}`, '');
        goBack(this, 'Download', `back_${listArr[1]}`, '');
    },
    data() {
        return {
            selectedProductName: 'vpn-for-ios',
        };
    },
    computed: {
        selectedProduct() {
            return dess[this.selectedProductName];
        },
    },
    methods: {
        down() {
            if (this.selectedProductName === 'vpn-for-windows') {
                this.downloadTarget('Windows');
            } else if (this.selectedProductName === 'vpn-for-ios') {
                this.downloadTarget('iOS');
            } else if (this.selectedProductName === 'vpn-for-android') {
                this.downloadTarget('AndroidApk');
            } else if (this.selectedProductName === 'vpn-for-mac') {
                this.downloadTarget('Mac');
            }
        },
        beforeGce(action, adro) {
            const listArr = gaMap[this.selectedProductName].split('-');
            const num = action === 'top' ? 3 : 4;
            if (adro) {
                this.$gce('Download', `${listArr[num]}`, adro);
            } else {
                this.$gce('Download', `${listArr[num]}`, 'success');
            }
        },
        gceGoogle(g, a, l) {
            this.$gce(g, a, l);
        },
        gceApk(g, a, l) {
            this.$gce(g, a, l);
        },
    },
};
</script>

<style lang="stylus" scoped>
.products {
    .des {
        margin-top: 24px;
    }
    .products-header {
        background: #101423;
        .product-top-tab {
            justify-content: space-around;
            padding: 16px 0 40px
            span {
                cursor: pointer;
                display: inline-block;
                background-position: center 20px;
                background-repeat: no-repeat;
                width: 60px;
                height: 60px;
                margin: 0;
                background-size: 40%;
                &.active {
                    border-bottom: 2px solid rgba(147,39,161,1);
                }
            }
            .icon-ios {
                background-image: url('../../assets/images/ios-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/ios-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/ios-active@2x.png');
                }
            }
            .icon-android {
                background-image: url('../../assets/images/android-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/android-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/android-active@2x.png');
                }
            }
            .icon-mac {
                background-image: url('../../assets/images/macos-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/macos-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/macos-active@2x.png');
                }
            }
            .icon-windows {
                background-image: url('../../assets/images/windows-normal@2x.png');
                &:hover {
                    background-image: url('../../assets/images/windows-hover@2x.png');
                }
                &.active {
                    background-image: url('../../assets/images/windows-active@2x.png');
                }
            }
        }
        .header-content {
            color: #fff;
            background-repeat: no-repeat;
            background-position: bottom left;
            padding-bottom: 290px;
            background-size: 100%;
            &.vpn-for-ios {
                background-image: url('../../assets/images/product_header_bg_m.png');
            }
            &.vpn-for-android {
                background-image: url('../../assets/images/product_header_bg_android_m.png');
            }
            &.vpn-for-windows {
                background-image: url('../../assets/images/product_header_bg_windows_m.png');
            }
            &.vpn-for-mac {
                background-image: url('../../assets/images/product_header_bg_macos_m.png');
            }
            h1 {
                font-size: 32px;
                text-align: center;
            }
            p {
                font-size: 16px;
                margin-bottom: 24px;
                text-align: center;
            }
            img {
                width: 16px;
                margin-right: 16px;
                margin-top: 2px;
            }
            li {
                margin-bottom: 8px;
                font-size:14px;
                display: flex;
            }
            ul {
                margin-bottom: 32px;
            }
            .android-btn,.android-btn1 {
                display: flex;
                justify-content: center;
                button {
                    max-width: 300px;
                    height: 60px;
                    font-size: 20px;
                }
                .vpn-for-android {
                    width: 208px;
                    @media screen and (max-width:576px) {
                        width: 50%;
                    }
                }
                .google_play {
                    flex-shrink: 0;
                    width: 208px;
                    height: 75px;
                    vertical-align: top;
                    cursor: pointer;
                    margin-bottom: 10px;
                    @media screen and (max-width:576px) {
                        width: 50%;
                        height: 60px;
                    }
                }
            }
            .android-btn1 {
                 justify-content: space-between;
            }
        }
    }

    .why-vpn-network {
        background: #D8D8D8;
        text-align: center;
        color: rgba(38,38,38,1);
        padding: 32px 0 80px;
        margin-top: 0;
        background-image: url('../../assets/images/product_bg_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
            font-size: 32px;
            margin-bottom: 8px;
        }
        p {
            font-size: 16px;
            margin-bottom: 24px;
            a {
                color: #3B74E8;
            }
        }
    }
}
@media (min-width: 576px) {
    .products {
        .products-header {
            .product-top-tab {
                padding: 40px 0 50px;
                span {
                    background-position: center 9px;
                    background-size: 60%;
                }
            }
            .header-content {
                padding-bottom: 90px;
                &.vpn-for-ios {
                    background-image: url('../../assets/images/product_header_bg.png');
                }
                &.vpn-for-android {
                    background-image: url('../../assets/images/product_header_bg_android.png');
                }
                &.vpn-for-windows {
                    background-image: url('../../assets/images/product_header_bg_windows.png');
                }
                &.vpn-for-mac {
                    background-image: url('../../assets/images/product_header_bg_macos.png');
                }
                h1 {
                    font-size: 52px;
                    text-align: left;
                }
                p {
                    font-size: 24px;
                    text-align: left;
                }
                li {
                    font-size:18px;
                }
                ul {
                    margin-bottom: 70px;
                }
                .google_play {
                    width: 260px;
                    height: 75px;
                }
                .android-btn,.android-btn1 {
                    display: flex;
                    justify-content: flex-start;
                    button {
                        max-width: 600px;
                        height: 75px;
                        padding: 24px 30px;
                        font-size: 24px;
                    }
                    .vpn-for-android {
                        width: auto;
                    }
                }
            }
        }
        .why-vpn-network {
            background-image: url('../../assets/images/product_bg.png');
            padding: 56px 0 192px;
            margin-top: 64px;
            h2 {
                font-size: 56px;
                margin-bottom: 16px;
            }
            p {
                font-size: 24px;
                margin-bottom: 64px;
            }
        }
    }
}
</style>
