<template>
  <div class="bit-paymentwall">
         <!-- <div class="images" v-show="from === 'pc'">
            <img src="@/assets/images/alipay.png" alt="alipay">
            <img src="@/assets/images/unionpay.png" alt="unionpay">
            <img src="@/assets/images/ideal.png" alt="ideal">
            <img src="@/assets/images/Group_ok.png" alt="Group_ok">
        </div> -->
        <payment-wall v-if="(userInfo.userId !== '') || (userInfo.email !=='')" :uri="paymentwallUri"></payment-wall>
        <pricing-money-back></pricing-money-back>
        <pricing-terms  type="paymentwall"></pricing-terms>
        <div style="margin: 32px 0 24px" v-if="(userInfo.userId == '') && (userInfo.email == '') ">
            <button class="green-button" @click="paymentwallPay">Continue with Paymentwall</button>
        </div>
        <bit-popup-email v-if="showpupemail" @closePop="hidePop" type='other'> </bit-popup-email>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { apiExecutePaymentwallUrl } from '@/fetch/api';
import { loadRecaptcha } from '@/utils/common';
import PaymentWall from '@/components/PaymentWall'

export default {
    name: 'BitPaymentwall',
    data() {
        return {
            showpupemail: false,
            paymentwallUri: '',
        };
    },
    components: {
        PaymentWall,
    },
    props: {
        from: {
            type: String,
            default: 'pc',
        },
    },
    watch: {
        'products.amount'(value) {
            if (this.userInfo.userId === '' || this.userInfo.email === '') {
                this.getPaymentWallUri();
            }
        },
    },
    computed: {
        ...mapState([
            'products',
            'userInfo',
        ]),
    },
    methods: {
        hidePop(uri) {
            this.showpupemail = false;
            this.paymentwallUri = uri;
        },
        getPaymentWallUri() {
            loadRecaptcha().then((robotToken) => {
                let params = '';
                if (this.userInfo.email === '' || this.userInfo.email === undefined) {
                    params = {
                        userId: this.userInfo.userId,
                        productId: this.products.productId,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                } else {
                    params = {
                        email: this.userInfo.email,
                        productId: this.products.productId,
                        userChannelType: 0,
                        sourceType: 1,
                        'g-recaptcha-response': robotToken,
                    };
                }
                apiExecutePaymentwallUrl(params).then((result) => {
                    if (result.data.result === 1) {
                        this.paymentwallUri = result.data.url;
                    }
                }).catch((err) => {
                    console.log(err);
                });
            });
        },
        paymentwallPay() {
            if (this.userInfo.userId === '' || this.userInfo.userId === undefined) {
                this.showpupemail = true;
            } else {
                this.getPaymentWallUri();
            }
        },
    },
    mounted() {
        this.getPaymentWallUri();
    },
};
</script>

<style lang="stylus" scoped>
.bit-paymentwall {
  .images {
      margin-top: 17px;
      img {
          width: 50px;
          margin-right: 8px;
      }
  }
}
</style>
