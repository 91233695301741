<template>
    <div class="bit-about">
        <bit-header></bit-header>
        <div class="container">
            <h1 class="bitm">Terms of Service</h1>
            <p class="time"></p>
            <div class="about-content">
                <p >
                    Welcome to BitVPN. The BitVPN.net website (the Website or BitVPN Website) , the BitVPN application (the Software or BitVPN Software) and the BitVPN VPN proxy service (the Service) are owned and operated by BitVPN, Inc. ("BitVPN", "us" or "we"). By visiting our website or using BitVPN application and accessing the information, resources, services, products and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User Agreement'), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy section below for more information).
                </p>
                <p >
                    This agreement is in effect as of January 18, 2018.
                </p>
                <p >
                    WE RESERVE THE RIGHT TO CHANGE THIS USER AGREEMENT FROM TIME TO TIME WITHOUT NOTICE. YOU ACKNOWLEDGE AND AGREE THAT IT IS YOUR RESPONSIBILITY TO REVIEW THIS USER AGREEMENT PERIODICALLY TO FAMILIARIZE YOURSELF WITH ANY MODIFICATIONS. YOUR CONTINUED USE OF THE WEBSITE AND/OR THE SOFTWARE AFTER SUCH MODIFICATIONS WILL CONSTITUTE ACKNOWLEDGMENT AND AGREEMENT OF THE MODIFIED TERMS AND CONDITIONS.
                </p>
                <p >
                    <span>The Service </span><br>
                    BitVPN provides the Service as a means of protecting your privacy by establishing a secure Virtual Private Network ("VPN") connection when connecting to the Internet through unsecured networks or browsing unsecured websites. The Service’s VPN utilizes a proxy Internet Protocol ("IP") address (a "Proxy IP Address").
                </p>
                <p >
                    <span>Usage Policy </span><br>
                    You agree to comply with all applicable laws and regulations in connection with your use of this service.<br>
                    You are responsible for maintaining the confidentiality of your security credentials, activation codes, and/or passwords (if any) and are liable for any harm resulting from disclosing or allowing disclosure of these credentials.<br>
                    You further agree that you, or anyone using the service under your account, will not engage in any of the following activities, and that any of the following activities constitute grounds for termination of your account:
                </p>
                <p >
                    • Sending or transmitting unsolicited advertisements or content ("spam") over the Service, via e-mail or any other communication channel.<br>
                    • Sending, transmitting or receiving any illegal content over the Service, including but not limited to child pornography, whether via e-mail, peer-to-peer file sharing, or any other electronic communication channel.
                </p>
                <p >
                    • Uploading, downloading, posting, reproducing, or distribution of any content protected by copyright, or any other proprietary right, without first having obtained permission of the owner of the proprietary content.<br>
                    • Engaging in any conduct that restricts or inhibits any other subscriber from using or enjoying the Service.<br>
                    • Attempting to access, probe, or connect to computing devices without proper authorization (i.e., any form of “hacking”).<br>
                    • Posting to or transmitting through the Service any unlawful, harmful, threatening, abusive, harassing, hateful, racially, ethnically or otherwise objectionable material of any kind, including, but not limited to, any material which encourages conduct that may constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, national or international law.<br>
                    • Using the Service for anything other than lawful purposes. Violations of this Usage Policy may result in termination of your account. Additionally, you may be held responsible for any and all damages incurred by BitVPN, including any amounts charged by any outside entity due to said violation(s), including without limitation attorney's fees and costs.
                </p>
                <p >
                    BitVPN enables you to download software, software updates or patches, or other utilities and tools onto your computer or Internet-enabled device ("Software"). BitVPN grants to you a non-exclusive, limited license to use the Software solely for the purpose stated by BitVPN at the time the Software is made available to you and in accordance with these Terms. Modifying, distributing to unauthorized parties, reverse engineering, or otherwise using the Software in any way not expressly authorized by BitVPN, is strictly prohibited.
                </p>
                <p >
                    <span>Client's account</span><br>
                    You must download the Software before starting to use the Service. You may also create an account and enter your credentials and private/public keys. You hereby agree to provide true, accurate, current and complete information as may be prompted by any registration forms regarding your registration or use of Service.<br>
                    By starting to use the Service, you agree and accept unconditionally that:<br>
                    • You must keep your credentials and private/public keys on your own;<br>
                    • BitVPN is not responsible for your account recovery if you lose your credentials and private/public keys
                </p>
                <p >
                    <span>Payment and Subscription</span><br>
                    BitVPN offers a 3-day free trial purchase option. The free-trial subscription begins immediately, but it won’t be billed until the free trial period is over. Your account will be charged within 24 hours prior to the end of the free trial period. You may cancel the free-trial subscription before the end of the free trial period by going to your Account Settings at App Store or Google Play Store. Payment will be charged to App Store or Google Play Store at the purchase confirmation page, and BitVPN would not force users to pay by any means. Your account will be charged for renewal within 24-hours prior to the end of current subscription period, and the amount of renewal will be identified. Unless auto-renew is turned off at least 24 hours before the end of current subscription period, the subscription will be continued. Users may turn off the auto-renew in users’ account setting at App Store or Google Play store after purchase. No cancellation of current subscription is allowed during the active subscription period. To the extent that payment mechanisms are provided through the third parties, you agree that BitVPN is not responsible for the charges of the renewal. BitVPN offers the following subscriptions:<br>
                    • Unlimited Premium Plan One Month: USD $11.99/month<br>
                    • Unlimited Premium Plan Six Month: USD $29.99/6-months<br>
                    • Unlimited Premium Plan One Year: USD $47.99/year<br>
                    • Unlimited Premium Special Deal Plan One Year: USD $35.99/year<br>
                    • Unlimited Premium Plan One Year with Trial: USD $47.99/year after free trial<br>
                    • Unlimited Premium Plan One Year with Trial: USD $35.99/year after free trial<br>
                    The price is for United States customers only. Pricing in other countries may vary and actual charges may be converted to your local currency depending on the country of residence.
                </p>
                <p >
                    <span>Fair use policy</span><br>
                    BitVPN bandwidth is subject to a fair use policy. This entails that service usage with regards to bandwidth is unlimited within reason. If reasonable bandwidth is exceeded BitVPN has the right but not the obligation to throttle or terminate the account in question for the remainder of the billing cycle. For Free connections the reasonable usage limit is subjective to the judgment of BitVPN. "Unlimited traffic" for Basic service is defined as what the vast majority of users will utilize in any given day. BitVPN considers the following to be fair usage: 600MB traffic per day.<br>
                    BitVPN has the right to change this reasonable usage limit at any time.
                </p>
                <p >
                    <span>Privacy Policy</span><br>
                    Your privacy is very important to us, which is why we've created a separate Privacy Policy in order to explain in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under the scope of this User Agreement. To read our privacy policy in its entirety, <a style="color: blue;" href="/privacy-policy">click here</a>.
                </p>
                <p >
                    <span>Limitation of Warranties</span><br>
                    By using the Website or the Software, you understand and agree that all Resources we provide are "as is" and "as available". This means that we do not represent or warrant to you that:
                </p>
                <p >
                    • The use of our Resources will meet your needs or requirements.<br>
                    • The use of our Resources will be uninterrupted, timely, secure or free from errors.<br>
                    • The information obtained by using our Resources will be accurate or reliable.<br>
                    • Any defects in the operation or functionality of any Resources we provide will be repaired or corrected.
                </p>
                <p >
                    Furthermore, you understand and agree that:
                </p>
                <p >
                    • Any content downloaded or otherwise obtained through the use of our Resources is done at your own discretion and risk, and that you are solely responsible for any damage to your computer or other devices for any loss of data that may result from the download of such content.<br>
                    • No information or advice, whether expressed, implied, oral or written, obtained by you from BitVPN, Inc. or through any Resources we provide shall create any warranty, guarantee, or conditions of any kind, except for those expressly outlined in this User Agreement.
                </p>
                <p >
                    <span>Limitation of Liability</span><br>
                    In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. BitVPN, Inc. will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.
                </p>
                <p >
                    WE EXPRESSLY DISCLAIM ALL WARRANTIES EXPRESS AND IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. YOUR USE OF OUR RESOURCES SHALL BE AT YOUR OWN RISK. WE SHALL NOT UNDER ANY CIRCUMSTANCES BE RESPONSIBLE FOR ANY LOSS OR DAMAGES OF ANY KIND UNDER ANY LEGAL THEORY ARISING FROM YOUR USE, REGISTRATION TO USE, OR INABILITY TO USE, OUR RESOURCES, EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p >
                    NEITHER THE BITVPN, NOR THE NODES WILL BE LIABLE IN ANY WAY OR FORM FOR ACTIONS DONE BY THE CLIENTS AND/OR ANY ACTIONS DONE USING CLIENT’S ACCOUNT INCLUDING CRIMINAL LIABILITY AND CIVIL LIABILITY FOR ANY HARM. SOLELY THE CLIENT IS LIABLE FOR ALL HIS ACTIONS USING THE SERVICES, THE SOFTWARE AND THE RESOURCES OF NETWORK OPERATOR AND OF THE RESOURCES THE NODES.
                </p>
                <p >
                    IN NO EVENT WILL WE OR ANY OTHER PARTY WHO HAS BEEN INVOLVED IN THE CREATION, PRODUCTION, DISTRIBUTION, PROMOTION, OR MARKETING OF SOFTWARE AND/OR THE SERVICES BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, RELIANCE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA OR PROFITS, OR FOR INABILITY TO USE THE SERVICE, EVEN IF WE OR SUCH OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILUTRE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.
                </p>
                <p >
                    <span>Copyrights/Trademarks</span><br>
                    All content and materials available on the Website and the Software, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of BitVPN, Inc., and are protected by applicable copyright and trademark laws. Any inappropriate uses, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by BitVPN, Inc.
                </p>
                <p >
                    <span>Termination of Use</span><br>
                    You agree that we may, at our sole discretion, suspend or terminate your access to all or part of the Website, the Software and the Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.
                </p>
                <p >
                    <span>Governing Law</span><br>
                    The Website and the Software are controlled by BitVPN, Inc. from its offices located in Singapore. The Website and the Software can be accessed by most countries around the world. As each country has laws that may differ from those of Singapore, by accessing our website and the Software, you agree that the statutes and laws of Singapore, without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site.
                </p>
                <p >
                    Furthermore, any action to enforce this User Agreement shall be brought in the courts located in Singapore. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
                </p>
                <p >
                    <span>Contact Information</span><br>
                    If you have any questions or comments about these our Terms of Service as outlined above, you can contact us at: contact@bitvpn.net
                </p>
            </div>
        </div>
        <bit-footer2></bit-footer2>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'Terms',
};
</script>

<style lang="stylus">
.bit-about {
    h1 {
        font-size: 24px;
        color: rgba(38,38,38,1);
        margin: 32px 0 8px 0;
    }
    .time {
        margin-bottom: 32px;
    }
    .about-content {
        margin-bottom: 80px;
        p {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 25px;
            color: #262626;
            font-family: Regular;
            font-weight: 500;
        }
        span {
            font-size: 24px;
            line-height: 25px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: PingFangSC-Semibold;
            font-weight: 600;
            color: #394266;
            display: inline-block;
        }
    }
}
@media (min-width: 576px) {
    .faq {
        h1 {
            font-size: 42px;
            margin: 30px 0 30px 0;
        }
    }
}
</style>
