/* eslint-disable no-param-reassign */
import * as API from "@/fetch/api";

const app = {
    state: {
        products: {}, // 存放后端返回的产品列表数据
        amount: 0, // 选择套餐的价格
        productId: "", // 选择套餐的productId
        productName: "", // 选择套餐的productName
        month: 1, // 包年还是包月 1包月 12 包年
        avg: 0 // 平均价格
    },
    mutations: {
        SET_PRODUCTS: (state, array) => {
            state.products = array;
        },
        UPGRADE_PRODUCT_ID: (state, value) => {
            state.productId = value;
            const arr = state.products;

            // eslint-disable-next-line no-restricted-syntax
            for (const obj of arr) {
                if (obj.productId === value) {
                    state.amount = obj.amount;
                    state.productName = obj.productName;
                    state.month = obj.month;
                    state.avg = obj.month > 1 ? obj.avg : obj.amount;
                    localStorage.setItem("month", obj.month);
                    break;
                }
            }
        }
    },
    actions: {
        fetch_getProducts({ commit }) {
            API.apiGetProducts({ sourceType: 1 })
                .then(data => {
                    commit("SET_PRODUCTS", data.data.products);
                    commit(
                        "UPGRADE_PRODUCT_ID",
                        data.data.products[0].productId
                    );
                })
                .catch(error => {
                    console.log(
                        `fetch_getProducts Interface exception --${error}`
                    );
                });
        },
        choose_product({ commit }, productId) {
            commit("UPGRADE_PRODUCT_ID", productId);
        }
    }
};

export default app;
