<template>
    <div class="pay-container right-solution">
        <h5>Your Ultimate Privacy Solution</h5>
        <p>
            <img src="@/assets/images/icon-hook2.png" alt>Anonymous Browsing
        </p>
        <p>
            <img src="@/assets/images/icon-hook2.png" alt>Secure VPN connection
        </p>
        <p>
            <img src="@/assets/images/icon-hook2.png" alt>Worldwide VPN Servers
        </p>
        <p>
            <img src="@/assets/images/icon-hook2.png" alt>Multiple devices: Windows, macOS, iOS, & Android
        </p>
        <p>
            <img src="@/assets/images/icon-hook2.png" alt>30-day Money-back Guarantee
        </p>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'BitRightSolution',
};
</script>
<style lang="stylus">
.right-solution {
    display: none;
    border: 1px solid rgba(222, 222, 222, 1);
    padding: 24px 24px 28px 24px;
    h5 {
        font-size: 16px;
        margin-bottom: 16px;
    }
    p {
        font-size: 12px;
        margin-bottom: 8px;
        display: flex;
    }
    img {
        width: 16px;
        margin-right: 8px;
        align-self: flex-start;
    }
}
@media (min-width: 576px) {
    .right-solution {
        display: block;
    }
}
</style>
