/* eslint-disable no-param-reassign */
import { downloadskyvpn } from '@/utils/common';

export const downloadMixin = {
    data() {
        return {
            showDownloadModal: false,
            device: 'apk',
        };
    },
    methods: {
        download(action) {
            const result = downloadskyvpn();
            if (result) {
                this.showDownloadModal = true;
            }
            if (this.$route.path === '/') {
                this.$gce('Home', action);
            } else if (this.$route.path.match(/^\/vpn-for-/)) {
                this.$gce('Download', action);
            }
        },
        downloadTarget(type, category, action, device) {
            if (action && action.indexOf('Android') > -1) {
                this.$gce(category, action, device);
            }
            // if (this.location === 'top') {
            //     this.$gce(this.catogory, this.action, this.device);
            // } else if (this.location === 'bottom') {
            //     this.$gce(this.catogory, this.action, this.device);
            // }
            downloadskyvpn(type);
        },
    },
};

export const gceMixin = {
    methods: {
        gce(c, a, l) {
            if (!c) {
                if (this.$route.path === '/') {
                    c = 'Home';
                } else if (this.$route.path === '/vpn-price') {
                    c = 'Pricing';
                } else if (this.$route.path.match(/^\/vpn-for-/)) {
                    c = 'Download';
                }
            }
            this.$gce(c, a, l);
        },
    },
};
