<template>
    <div class="pricing">
        <bit-header header-type="transparent" :isPayPage="true"></bit-header>
        <div class="pricing-top ">
            <div class="container">
                <h1 class="bitm">Your Ultimate Privacy Solution</h1>
                <div class="header-des-item">
                    <div class="des-one-item">
                        <div><img src="@/assets/images/pricing_icon.png" alt="pricing_icon"></div>
                        <span>Anonymous Browsing</span>
                    </div>
                    <div class="des-one-item">
                        <div><img src="@/assets/images/pricing_icon.png" alt="pricing_icon"></div>
                        <span>Secure VPN connection</span>
                    </div>
                    <div class="des-one-item">
                        <div><img src="@/assets/images/pricing_icon.png" alt="pricing_icon"></div>
                        <span>Worldwide VPN Servers</span>
                    </div>
                </div>
                <div class="header-des-item">
                    <div class="des-one-item">
                        <div><img src="@/assets/images/pricing_icon.png" alt="pricing_icon"></div>
                        <span>Multiple devices: Windows, macOS, iOS, & Android</span>
                    </div>
                    <div class="des-one-item">
                        <div><img src="@/assets/images/pricing_icon.png" alt="pricing_icon"></div>
                        <span>30-day Money-back Guarantee</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- choose a plan -->
        <div class="choose-a-plan">
            <div class="container">
                <div class="row">
                    <div class="pricing-title col-12">
                        <span>Step 1</span>
                        <div class="title">Choose a plan</div>
                    </div>
                </div>
            </div>
            <div class="planlist container" v-if="products.products.length > 0">
                <div class="row">
                    <div class="col-md-4 pc-planitem">
                        <div class="planitem"  @click="changePackage(0,products.products[0].productId)" :class="selectedPackage == 0 ? 'active': ''">
                            <div class="top-title bitm">{{products.products[0].month}} {{ products.products[0].month > 1 ? 'Months' :'Month'}}</div>
                            <div class="line"></div>
                            <div class="price bitm"><span>$</span>{{products.products[0].avg}}</div>
                            <div class="per-month bitm">per month</div>
                            <div class="des">billed {{products.products[0].amount}} a {{ products.products[0].month > 1 ? 'year' :'month'}}</div>
                            <div>
                                <bit-radio
                                    :selected="selectedPackage === 0"
                                ></bit-radio>
                            </div>
                            <div class="top-right-save bitm">
                                <div class="save-save">Save</div>
                                <div class="save-percent">{{products.products[0].discount}}<span>%</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pc-planitem">
                        <div class="planitem"  @click="changePackage(1,products.products[1].productId)" :class="selectedPackage == 1 ? 'active': ''">
                            <div class="top-title bitm">{{products.products[1].month}} {{ products.products[1].month > 1 ? 'Months' :'Month'}}</div>
                            <div class="line"></div>
                            <div class="price bitm"><span>$</span>{{products.products[1].avg}}</div>
                            <div class="per-month bitm">per month</div>
                            <div class="des">billed {{products.products[1].amount}} a {{ products.products[1].month > 1 ? 'year' :'month'}}</div>
                            <div>
                                <bit-radio
                                    :selected="selectedPackage === 1"
                                ></bit-radio>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pc-planitem">
                        <div class="planitem"  @click="changePackage(2,products.products[2].productId)" :class="selectedPackage == 2 ? 'active': ''">
                            <div class="top-title bitm">{{products.products[2].month}} {{ products.products[2].month > 1 ? 'Months' :'Month'}}</div>
                            <div class="line"></div>
                            <div class="price bitm"><span>$</span>{{products.products[2].amount}}</div>
                            <div class="per-month bitm">per month</div>
                            <div class="des">billed {{products.products[2].amount}} a {{ products.products[2].month > 1 ? 'year' :'month'}}</div>
                            <div>
                                <bit-radio
                                    :selected="selectedPackage === 2"
                                ></bit-radio>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mobile-planitem">
                        <div class="planitem"  @click="changePackage(0,products.products[0].productId)" :class="selectedPackage == 0 ? 'active': ''">
                            <div class="m-top-right-save bitm">
                                <div class="m-save-save">Save 55%</div>
                            </div>
                            <div class="m-item-line1">
                                <div class="l-year">
                                    <bit-radio
                                        :selected="selectedPackage === 0"
                                        size="m-small"
                                    ></bit-radio>
                                    <span class="l-year-right">1 - year</span>
                                </div>
                                <div class="m-price bitm"><span>$</span>{{products.products[0].avg}}</div>
                            </div>
                            <div class="m-item-line2">
                                <div class="m-des">billed {{products.products[0].amount}} a {{ products.products[0].month > 1 ? 'year' :'month'}}</div>
                                <div class="m-per-month bitm">per month</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mobile-planitem">
                        <div class="planitem"  @click="changePackage(1,products.products[1].productId)" :class="selectedPackage == 1 ? 'active': ''">
                            <div class="m-item-line1">
                                <div class="l-year">
                                    <bit-radio
                                        :selected="selectedPackage === 1"
                                        size="m-small"
                                    ></bit-radio>
                                    <span class="l-year-right">6 - month</span>
                                </div>
                                <div class="m-price bitm"><span>$</span>{{products.products[1].amount}}</div>
                            </div>
                            <div class="m-item-line2">
                                <div class="m-des">billed {{products.products[1].amount}} a {{ products.products[1].month > 1 ? 'year' :'month'}}</div>
                                <div class="m-per-month bitm">per month</div>
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 mobile-planitem">
                        <div class="planitem"  @click="changePackage(2,products.products[2].productId)" :class="selectedPackage == 2 ? 'active': ''">
                            <div class="m-item-line1">
                                <div class="l-year">
                                    <bit-radio
                                        :selected="selectedPackage === 2"
                                        size="m-small"
                                    ></bit-radio>
                                    <span class="l-year-right">1 - month</span>
                                </div>
                                <div class="m-price bitm"><span>$</span>{{products.products[2].amount}}</div>
                            </div>
                            <div class="m-item-line2">
                                <div class="m-des">billed {{products.products[2].amount}} a {{ products.products[2].month > 1 ? 'year' :'month'}}</div>
                                <div class="m-per-month bitm">per month</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- select your pre payment -->
        <div class="select-payment">
            <div class="container">
                <div class="row">
                    <div class="pricing-title col-12">
                        <span>Step 2</span>
                        <div class="title">Select your preferred method of payment</div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="pay-gallery row">
                    <div class="col-lg-4 col-sm-6 ">
                        <div class="pay-item" :class="[selectedPayment === 'creditCard' ? 'active' : '']">
                            <div class="m-pay-item-title" @click="changePayment('creditCard')" >
                                <div class="title-line">
                                    <bit-radio
                                        type="blue"
                                        size="small"
                                        :selected="selectedPayment === 'creditCard'"
                                    ></bit-radio>
                                    <span class="down-icon"></span>
                                    <span class="title-text" >Credit Card</span>
                                </div>
                                <div class="images">
                                    <img v-if="mobile === -1 || selectedPayment !== 'creditCard'" src="@/assets/images/visa.png" alt="visa">
                                    <img v-if="mobile === -1 || selectedPayment !== 'creditCard'" src="@/assets/images/mastercard.png" alt="mastercard">
                                    <img v-if="mobile === -1" src="@/assets/images/american express.png" alt="american express">
                                    <img v-if="mobile === -1" src="@/assets/images/discover.png" alt="discover">
                                </div>
                            </div>
                            <div class="m-hide-cards images" v-if="mobile === 1 && selectedPayment === 'creditCard'">
                                <img src="@/assets/images/visa.png" alt="visa">
                                <img src="@/assets/images/mastercard.png" alt="mastercard">
                                <img src="@/assets/images/american express.png" alt="american express">
                                <img src="@/assets/images/discover.png" alt="discover">
                            </div>
                            <div v-if="mobile === 1 && selectedPayment === 'creditCard'" class="m-hide-cards-line"></div>
                            <div v-if="mobile === 1 && selectedPayment === 'creditCard'" style="padding: 0 16px;">
                                <bit-pay
                                    type="creditCard"
                                ></bit-pay>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div  class="pay-item" :class="[selectedPayment === 'paypal' ? 'active' : '']">
                            <div class="m-pay-item-title" @click="changePayment('paypal')">
                                <div class="title-line">
                                    <bit-radio
                                        type="blue"
                                        size="small"
                                        :selected="selectedPayment === 'paypal'"
                                    ></bit-radio>
                                    <span class="down-icon"></span>
                                    <span class="title-text">PayPal</span>
                                </div>
                                <div class="images">
                                    <img v-if="mobile === -1 || selectedPayment !== 'paypal'" src="@/assets/images/paypal.png" alt="paypal">
                                </div>
                            </div>
                            <div class="m-hide-cards images" v-if="mobile === 1 && selectedPayment === 'paypal'">
                                <img src="@/assets/images/paypal.png" alt="paypal">
                            </div>
                            <div v-if="mobile === 1 && selectedPayment === 'paypal'" class="m-hide-cards-line"></div>
                            <div v-if="mobile === 1 && selectedPayment === 'paypal'" style="padding: 0 16px;">
                                <bit-pay
                                    type="paypal"
                                ></bit-pay>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div  class="pay-item" :class="[selectedPayment === 'btc' ? 'active' : '']">
                            <div class="m-pay-item-title" @click="changePayment('btc')">
                                <div class="title-line">
                                    <bit-radio
                                        type="blue"
                                        size="small"
                                        :selected="selectedPayment === 'btc'"
                                    ></bit-radio>
                                    <span class="down-icon"></span>
                                    <span class="title-text">BTC</span>
                                </div>
                                <div class="images">
                                    <img v-if="mobile === -1 || selectedPayment !== 'btc'" src="@/assets/images/btc.png" alt="btc">
                                </div>
                            </div>
                            <div class="m-hide-cards images" v-if="mobile === 1 && selectedPayment === 'btc'">
                                <img src="@/assets/images/btc.png" alt="btc">
                            </div>
                            <div v-if="mobile === 1 && selectedPayment === 'btc'" class="m-hide-cards-line"></div>
                            <div v-if="mobile === 1 && selectedPayment === 'btc'" style="padding: 0 16px;">
                                <bit-pay
                                    type="btc"
                                ></bit-pay>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div  class="pay-item" :class="[selectedPayment === 'top' ? 'active' : '']">
                            <div class="m-pay-item-title" @click="changePayment('top')">
                                <div class="title-line">
                                    <bit-radio
                                        type="blue"
                                        size="small"
                                        :selected="selectedPayment === 'top'"
                                    ></bit-radio>
                                    <span class="down-icon"></span>
                                    <span class="title-text">
                                        TOP
                                        <span class="tips" @click.stop="routerGo"></span>
                                    </span>
                                </div>
                                <div class="images">
                                    <img v-if="mobile === -1 || selectedPayment !== 'top'" src="@/assets/images/top.png" alt="top">
                                </div>
                            </div>
                            <div class="m-hide-cards images" v-if="mobile === 1 && selectedPayment === 'top'">
                                <img src="@/assets/images/top.png" alt="top">
                            </div>
                            <div v-if="mobile === 1 && selectedPayment === 'top'" class="m-hide-cards-line"></div>
                            <div v-if="mobile === 1 && selectedPayment === 'top'" style="padding: 0 16px;">
                                <bit-pay
                                    type="top"
                                ></bit-pay>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div  class="pay-item" :class="[selectedPayment === 'other' ? 'active' : '']">
                            <div class="m-pay-item-title" @click="changePayment('other')">
                                <div class="title-line">
                                    <bit-radio
                                        type="blue"
                                        size="small"
                                        :selected="selectedPayment === 'other'"
                                    ></bit-radio>
                                    <span class="down-icon"></span>
                                    <span class="title-text">Other</span>
                                </div>
                                <div class="images">
                                    <img v-if="mobile === -1 || selectedPayment !== 'other'" src="@/assets/images/alipay.png" alt="alipay">
                                    <img v-if="mobile === -1 || selectedPayment !== 'other'" src="@/assets/images/unionpay.png" alt="unionpay">
                                    <img v-if="mobile === -1 || selectedPayment !== 'other'" src="@/assets/images/ideal.png" alt="ideal">
                                    <!-- <img src="@/assets/images/Group_ok.png" alt="Group_ok"> -->
                                </div>
                            </div>
                            <div class="m-hide-cards images" v-if="mobile === 1 && selectedPayment === 'other'">
                                <img src="@/assets/images/alipay.png" alt="alipay">
                                <img src="@/assets/images/unionpay.png" alt="unionpay">
                                <img src="@/assets/images/ideal.png" alt="ideal">
                                <img src="@/assets/images/Group_ok.png" alt="Group_ok">
                            </div>
                            <div v-if="mobile === 1 && selectedPayment === 'other'" class="m-hide-cards-line"></div>
                            <div v-if="mobile === 1 && selectedPayment === 'other'" style="padding: 0 16px;">
                                <bit-pay
                                    type="other"
                                    from="mobile"
                                ></bit-pay>
                            </div>
                        </div>
                    </div>
                </div>
                <bit-right-sulution v-if="mobile === 1" style="display: block;margin-top: 16px;margin-bottom: 110px;"></bit-right-sulution>
            </div>
            <bit-pay
                v-if="mobile === -1"
                :type="selectedPayment"
            ></bit-pay>
        </div>
        <bit-footer2 type="1"></bit-footer2>
    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import { gceMixin } from '@/mixins/';
import { gaClose, goBack } from '@/utils/common';

export default {
    components: {
    },
    mixins: [gceMixin],
    name: 'Pricing',
    data() {
        return {
            selectedPayment: 'creditCard',
            selectedPackage: 0,
            mobile: 0,
        };
    },
    computed: {
        ...mapState([
            'products',
        ]),
    },
    methods: {
        ...mapActions([
            'fetch_getProducts',
            'choose_product',
            'upgradeuserid',
        ]),
        changePayment(toPayment) {
            if (this.selectedPayment === toPayment) {
                return;
            }
            this.selectedPayment = toPayment;
        },
        changePackage(index, productId) {
            this.selectedPackage = index;
            this.choose_product(productId);
            this.gce('Pricing', 'selectProduct', productId);
        },
        routerGo(){
            this.$router.push('/what-is-top')
        }
    },
    mounted() {
        this.mobile = window.innerWidth < 576 ? 1 : -1;
        this.fetch_getProducts();
        localStorage.setItem('payMethod', '');
        localStorage.setItem('orderTime', '');
        localStorage.setItem('endTime', '');
        localStorage.setItem('oldEndTime', '');
        localStorage.setItem('amount', '');
        localStorage.setItem('productName', '');
        localStorage.setItem('redeemCode', '');
        localStorage.setItem('userId', '');
        localStorage.setItem('type', '');
        localStorage.setItem('email', '');
        if ((this.$route.query.userid !== undefined && this.$route.query.userid !== '') || (this.$route.query.userId !== undefined && this.$route.query.userId !== '')) {
            this.upgradeuserid((this.$route.query.userid === '' || this.$route.query.userid === undefined) ? this.$route.query.userId : this.$route.query.userid);
        }
        this.gce('Pricing', 'enterPurchasePage', this.$route.query.userid || '');
        gaClose(this, 'Pricing', 'close_Purchapage', '');
        goBack(this, 'Pricing', 'back_Purchapage', '');
    },
};
</script>

<style lang="stylus" scoped>
.pricing {
    .pricing-top {
        color: #fff;
        background: linear-gradient(180deg,rgba(60,26,65,1) 0%,rgba(44,18,71,1) 100%);
        padding: 40px 0 24px 0;
        text-align: center;
        h1 {
            font-size: 32px;
            margin-bottom: 16px;
        }
        .header-des-item {
            padding: 0 24px;
            margin-bottom: 8px;
            text-align: left;
            font-size: 14px;
            img {
                margin-right: 8px;
                width: 12px;
            }
            .des-one-item {
                display: flex;
                margin-bottom: 8px;
            }
        }
    }
    .choose-a-plan {
        padding: 24px 0 8px;
        background:rgba(245,245,245,1);
        .planlist {
            margin-top: 16px;
            .col-md-6 {
                display: flex;
                justify-content: center;
            }
            .pc-planitem {
                display: none;
            }
            .mobile-planitem {
                display: flex;
            }
        }
        .planitem:hover {
            border: 2px solid #9327A1;
        }
        .planitem {
            flex-grow: 1;
            max-width: 440px;
            color: #262626;
            border-radius: 8px;
            border: 2px solid rgba(222,222,222,1);
            text-align: center;
            padding: 20px 0 26px;
            position: relative;
            margin-bottom: 16px;
            cursor: pointer;
            background: #fff;
            .m-item-line1, .m-item-line2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .m-item-line1 {
                font-size: 18px;
                color: #262626
                .m-price {
                    font-size: 28px;
                }
                .l-year {
                    display: flex;
                    align-items: center;
                }
                .l-year-right {
                    margin-left: 8px;
                }
            }
            .m-item-line2 {
                font-size: 12px;
                color: #666;
                .m-per-month {
                    color: #999;
                }
            }
            .m-top-right-save {
                border-radius:0px 0px 30px 30px;
                display: inline-block;
                padding: 2px 22px;
                position: absolute;
                left: 50%;
                top: -2px;
                transform: translateX(-50%);
                background-image: url(../../assets/images/bug_bg.png);
                background-size: contain;
                .m-save-save {
                    font-size:16px;
                    color: #fff;
                }
            }
            .top-title {
                font-size: 24px;
            }
            .line {
                border-bottom: 1px solid #DEDEDE;
                margin: 4px auto 40px auto;
                width: 60%;
            }
            .price {
                font-size: 74px;
                line-height: 1;
                span {
                    font-size: 42px;
                    margin-right: 5px;
                }
            }
            .per-month {
                font-size: 14px;
                color: rgba(51,51,51,1);
                margin-bottom: 20px;
            }
            .des {
                font-size: 14px;
                color: rgba(153,153,153,1);
                margin-bottom: 12px;
            }
            &.active {
                border: 4px solid #9327A1;
                .line {
                    border-bottom-color: #9327A1;
                }
            }
            .top-right-save {
                position: absolute;
                right: 0;
                top: 0;
                background: #9327A1;
                color: #fff;
                width: 100px;
                height: 100px;
                background: linear-gradient(to top right, #fff 0%, #fff 50%, #9327A1 50%, #9327A1 100%);
                text-align: right;
                border-top-right-radius: 4px;
                .save-save {
                    font-size: 20px;
                    padding-right: 12px;
                }
                .save-percent {
                    font-size: 24px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
        .mobile-planitem {
            .planitem {
                padding: 20px 12px 16px 12px;
            }
        }
    }
    .select-payment {
        padding: 24px 0 0;
        .pay-gallery {
            margin-top: 24px;
        }
        .down-icon {
            display: inline-block;
            border-top: 3px solid #999;
            border-left: 3px solid #999;
            width: 15px;
            height: 15px;
            transform: rotate(-135deg);
            position: relative;
            top: -3px;
        }
        .bit-radio {
            display: none;
        }
        .m-pay-item-title {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            height: 66px;
        }
        .m-hide-cards {
            border-top: 1px solid rgba(211,211,211,1);
            padding: 25px 16px;
        }
        .m-hide-cards-line {
            margin: 0 16px 27px 16px;
            border-top: 1px solid rgba(211,211,211,1);
        }
        .pay-item {
            border: 1px solid rgba(211,211,211,1);
            margin-bottom: 16px;
            &.active {
                background: #fff;
                .down-icon {
                    transform: rotate(45deg);
                    top: 4px;
                }
            }
            span {
                position relative
                color: rgba(38,38,38,1);
                font-size: 18px;

                .tips{
                    position absolute
                    top 2px
                    right -20px
                    display: block;
                    width:16px;
                    height:16px;
                    background: url(../../assets/images/questionmark.jpg) no-repeat center;
                    background-size: 100% 100%
                }
            }
            .title-line {
                display: flex;
                align-items: center;
                .title-text {
                    margin-left: 16px;
                }
            }
        }
        .images {
            margin-top: 0;
            img {
                width: 50px;
                margin-right: 8px;
                vertical-align: top;
            }
        }
    }
    .pricing-title {
        display: flex;
        align-items: flex-start;
        color:rgba(51,51,51,1);
        span {
            font-size:12px;
            margin-right: 8px;
            padding: 4px 8px;
            background: #DEDEDE;
            border-radius: 23px;
            flex-shrink: 0;
        }
        .title {
            font-size: 18px;
        }
    }
}
@media (min-width: 576px) {
    .pricing {
        .pricing-top {
            padding: 40px 0;
            h1 {
                font-size: 64px;
            }
        }
        .header-des-item {
            padding: 0 50px;
            display: flex;
            justify-content: space-around;
            margin-bottom: 8px;
            img {
                width: auto;
            }
        }
        .choose-a-plan {
            background:rgba(245,245,245,1);
            padding: 32px 0 60px;
            .planlist {
                margin-top: 44px;
                .pc-planitem {
                    display: flex;
                }
                .mobile-planitem {
                    display: none;
                }
            }
        }
        .pricing-title {
            span {
                font-size:20px;
                margin-right: 16px;
                padding: 4px 16px;
                background: #DEDEDE;
                margin-top: 5px;
            }
            .title {
                font-size: 32px;
            }
        }
        .select-payment {
            padding: 32px 0 40px;
            .pay-gallery {
                margin-top: 40px;
                margin-bottom: 10px;
            }
            .m-pay-item-title {
                display: block;
                padding: 0;
                height: auto;
            }
            .pay-item {
                background: rgba(245,245,245,1);
                padding: 16px 13px;
                margin-bottom: 30px;
                cursor: pointer;
                span {
                    font-size: 24px;

                    .tips{
                        position absolute
                        top 5px
                        right -30px
                        display: block;
                        width:20px;
                        height:20px;
                        background: url(../../assets/images/questionmark.jpg) no-repeat center;
                        background-size: 100% 100%
                    }
                }
                .title-line {
                    .title-text {
                        margin-left: 24px;
                    }
                }
            }
            .images {
                margin-top: 17px;
            }
            .down-icon {
                display: none;
            }
            .bit-radio {
                display: inline-block;
            }
        }
    }
}
</style>
