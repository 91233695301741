import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './router';

Vue.use(VueRouter);

export default function createRouter() {
    return new VueRouter({
        mode: 'history',
        scrollBehavior: () => ({ x: 0, y: 0}),
        routes,
    });
}
