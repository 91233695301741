/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

/**
 *复制操作
 * @param {*} el 需要复制的dom对象
 */
export function copy(el) {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.getSelection().removeAllRanges();
        const range = document.createRange();
        range.selectNode(el);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        // 移除选中的元素
        window.getSelection().removeAllRanges();
    } else {
        el.select();
        document.execCommand('copy');
    }
}
/**
 *
 * @param {*} url  需要加载第三方css库
 */

export function loadCss(url) {
    return new Promise((resolve) => {
        const css = document.createElement('link');
        css.onload = () => {
            resolve();
        };
        css.rel = 'stylesheet';
        css.href = url;
        const ct = document.getElementsByTagName('head')[0];
        ct.appendChild(css);
    });
}

/**
 *
 * @param {*} url  需要加载第三方库
 */

export function loadScript(url) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.onload = () => {
            resolve();
        };
        script.src = url;
        const ct = document.getElementsByTagName('script')[0];
        ct.parentNode.insertBefore(script, ct);
    });
}

/**
 *
 * @param {*} 需要加载google的recaptcha
 */
export function loadRecaptcha() {
    return new Promise((resolve) => {
        const keys = window.location.hostname.indexOf('localhost') > -1 ? '6LcGEq4UAAAAAI8xsB0WnOr21QjEcSrId870DKWK' : '6LfNDK4UAAAAANjaftKoBf2DxcUbkYpGTpd3cRcl';
        loadScript(`https://www.recaptcha.net/recaptcha/api.js?render=${keys}`).then(() =>{
            grecaptcha.ready(() => {
                grecaptcha.execute(`${keys}`, {action: 'homepage'}).then((token) => {
                    resolve(token);
                });
            });
        });
    });
}

// 字符串加密函数
export function compile(code) {
    let c = String.fromCharCode(code.charCodeAt(0) + code.length);
    for (let i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
    }
    return escape(c);
}
// 字符串解密函数
export function uncompile(code) {
    code = unescape(code);
    let c = String.fromCharCode(code.charCodeAt(0) - code.length);
    for (let i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
    }
    return c;
}

// Get User System

export function getMobileOperatingSystem() {
    const agent = navigator.userAgent.toLowerCase();
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
        return 'Windows';
    }
    if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
        return 'Windows';
    }

    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { // ios设备
        return 'iOS';
    }

    if (isMac) {
        return 'Mac';
    }

    if (/(Android)/i.test(navigator.userAgent)) { // andorid设备
        return 'Android';
    }
    return 'unknow';
}

/*
 * 打开新网页，兼容safari浏览器有时打不开的问题
 */
export function openNewPage(url) {
    const winOpen = window.open(url, '_blank');

    // 兼容safari浏览器
    if (winOpen == null || typeof winOpen === 'undefined') {
        window.location.href = url;
    }
    return winOpen;
}

export function downloadskyvpnIOS() {
    openNewPage('https://apps.apple.com/us/app/bitvpn-secure-vpn-wifi-proxy/id1421603736');
}

export function downloadskyvpnAndroidG() {
    openNewPage('https://play.google.com/store/apps/details?id=me.bitvpn.app');
}

export function downloadskyvpnAndroid() {
    
}

export function downloadskyvpnMAC() {
    openNewPage('https://apps.apple.com/us/app/bitvpn-vpn-proxy-security/id1469022431?mt=12');
}

export function downloadskyvpnWindows() {
    openNewPage('https://statich5.cheapmessageapp.com/bitvpn_download/windows/BitVPNSetup.exe');
}


/**
 * 根据不同设备下载不同的应用包
 *
 *
 *
 *
 */
export function downloadskyvpn(userOS) {
    if (!userOS) {
        userOS = getMobileOperatingSystem();
    }
    if (userOS === 'iOS' || navigator.userAgent.toLowerCase().indexOf('ipad') > -1) {
        downloadskyvpnIOS();
    } else if (userOS === 'Android') {
        return 'Android';
    } else if (userOS === 'Mac') {
        downloadskyvpnMAC();
    } else if (userOS === 'Windows') {
        downloadskyvpnWindows();
    } else if (userOS === 'AndroidG') {
        downloadskyvpnAndroidG();
    } else if (userOS === 'AndroidApk') {
        downloadskyvpnAndroid();
    }
    return '';
}
export function gce(c, a, l) {
    if (window.gce) {
        window.gce(c, a, l);
    }
}

/**
 * ga打点 关闭浏览器
 * @param {*} self 组件this
 * @param {*} category 分类
 * @param {*} action  事件名称
 * @param {*} label  事件标签
 */
export function gaClose(self, category, action, label) {
    window.addEventListener('beforeunload', () => {
        gce(category, action, label);
    });
    //    if (window.history && window.history.pushState) {

    //         self.$ga.event('Home','back_Homepage','')
    //    }
}

export function pushHistory(data, url) {
    window.history.pushState(data, '', url);
}

/**
 * ga打点 浏览器返回按钮
 * @param {*} self 组件this
 * @param {*} category 分类
 * @param {*} action  事件名称
 * @param {*} label  事件标签
 */
export function goBack(self, category, action, label) {
    if (window.history && window.history.pushState) {
        window.addEventListener('popstate', () => {
            // pushHistory(category, url);
            gce(category, action, label);
        }, false);
    }
}


/**
 * formatTime
 * @param fmt
 * @param dateObj
 * @returns {*}
 */
export function formatTime(fmt, dateObj) {
    const o = {
        'y+': dateObj.getFullYear(),
        'M+': dateObj.getMonth() + 1,
        'd+': dateObj.getDate(),
        'h+': dateObj.getHours(),
        'm+': dateObj.getMinutes(),
        's+': dateObj.getSeconds(),
        'q+': Math.floor((dateObj.getMonth() + 3) / 3),
        'S+': dateObj.getMilliseconds(),
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            if (k === 'y+') {
                fmt = fmt.replace(RegExp.$1, (`${o[k]}`).substr(4 - RegExp.$1.length));
            } else if (k === 'S+') {
                let lens = RegExp.$1.length;
                lens = lens === 1 ? 3 : lens;
                fmt = fmt.replace(RegExp.$1, (`00${o[k]}`).substr((`${o[k]}`).length - 1, lens));
            } else {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
            }
        }
    }
    return fmt;
}

function convertUtcToPacificTime(time) {
    if (!time) {
        return new Date().getTime();
    }
    return time - 16 * 3600 * 1000;
}

/**
 * formatPacificTime
 * @param time
 * @returns {string}
 */
export function formatPacificTime(time) {
    return `${formatTime('yy/MM/dd hh:mm:ss ', new Date(convertUtcToPacificTime(time)))} PST`;
}

/**
 * formatPacificTimeLottery
 * @param time
 * @returns {string}
 */
export function formatPacificTimeLottery(time) {
    return formatTime('yyyy/MM/dd hh:mm:ss ', new Date(convertUtcToPacificTime(time)));
}

/**
 * formatPacificTime
 * @param time
 * @returns {string}
 */
export function smartToClient(money) {
    return (money / 1000000).toFixed(3);
}
/**
 * formatPacificTime
 * @param time
 * @returns {string}
 */
export function clientToSmart(money) {
    return money * 1000000;
}

// 获取url中的参数
export function getQueryString(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = window.location.search.substr(1).match(reg);
    if (r !== null) {
        return unescape(r[2]);
    }
    return '';
}

// 获取剩余时间，按utc时间计算
export function getRemainTime(time) {
    const current = new Date();
    const second = Date.parse(current.toUTCString()) / 1000;
    const remainSecond = time - second;
    const days = Math.floor(remainSecond / (24 * 3600));
    const hours = Math.floor((remainSecond - (24 * 3600 * days)) / 3600);
    return {
        days,
        hours,
    };
}

export function getEnv() {
    if (window.location.href.indexOf('bitvpn.net') === -1) {
        return 'development';
    }
    return 'production';
}

// log error to server
export function logError(e, ...info) {
    try {
        if (getEnv() !== 'development') {
            let customLabel = '';
            if (info.length > 0) {
                customLabel += JSON.stringify(info);
            }
            window.dataLayer.push({
                event: 'customer-error-event',
                customAction: e.message,
                customLabel,
            });
        } else {
            window.console.error(e);
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}
}

// 邮箱校验
export function emailValid(input) {
    if (!input) {
        return false;
    }
    const regu = /^[A-Za-z0-9]+([._\\-]*[A-Za-z0-9])*@([A-Za-z0-9]+[-A-Za-z0-9]*[A-Za-z0-9]+.){1,63}[A-Za-z0-9]+$/;
    const re = new RegExp(regu);
    const obj = input.trim();

    if (!re.test(obj) || !input.slice(input.length - 5).includes('.')) { // 正则验证不通过，格式不对
        return false;
    }
    return true;
}
