import { render, staticRenderFns } from "./DigitalPurchase.vue?vue&type=template&id=f004bb06&scoped=true&"
import script from "./DigitalPurchase.vue?vue&type=script&lang=js&"
export * from "./DigitalPurchase.vue?vue&type=script&lang=js&"
import style0 from "./DigitalPurchase.vue?vue&type=style&index=0&id=f004bb06&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f004bb06",
  null
  
)

export default component.exports