<template>
    <div class="paymentwallsty">
        <iframe name="myiframe"  :src="uri"  frameborder="0" align="middle" height="500px"  width="100%"  
        scrolling="yes"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        uri: {
            type: String,
            default: '',
        }
    }
}
</script>

<style lang="stylus" scoped>
    .paymentwallsty {
        padding-top 20px
    }
</style>