<template>
    <div class="products-item" :class="[index === 1 ? 'revers' : '']">
        <div class="img-wrap">
            <img v-if="index === 0 && item.type === 'ios'" src="@/assets/images/products_des_ios1.png" :alt="item.title">
            <img v-if="index === 1 && item.type === 'ios'" src="@/assets/images/products_des_ios2.png" :alt="item.title">
            <img v-if="index === 2 && item.type === 'ios'" src="@/assets/images/products_des_ios3.png" :alt="item.title">
            <img v-if="index === 0 && item.type === 'android'" src="@/assets/images/products_des_android1.png" :alt="item.title">
            <img v-if="index === 1 && item.type === 'android'" src="@/assets/images/products_des_android2.png" :alt="item.title">
            <img v-if="index === 2 && item.type === 'android'" src="@/assets/images/products_des_android3.png" :alt="item.title">
            <img v-if="index === 0 && item.type === 'mac'" src="@/assets/images/products_des_mac1.png" :alt="item.title">
            <img v-if="index === 1 && item.type === 'mac'" src="@/assets/images/products_des_mac2.png" :alt="item.title">
            <img v-if="index === 2 && item.type === 'mac'" src="@/assets/images/products_des_mac3.png" :alt="item.title">
            <img v-if="index === 0 && item.type === 'windows'" src="@/assets/images/products_des_win1.png" :alt="item.title">
            <img v-if="index === 1 && item.type === 'windows'" src="@/assets/images/products_des_win2.png" :alt="item.title">
            <img v-if="index === 2 && item.type === 'windows'" src="@/assets/images/products_des_win3.png" :alt="item.title">
        </div>
        <div class="content-wrap">
            <h2 class="bitm">{{item.title}}</h2>
            <ul>
                <li v-for="subitem in item.list" :key="subitem">{{subitem}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'BitProductsItem',
    props: {
        index: Number,
        item: Object,
    },
};
</script>

<style lang="stylus" scoped>
.products-item {
    display: flex;
    color: rgba(38,38,38,1);
    margin-bottom: 32px;
    &.revers {
    }
    .content-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .img-wrap {
        text-align: center;
    }
    img {
        width: 86px;
        margin-right: 16px;
    }
    h2 {
        color:rgba(38,38,38,1);
        font-size:18px;
        margin-bottom: 8px;
    }
    li {
        font-size: 14px;
        margin-left: 16px;
        margin-bottom: 8px;
    }
    li::before {
        content: "\2022";
        color: rgba(153,153,153,1);
        font-weight: bold;
        display: inline-block;
        font-size: 24px;
        height: 19px;
        line-height: 19px;
        width: 16px;
        margin-left: -16px;
        vertical-align: middle;
    }
}
@media (min-width: 576px) {
    .products-item {
        &.revers {
            flex-direction: row-reverse;
        }
        img {
            width: 298px;
            margin-right: 0;
        }
        .img-wrap, .content-wrap {
            width: 50%;
        }
        h2 {
            font-size:32px;
            margin-bottom: 24px;
        }
        li {
            font-size: 17px;
        }
    }
}
</style>
