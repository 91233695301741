<template>
    <div class="faq">
        <bit-header></bit-header>
        <div class="container">
            <div class="title">
                {{ title }}
            </div>
            <img class="what_is_top" src="@/assets/images/what_is_top.png" alt="">
            <div
                class="contentList"
                v-for="(item, index) in contentList"
                :key="index"
            >
                <div class="_title">
                    {{ item.title }}
                </div>
                <div class="_content">
                    {{ item.content }}
                </div>
                <div
                    class="sub_content"
                    v-for="(item1, index1) in item.contentdetail"
                    :key="index1"
                >
                    <div class="sub_title">
                        {{ item1.subTitle }}
                    </div>
                    <div
                        class="sub_sub"
                        v-for="(item2, index2) in item1.details"
                        :key="index2"
                        v-html="item2"
                    ></div>
                </div>
            </div>
            <!-- <div class="btn-center">
                <bit-button
                    size="medium"
                    @click.stop="download('click_bottom_aboutvpn')"
                    >Get BitVPN</bit-button
                >
            </div> -->
        </div>

        <bit-footer></bit-footer>
    </div>
</template>

<script>
import { downloadMixin } from "@/mixins/";
export default {
    mixins: [downloadMixin],
    name: "Faq",
    data() {
        return {
            title: "What is TOP",
            contentList: [
                {
                    title: "",
                    content: `“TOP” is the token name of TOP Network. TOP Network is a decentralized blockchain ecosystem composed of 4th-generation public blockchain, decentralized Apps (DApps) and decentralized communications services.`,
                    contentdetail: [],
                },
                {
                    title: "",
                    content: `TOP Chain, the public blockchain infrastructure underpinning the entire decentralized ecosystem is the world’s first full-state sharding public chain and capable of handling real-world business of any size and volume. A high-performance public blockchain platform for real-world Apps!`,
                    contentdetail: [],
                },
                {
                    title: "",
                    content: `The vision of TOP Network is to build a public blockchain infrastructure built specifically for DApps. As of now, the TOP team has already developed the decentralized wallet HiWallet, and are currently developing a series of blockchain games and more large-scale DApps on top of the ecosystem. Applications such as BitVPN, Dingtone and CoverMe, which bring more than 100 million users in the TOP ecosystem, will also be migrated to the TOP chain, which will bring a large number of users and services to the TOP ecosystem. `,
                    contentdetail: [],
                },
                {
                    title: "How do I get TOP?",
                    content:
                        "TOP is available on some exchanges, you can get it from Huobi Global, Hotbit, Binance DEX, etc. TOP Network releases bounties from time to time, follow their channels to get free TOP.",
                    contentdetail: [
                        {
                            subTitle: "",
                            details: [
                                `Website: https://www.topnetwork.org`,
                                `Telegram: https://t.me/topnetwork_top`,
                                `Twitter: https://twitter.com/topnetwork_top`,
                                `Medium: https://medium.com/top-network`,
                                `Facebook: https://www.facebook.com/topnetworktop`,
                            ],
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        down() {},
    },
};
</script>

<style lang="stylus" scoped>
.what_is_top{
    width: 100%
    height: 100%
}
.title {
    font-size 52px;
    color rgba(38,38,38,1);
    line-height 73px;
    margin-top: 36px;
    margin-bottom 36px;
    font-weight 600
}
.contentList {
    margin-bottom 20px
    color #262626
    line-height 28px
    ._title {
        font-size 24px
        font-weight 600
    }
    ._content{
        font-size 16px
    }
    .sub_content {
        margin-top 10px
        .sub_title{
            font-weight 600
        }
        .sub_sub {
           // line-height 25px
          // margin-top 10px
        }

    }
}
.btn-center {
    margin 30px 0px 99px
    button {
        margin 0 auto
        display block
    }
}
</style>
